@import "../../styleConstants.scss";

.textfield {
    border: 1px solid $gray_40;
    border-radius: $border_radius_normal;
    height: 30px;
    overflow: hidden;
    display: flex;
    align-items: center;
    background: $white;

    ::placeholder {
        color: #aaa;
    }

    &:focus-within {
        background: $darkblue_5;
    }

    input {
        border: 0;
        width: 100%;
        height: 100%;
        outline: 0;
        padding: 0 10px;
        background-color: transparent;
    }

    @at-root #{&}-filled {
        background-color: $turquoise_10;
        border-color: $turquoise_10;

        &:focus-within {
            background: $turquoise_20;
            border-color: $turquoise_20;
        }

        ::placeholder {
            color: #A3BAD1;
        }
    }

    @at-root #{&}-plain {
        background-color: $white;
        border: 0;
        border-radius: 0;
        border-bottom: 2px solid #ccc;

        &:focus-within {
            border-bottom-color: $primary_color;
        }
    }

    svg {
        color: $darkblue_80;
        width: 15px;
    }
}

.textfield-disabled {
    background: $darkblue_5 !important;
}