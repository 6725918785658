$tenueBlue: #d5e3f2;
$font-Secular: "PT Sans Caption", sans-serif;
$darkBlue: #003f80;
.container {
  width: 100%;
  padding: 0 135px;
  display: flex;
  flex-direction: column;
}
.Search {
  vertical-align: top !important;
  border: rgba(0, 93, 191, 0.1);
  background-color: rgba(0, 93, 191, 0.05);
  border-radius: 6px;
  width: 438px;
  height: 32px;
  padding-left: 18px;
}

.categoryContainers {
  margin: 8rem;
  text-align-last: center;
}
.inputMargin {
  margin-right: 15px;
}
.backgroundImage {
  background-image: url("../../assets/img/icons/Filtrar.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
}
.categoryImages {
  filter: none;
}
.imagesBlock {
  filter: grayscale(100%);
}
.categoryImages:hover {
  filter: opacity(85%);
  transform: scale(1.003);
  transition: 0.1s all ease-in-out;
  cursor: pointer;
}
.backgroundBlueTenue {
  border-radius: 100%;
}
.backgroundBlueTenue:hover {
  background-color: rgba(0, 93, 191, 0.4);
}

.categoryLetters {
  color: #58595b;
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 20.1439px;
  line-height: 26px;
  text-align: center;
  margin-top: 0.7rem;
}
.marginBot {
  margin-bottom: 2rem;
}

.leftBorder {
  border-left: 1px solid #cecece;
  height: 488px;
}
.center {
  align-items: center;
  margin-top: 20px;
  margin-left: 25px;
}

.TitleBar {
  color: #005dbf;
  font-family: $font-Secular;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 45px;
}
.marginRight {
  margin-right: 1rem;
}
.alingCenter {
  align-self: center;
}
.heightvh {
  margin: auto;
  height: 80vh;
}
.height100 {
  height: 100%;
}
.flex1 {
  display: flex;
  padding: 0px;
  border: 1px solid #cecece;
  background-color: #f5f7fa;
  background-color: #fff;
  border-radius: 15px;
  cursor: pointer;
}
.flex2 {
  cursor: pointer;
  text-align: center;
}
.classTitle {
  flex: 2;
  padding-top: 10px;
  margin: 0 auto;
  font-family: "PT Sans";
  color: #58595b;
  font-weight: Bold;
  font-size: 16px;
  line-height: 21px;
  text-align: start;
}
.classSubTitle {
  font-family: "PT Sans";
  color: #cecece;
}
.accountBoxWrap {
  display: grid;
  grid-template-areas: "a a a";
  grid-gap: 10px;
  grid-auto-rows: 100px;
}
.classIcon {
  border-radius: 15px;
}
.classArrow {
  width: 15px;
  margin-right: 10px;
}
.categoryDisabled {
  filter: grayscale(1);
}
.bgImage {
  background-image: url("../../assets/img/icons/spiralBackGround.svg");
  background-repeat: no-repeat;
  background-position: 100% bottom;
}
