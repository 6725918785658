
@import "../../index.scss";
.line{
    height: 1px;
    width: 100%;
    border-color: #CECECE;
    background-color: #CECECE;
}
.inputMarginR{
    margin-right:15px,
}
.imageMarginL{
    margin-left: 9px;
}
.imageMarginTop{
    margin-top: 20px;
}
.registerInputs{
    border: 1.5px solid #005DBF;
    border-radius: 5px;
    width: 100%;
    padding: 0 .5rem;
    height: 24px;
    flex:1;

}
.crudModalLabel{
    font-size: .75rem;
    margin: .4rem .3rem 0 .3rem;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
    color: $darkBlue;
}


.Item1{
    display: flex;
    flex-direction: column;
}

.Item2{
    display: flex;
    flex-direction: row;
}

.container{
    width: 80%;
}

.container0{
    display: flex;
    flex-direction: row;
    max-width: 390px;

}

.container1{
    display: flex;
    flex-direction: column;
    max-width: 216px;

}
.container2{
    display: flex;
    flex-direction: initial;
}

.container3{
    display: flex;
    flex-direction: column;

}

.sectionTitle{
    font-weight: 700;
    font-style: normal;
    font-size: 15px;
    line-height: 19px;
    color: #005DBF;
    margin-bottom: 11px;
}
.marginTopSection{
    margin-top: 49px;
}
.marginTopButton{
    margin-top: 16px;
}
.marginButton{
    margin-bottom: 45px;
}

.marginTopLabel{
    margin-top: 9px;
}

.btnPrimary{
    background-color: $clearBlue;
    border: 1.5px solid $clearBlue;
    border-radius: 5px;
    max-width: 136px;
    font-family: 'PT Sans';
    color:#fff;
    min-width: 136px;
    font-weight: 700;
    font-size: 15px;
    line-height: 19.41px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.btnPrimary:hover{
    background-color: $darkBlue;
    border: 1.5px solid $darkBlue;
    color:#fff;
}

.btnPrimary:disabled{
    background-color: $clearGray;
    border: 1.5px solid $clearGray;
    color:#fff;
    opacity: 1;
}

.label{
    font-weight: 700;
    font-style : normal;
    font-size: 12px;
    line-height:16px;
    margin-bottom: 1.5px;
    align-content: left ;
    color: #58595B;
    padding: 4px 1px 2px;
}


.buttonBack{
    flex-direction: row;
    float: right;
    width: 96px;
    height: 25px;
    display: flex;
    border-radius: 6px;
    justify-content: space-evenly;
}
.buttonBack:hover{
    background-color: #E6ECF2;
}
.buttonBack:hover>p{
    color: #005DBF;
}

.buttonBack>img{
    height: 20px;
    top: 3px;
}
.buttonBack:hover>img{
    filter: none;
}
.buttonBack>p{
    size: 76px;
    line-height:16px;
    margin-bottom: 0;
    color: #58595B;
    cursor: pointer;
    font-Weight:700;
}
.sizeLink{
    width: 16.67px;
    height: 16.67px;
}
.userImgHeader{
    width: 40px;
    height: 40px;
    border:1px solid $clearBlue;
    border-radius: 100%;
    margin:4px 10px 4px 0;
}
.profilepicWrapper{
    position: relative;
    margin: 0 auto;
}
.profilepic{
    width: 84px;
    height: 84px;
    border:1px solid $clearBlue;
    border-radius: 100%;
}
.hoverPointer:hover{
    cursor: pointer;
    //filter: brightness(0) saturate(100%) invert(43%) sepia(100%) saturate(1319%) hue-rotate(171deg) brightness(95%) contrast(104%);
}
.changepfp{
    width: 25px;
    height: 25px;
    border-radius: 100%;
    position: absolute;
    bottom: 1px;
    padding: 0px;
    background: #fff;
    right: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
}
.imageInputBtn{
   display: none;
}
.imageInputLabel{
    width: 25px;
    height: 25px;
    border-radius: 100%;
    position: absolute;
    bottom: 1px;
    padding: 4px;
    background: #fff;
    right: 2px;
    //box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
    display: flex;
}
