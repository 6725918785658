@import "../../styleConstants.scss";

.subtitle{
    margin: 16px 0 0 0;
    color:$darkBlue;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
}

.container1 {
  display: flex;
  flex-direction: row;
}

.throwThisToTheBottom {
  display: flex;
  flex-direction: column-reverse;
}
.textToTheEnd {
  display: flex;
  justify-content: flex-end;
}

//zone of new angie made tables
.container2 {
  //flex:2;
  //margin-top:15px;
  display: flex;
  flex-direction: column;
}

.container3 {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.Item {
  display: flex;
  flex-direction: column;
}

.ItemTiny {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ItemSmall {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.ItemAlmostMedium {
  flex: 3;
  display: flex;
  flex-direction: column;
}

.ItemMedium {
  flex: 4;
  display: flex;
  flex-direction: row;
}

.inputMargin {
  margin-right: 15px;
}

.ItemCheckbox {
  display: flex;
  margin-top: 24px;
  //flex-direction: column-reverse;
}

.greyRow {
  display: flex;
  flex-direction: row;
  background-color: #ddd;
  border-radius: 0.5rem;
  margin-top: 1rem;
  padding: 0.5rem;
  padding: 0.5rem 2rem;
}

.textArea {
  height: 32px;
  border: 1.5px solid $darkBlue;
  border-radius: 6px;
}

.justification {
  min-height: 50px;
}

.checkbox {
  margin: auto 6px auto 0;
}

.bottomRow {
  margin: 20px 0;
  display: inline-flex;
  flex-direction: row-reverse;
  width: 100%;
  vertical-align: middle;
  padding: 0 0.5rem;
}

.bottomRow > Button {
  margin-left: 12px;
}

.tableButton {
  cursor: pointer;
  color: $tableText;
  text-decoration: none;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  font-size: 12px;
  line-height: 16px;
}

.tableButton:hover {
  color: $darkBlue;
  padding-top: 0px;
  transition-duration: 0.2s;
}

.tableSlash {
  color: $tableText;
  font-size: 12px;
  line-height: 16px;
}

.traerReq {
  display: flex;
  min-width: 140px;
  height: 30px;
  font-weight: Bold;
  font-size: 12px;
  line-height: 16px;
  color: #697387;
  cursor: pointer;

  border-radius: 10px;
  margin: 0 0 0 auto;
}

.traerReq:hover {
  background-color: #f5f7fa;
}

.traerReq > p {
  flex: 1;
  margin: 8px 6px 4px 6px;
}

.traerReq:hover > p {
  color: #005dbf;
}

.traerReq:hover > img {
  filter: brightness(0) saturate(100%) invert(20%) sepia(94%) saturate(1860%)
    hue-rotate(196deg) brightness(107%) contrast(105%);
}

.pseudoInput {
  padding: 1px;
  position: relative;
  cursor: pointer;
}

.pseudoInput > p {
  margin: 0;
  color: #cecece;
  font-size: 16px;
  line-height: 24px;
  height: 22px;
  padding-left: 8px;
  overflow: hidden;
}

.pseudoInput > img {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 15px;
  height: 22px;
  margin: 0;
}

.urgente {
  color: #697387;
  line-height: 16px;
  font-size: 12px;
  display: inline;
}

.rejected {
  color: $clearGray;
}

.newArticle {
  margin: 0 5px;
  border: 1.5px solid $orange;
  border-radius: 5px;
  padding: 0 15px;
}

.adjustIcon {
  cursor: pointer;
  width: 20px;
  margin: 0;
}

.adjustIconDescargar{
    cursor: pointer;
    width: 28px;
    margin: 0;
}
.adjustIcon2:hover{
    filter: brightness(0) saturate(100%) invert(22%) sepia(100%) saturate(1940%) hue-rotate(198deg) brightness(91%) contrast(103%);
}

.adjustIconDescargar:hover{
    filter: brightness(0) saturate(100%) invert(22%) sepia(100%) saturate(1940%) hue-rotate(198deg) brightness(91%) contrast(103%);
}

.adjustIconDetalles{
    cursor: pointer;
    width: 14px;
    margin: 0;
}

.adjustIconTransladar{
    cursor: pointer;
    width: 18px;
    margin: 0;
}

.adjustIconTransladar:hover{
    filter: brightness(0) saturate(100%) invert(50%) sepia(53%) saturate(3534%) hue-rotate(172deg) brightness(98%) contrast(100%);
}

.adjustIconPart2{
    height: 16px;
    width: 16px;
}

.adjustIconPart2Orange{
    height: 24px;
    width: 24px;
}

.textPart2{
    margin: auto 0 0 10px;
    color:$darkBlue;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
}
.textPart2Orange{
    margin: auto 0 0 2px;
    color:$orange;
}

.adjustIcon2 {
  cursor: pointer;
  width: 28px;
  margin: 0;
}


.adjustIcon3 {
  cursor: pointer;
  width: 14px;
  margin: 0;
}

.adjustIcon4 {
    cursor: pointer;
    height: 18.102745056152344px;
    width: 20.896038055419922px;
    left: 1.5517578125px;
    top: 2.897216796875px;
    margin-right: 10px;
  }
.urgentText {
  font-weight: Bold;
  font-size: 12px;
  line-height: 16px;
  //color: #01a0f6;
  color: $red;
  margin: 0;
}
.budgetModalText {
  font-weight: Bold;
  font-size: 15px;
  line-height: 19px;
  color: #01a0f6;
  margin: 0;
}
.alertTriangle {
  margin-left: 10px;
}

.popupReqActive::before {
  width: 12px;
  height: 12px;
  cursor: default;
}


.orangeText{
    color: $orange;
    font-weight: Bold;
    line-height: 16px;
}

.smallerHeader{
    font-size: 14px;
    padding: 0 5px;
}

//added for article insert ts-147
.orangeIcon {
  fill: $orange;
  filter: saturate(3000%) hue-rotate(190deg);
}

.orangeBorder {
  border: 1.5px solid $orange;
  border-radius: 5px;
}

.orangeText {
  color: $orange;
  font-family: "PT Sans Caption";
  font-style: normal;
  font-weight: bold;
}
.orangeText3 {
  font-size: 12px;
  line-height: 15px;
}
.orangeText2 {
  font-family: "PT Sans";
  font-size: 15px;
  line-height: 19px;
}

.orangeCheck {
  background-color: $orange;
  border-color: $orange;
}
.inputsTextArea {
  border: 1.5px solid #01a0f6;
  border-radius: 5px;
  width: 100%;
  padding: 0 0.5rem;
  height: 50px;
}
.btnPrimary {
  background-color: #fff !important;
  border: 1.5px solid $clearBlue !important;
  color: $clearBlue !important;
  min-width: 110px;
  font-weight: 700;
  font-size: 15px;
  line-height: 19, 41px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.btnPrimary:hover {
  background-color: $clearBlue !important;
  border: 1.5px solid $darkBlue !important;
  color: #fff !important;
}

.btnPrimary:disabled {
  background-color: $clearGray;
  border: 1.5px solid $clearGray;
  color: #fff;
  opacity: 1;
}

.btnSecondary {
  background-color: $darkBlue !important;
  border: 1.5px solid $darkBlue !important;
  color: #fff !important;
  min-width: 110px;
  font-weight: 700;
  font-size: 15px;
  line-height: 19, 41px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.btnSecondary:hover {
  background-color: $darkBlue !important;
  border: 1.5px solid $darkBlue !important;
  color: #fff !important;
}

.btnSecondary:disabled {
  background-color: #fff !important;
  border: 1.5px solid $clearGray !important;
  color: $clearGray !important;
  opacity: 1;
}

