@import "../../styleConstants.scss";

.subtitle {
  margin: 16px 0 0 0;
  color: #005dbf;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
}

.closeX {
  background: rgba(#005dbf, 0.1);
  cursor: pointer;
  padding: 3px;
  margin: 0 auto;
  border: 0;
  border-radius: 100%;
  width: 15px;
}




//***************************************FOR CURRICULUMENDOWNMENT
.sectionTitle,
.sectionSubTitle{
  font-family: "PT Sans";
  font-style: normal;
  font-weight: bold;
  color: #005DBF;
}

.sectionTitle{
  font-size: 20px;
  line-height: 26px;
}

.sectionSubTitle{
  font-size: 15px;
  line-height: 19px;
}

.downloadBtn{
  display: flex;
  padding: 10px;
  background: rgba(0, 93, 191, 0.05);
  border-radius: 5px,
}

.historyText,
.historyTextBlue,
.historyTextGray{
  font-family: "PT Sans Caption";
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  color:#58595B;

}
.historyTextBlue{
  color: #005DBF;
  font-weight: bold;
  margin-right: 16px;
}
.historyTextGray{
  font-weight: bold;
  text-decoration: underline;
}
.historyListItem{
  position: relative;
}
.historyListItem::before{
  content: "";
  background-color: #005dbf;
  position: absolute;
  left: -8px;
  width: 5px;
  height: 5px;
  border-radius: 100%;
}
.checklistText{
  font-family: "PT Sans";
  font-size: 13px;
  line-height: 17px;
  color: #58595B;
}
//***************************************END FOR CURRICULUMENDOWNMENT 
.statusCanceled,
.statusPending{
  font-weight: bold; //nota, emanuel no tenia esto
  //font-size: 9px;
  //line-height: 12px;
  border-radius: 10px;
  padding: 4px 16px;
  text-align: center;
}

.statusCanceled {
  color: #ec1c24;
  background: rgba(#ec1c24, 0.1);
  width: 140px;

}

.statusPending {
  color: #5942cd;
  background: rgba(#5942cd, 0.1);
  width: 140px;
}
.name_day {
  text-align: center;
  background-color: #f5f7fa;
  width: 14.2%;
}

.name_day > p {
  padding: 10px;
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: #58595b;
}
.number_day {
  border: 1px solid #cecece;
  width: 14.2%;
  height: 97px;
  padding: 12px;
}
.containerDay {
  --bs-gutter-y: 0;
  // cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}
.day_icons {
  background-color: #005dbf20;
  color: gray;
  width: 80%;
  height: 25px;
  border-radius: 6px;
  margin: auto 23px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  text-transform: lowercase;
}

.candidateWrap{
  cursor: pointer;
  display: flex;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 9px;
  padding: 3px;
  border: 1px solid transparent;
  
  &.active{
    //background: rgba(0, 93, 191, 0.05);
    background: rgba($darkBlue, 0.05);
    border: 1px solid #01A0F6;
  }

  &:hover{
    background: rgba($clearBlue, 0.05);
  }
}

.candidateImage{
  border: 1px solid #005DBF;
  border-radius: 40px;
  height:40px; 
  width:40px;
  object-fit:"cover";
  margin-right: 15px;
  margin-left: 15px;
}
.candidateTitle{
  font-size: 15px;
  line-height: 19px;
  color: #58595B;
}

.candidateSubtitle{
  font-family: "PT Sans Caption";
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #005DBF;
}

.linkButton{
  padding:6px;
  border-radius: 100%;
  transition: 0.3s all ease-in-out;
  &:hover{
    
    background-color: rgba($clearBlue, 0.3);

    &>img{
      filter:brightness(0) saturate(100%) invert(11%) sepia(99%) saturate(7406%) hue-rotate(203deg) brightness(100%) contrast(101%);
    }
  }


}
