@import "../../styleConstants.scss";



@import "../../index.scss";

.backgroundBlueTenue{
    background-color: rgba(0,63,128,0.05)
}
.btnAddTable{
    width: 0.973rem; //15.56px
    margin:0 10px;
}
.handleLot{
    margin-top:1.25rem;
}
.heightInputs{
    height: 2.5rem;
}
.registerSelect{
    border: 3px solid #01A0F6 !important;
    border-radius: 5px;
    width: 100%;
}
.discardFile{
    position: absolute;
    right: 3rem;
    top: 2rem;
    z-index: 10;
}
.selectGroup{
    margin-right: 1rem; // 16px
    display: inline-block;
    // max-width: 9.75rem; // 156px
    //min-width: 9.75rem; // 156px
}
.selectGroupWithOutMargin{
    display: inline-block;
    // max-width: 9.75rem; // 156px
    //min-width: 9.75rem; // 156px
}
.labelFont{
    font-size: 0.8rem;
    color: #005DBF;
    font-family: "PT Sans Caption";
    line-height: 0.971rem;
    font-weight: 400;
}
.labelFontGray{
    font-size: 13px;
    color: #58595B;
    font-family: "PT Sans Caption";
    line-height: 0.971rem;
    font-weight: 400; 
}
.containDeliveries{
    font-size: 13px;
    color: $darkGray;
    font-family: "PT Sans Caption";
    font-weight: 400;
}
.selectInput{    
    border: 1px solid #7FADDE;
    border-radius: 5px;
    width: 100%;
    padding: 0 .5rem;
    height: 24px;
    max-height: 24px;
    font-size: 12px;
    color: $darkGray;
    outline: none
}
.selectInputClear{    
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid $clearBlue;
    min-height: 1.75rem;
}
.selectInputClear:disabled{
    background-color: #F5F7FA;
    border-radius: 6px;
    border: 1px solid $clearBlue;
    min-height: 1.75rem;
}
// .selectInput:disabled{
//     opacity: 0.7;
//     border-color: rgba(118, 118, 118, 0.3);   
// }
.inputsearch{
    min-width:16.875rem; //270px;
    max-height: 1.5rem; //24px;
    background-color:rgba(0, 93, 191, 0.1);
    border-radius: 6px;
    border: none;
    padding:0 0.5rem;
}
.inputsearch::placeholder{opacity:0.5;}
.btnExcelIcon{
    width:1.073rem; //17.16px
    height:1.241rem; //19.86px
    margin:0 .5rem; //18.44px;
}
.groupBounding{
    margin-left:.5rem;
    display:inline-block;
    padding:0.5rem 0.3rem;
    transition: 0.3s all ease-in-out;
    //min-width:9.563rem;// 153px;
}
.groupBounding:hover{    
    cursor:pointer;
    background: rgba(230, 236, 242, 1);
    border-radius: 10px;
    color: rgba(0, 93, 191, 1)
 }
 .groupBounding a{
    color: rgba(88, 89, 91, 1);
    transition: 0.2s all ease-in-out;
 }
 .groupBounding a:hover{
    color: rgba(0, 93, 191, 1);
 }
 .legalPersonBackground{
     background-image: url("../../assets//img/legalPerson.png");
     background-position: center;     
     background-repeat: no-repeat;
     width:20.5rem;; //321.18px;
     height:12.5rem; //200px;
     background-size: cover;
     margin-top: 3rem; //119px
     transition: 0.2s all ease-in-out;
 }
 .naturalPersonBackground{
     background-image: url("../../assets//img/backgroundNaturalPerson.png");
     background-position: center;     
     background-repeat: no-repeat;
     width:20.5rem;; //321.18px;
     height:12.5rem; //200px;
     background-size: cover;
     margin-top: 3rem; //119px
     transition: 0.2s all ease-in-out;
 }
 
 .backIcon{
     display:inline;     
     max-width:32.91px;
     max-height: 19.74px;
 }
 .btnBigPrimary{
    border: 1.5px solid #005DBF;
    // margin-left:6.625rem;
    border-radius: 15px;
    height: 4.75rem; //76px;
    width: 20.5rem; //328px;
    color:#005DBF;
    font-weight: 700;
    font-family: 'PT Sans Caption';
    font-size: 1.563rem; //25px;
    text-decoration: none;
    text-align: center;
    display:inline-block;
    vertical-align:middle;
    line-height:70px;
 }
 .btnBigPrimary:hover {
     background-color:#005DBF;
     transition: 0.2s all ease-in-out;
     color:#fff;
 }
 .containerItemSelector:hover .legalPersonBackground{
     background-image: url("../../assets//img/legalPersonColor.png");     
 } 
 .containerItemNaturalSelector:hover .naturalPersonBackground{
    background-image: url("../../assets//img/backgroundNaturalPersonColor.png");
 } 

 .verticalDivisor{
    height:326px;
    width:1px;
    background-color:#CECECE;
    margin: 3rem 97px;
 }
 .containerSelectorPersons{
     display:flex;
     justify-content:space-around;
     max-width: 75%;
     margin: auto;
 }
 
 .checkAccordion{
    content:"";
    width: 1.563rem;
    height: 1.563rem;
    min-width: 1.563rem;
    min-height: 1.563rem;
    max-height: 1.563rem; //25px;
    max-width: 1.563rem; //25px;
    border: 1.5px solid #CECECE;
    border-radius:100%;
    text-align: center;
    display: inline-block;    
    background-color:#fff;
    margin:0.5rem 0.5rem 5px 0.5rem;
    margin-left: 0 !important;
    position: absolute;
    right: 100%;
 }
 .checkAccordionActive{
    width: 1.563rem;
    height: 1.563rem;
    min-width: 1.563rem;
    min-height: 1.563rem;
    max-height: 1.563rem; //25px;
    max-width: 1.563rem; //25px;
    border: 1.5px solid rgba(0, 165, 81, 1);
    border-radius:100%;
    text-align: center;
    display: inline-block;    
    background-color:#fff;
    margin: 0.5rem;
    margin-left: 0 !important;
 }
 .checkAccordionActive img, svg{
    vertical-align: baseline !important;
}
 .checkAccordionItem{
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;
    max-height: 1.563rem; //25px;
    max-width: 1.563rem; //25px;
    border-radius:100%;
    text-align: center;
    display: inline-block;    
    background-color:#fff;
    border: 2px solid #58595B;
    margin: auto;
 }
 .checkAccordionItemActive{
    border:2px solid #00A551;
 }
 .containerCard{
    border: none;
    width: 93%;
    margin-left: 7%;
    position: relative;
 }
.grayBackgroundCards{
    background: rgba(229, 229, 229, 0.5);
    // border-radius:0 0 10px 10px;
    z-index: 1;
}
.textDarkGray{
    //styleName: Texto titulos grandes;
    color:#58595B;
    font-weight: 700;
    // font-family: 'PT Sans';
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}
.textDarkBlue{
    //styleName: Texto titulos grandes;
    color:#005DBF;
    font-weight: 700;
    // font-family: 'PT Sans';
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}
.downArrowIcon{
    margin-right: 0.5rem;
    transform: rotate(270deg);
}
.downArrowIconHorizontal{    
    margin-right: 0.5rem;
}
.containerAllContent{
    width: 85%;    
    margin:0 auto;
}
.containerAllContent > *{
    color:$darkGray;
}
.containerAllContent > input, select {
    color:$darkGray;
}
.itemTextAccordion{
    text-align: left;    
}
.inputFile{
    display: none;
}
.paragraphRequiredDocs{
    min-width:18rem;
    max-width:18rem;
}
.greenCircle{
    width:8px;
    min-width:8px;
    min-height: 8px;
    height: 8px;
    border-radius: 100%;
    background: rgba(0, 165, 81, 1);
    margin: 2px auto;
}
.labelInputFile{
    border: 1px solid #005DBF;
    border-radius: 6px;
    min-width: 172px;
    width: 172px;
    display: flex;
    justify-content: justify-content-between;
    padding:0.2rem 0.5rem;
    background-color: #fff;
}
.labelInputFile:hover{
    cursor:pointer;
}
.labelInputFile::before{
    content:"Seleccionar";
    margin-right:4rem;
    color:rgba(206, 206, 206, 1);
    font-size: 0.8rem; 
}
.secondaryBlue{
    color:rgba(0, 93, 191, 1);
}
.discardFile{
    position: absolute;
    top: 0;
    left: 100%;
}
.discardFile:hover{
    cursor: pointer;
}
.blueIcon{
    color: rgba(0, 93, 191, 1);
}
.labelFilenames{
    display: block;
    max-width:185px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.btnPrimary{
    transition: 0.3s all ease-in-out;
    background:rgba(1, 160, 246, 1);
    color: #fff;
    min-width:168px;
    min-height:32px;
    padding:0.5rem 1rem;
    text-align:center;
    border-radius: 5px;
    font-weight: 700;
    max-height:40px;
}
.btnPrimary:hover, .btnOutlineDarkBlue:hover{
    background: rgba(0, 93, 191, 1);
    color: #fff;
}
.btnPrimaryShort{
    transition: 0.3s all ease-in-out;
    background:#005DBF;
    color: #fff;
    min-width:120px;
    min-height:32px;
    padding:0.5rem 1rem;
    text-align:center;
    border-radius: 5px;
    font-weight: 700;
    max-height:40px;
}
.btnPrimaryShort:hover, .btnOutlineDarkBlue:hover{
    background: #002C91;
    color: #fff;
}
 .btnOutlineDarkBlue{
     transition: 0.3s all ease-in-out;
     color:rgba(0, 93, 191, 1);
    border: 1.5px solid #005DBF;
    border-radius: 5px;
    font-weight: 700;    
    min-width: 110px;
    min-height:19px;
    max-height:40px;
}
.btnSecondary{
    min-width: 110px;
    min-height: 32px;
    background: rgba(206, 206, 206, 1);
    color:#fff;
    border-radius: 5px;
    max-height:40px;
}
.quaternaryColor{
    color:#FF8B00;
}
.borderParagraph{
    background: #FFFFFF;
    /* Color Principal */
    border: 1.5px solid #01A0F6;
    box-sizing: border-box;
    border-radius: 15px;
    padding:1rem;
    text-align: justify;
}
.containerAccordionCheck{
    display: flex;
    position:relative;
}
.btnSecondaryDisabled{
    min-width: 110px;
    min-height: 32px;
    background: rgba(206, 206, 206, 1);
    color:#fff;
    border-radius: 5px;
    max-height:40px;
    font-size: inherit !important;
    font-weight: 400 !important;
}
.btnSecondaryDisabled:hover{
    cursor:default;
}
.btnDisabled{
    background:#CECECE;
    color: #fff;
    min-width:168px;
    min-height:32px;
    padding:0.5rem 1rem;
    text-align:center;
    border-radius: 5px;
    font-weight: 700;
    max-height:40px;
    font-size: inherit !important;
    font-weight: 700 !important;
}
.btnDisabled:hover{
 cursor:default;   
}
// margin left 2.5rem
.ml4{
    margin-left:2.5rem;
}
.blueBackgroundCard{
    background-color: rgba(0, 93, 191, 0.05);    
    margin-top: 3px;
}

.nitDigit{
    min-width: 1.7rem;
    min-height: 1.7rem;
    max-width: 1.7rem;
    max-height: 1.7rem;
    // padding:0.2rem 0.7rem;
    background: #FFFFFF;
    border: 1px solid #005DBF;
    border-radius: 6px;
    display: inline;
    text-align: center;
}
.nitLine{
    width: 6%;
    display: inline-block;
    margin: 0.3rem auto;    
}
// input:disabled {
//     border: 1px solid #CECECE;
// }

.withShort{
    width: 25.7%;
}
.longWith{
    width:50%;
}
.extraLongWith{
    width:70%;
}
.middleShort{
    width:13.8%;
}
.smallWith{
    width:11.7%;
}
.inputText{
    background: #FFFFFF;
    border: 1px solid #7FADDE;
    border-radius: 6px;
    color:$darkGray;
}
.inputTextClear{
    background: #FFFFFF;
    border: 1px solid $clearBlue;
    border-radius: 6px;
    color:$darkGray;
}
.textGroup{
    margin-right: 1rem; // 16px
    display: inline-block;
    // max-width: 9.75rem; // 156px
}
.addActiviyIcon{
    width:1.2rem;
    margin-left: 1rem;
    margin-right: 0.3rem;
}
.addActiviyIconWithOutMargin{
    width:1.2rem;
    margin-right: 0.3rem;
}
.eightyWith{
    width:84%
}
.ninetyWith{
    width:90%
}
.ninetyFiveWith{
    width:95%
}
.containerRow{
    display:flex;
    flex: 24 0 0;
    margin: 0rem 1rem;
    flex-flow: row wrap;
}
.containerRow > div:not(.ninetyWith){
    margin-right:1rem;
}
.containerRowMd{
    display:flex;
    flex: 18 0 0;
    margin: 0rem 1rem;
    flex-flow: row wrap;
}
.containerRowMd > div:not(.ninetyWith){
    margin-right:1rem;
}
.colSix{
    flex:6;
}
.colTwelve{
    flex:12;
}
.colTwelveV2{
    flex:12.9;
}
.colFour{
    flex:4;
}
.colFourV2{
    flex:4.1;
}
.colThree{
    flex:3;
}
.colEighteen{
    flex:18;
}
.colOne{
    flex:1;
}
.colFive{
    flex:5;
}
.ml1rem{
    margin-left: 1rem;
}
.flexAuto{
    flex:auto;
}
.notMarginRight{
    margin-right:0 !important;
}
.selectPersonTypeLglRpst{
    display: flex;
    flex-direction: column;
    flex:0 1 23.2%;
}
.verticalSub{
    vertical-align:sub;
}
.darkGrayColor{
    color:#58595B;
}
.checkGroup{
    display: flex;
    width: 50%;
    justify-content: space-between;
}
.modalScroll{
    overflow-y: scroll;
    max-height: 50vh;
    overflow-x: hidden;
    padding:0.5rem;
}
.maxWithSelect{
    max-width: 289px;
}
.mr0{
    margin-right: 0 !important;
}
.addButtonOnRow{
    align-self: flex-end;
    padding-bottom: 0.4rem;
    margin-left: 1rem;
    margin-right: 0;
}
.labelInputFileBank{
    border: 1px solid #005DBF;
    border-radius: 6px;
    display: flex;
    // justify-content: space-between;
    padding:0.2rem 0.5rem;
    background-color: #fff;
    margin-right: 1rem;
}
.labelInputFileBank:hover{
    cursor: pointer;
}
// .disabled{
//     opacity: 0.7;
//     border-color: rgba(118, 118, 118, 0.3) !important;
// }
.alignBetween{
    justify-content:space-between;
}
.flexEnd{
    justify-content:flex-end;
}
.containerDropzone{
    margin:1rem 0;
    width:40%;
    align-items:center;
}
.containerDropzone:hover{
    cursor:pointer;
}
.dropzoneSignature{    
    width: 100%;
    height: 5rem;
    border-radius: 10px;
    position:relative;
    display:flex;
    justify-content:center;
    text-align:center;
    border-radius: 10px;
    border: 1.5px solid rgba(0, 93, 191, 0.1);
    background-color:rgba(0, 93, 191, 0.1);
}
// .dropzoneSignature::before{
//     content:"";    
//     width:100%;    
//     z-index: -1;
// }
.imageIconDropzone{
    width:6%;
    top: 20%;    
    left:47%;
    position: absolute;
}
.signatureLabel{
    color:rgba(88, 89, 91, 1);
    font-size: 1rem;
    font-weight: bold;        
    text-align: center;
    padding-top: 0.3rem;
    margin-top: 0.5rem;
    border-top: 1.5px solid rgba(88, 89, 91, 1);
}


.containerBackground{
    width:100%;
    height:100vh;
    align-items: center;
    display: flex;
    flex-direction:column;
}

.containerLogin{
    padding:2rem;
    width: calc(398px*1.5);
    margin: auto;    
    text-align: center;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);;
}

.btnLogin{
    background-color: $clearBlue;    
    width:calc(273px * 1.5);
    border-radius:10px;
    height:4rem;
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    text-align: center;
    font-size:1.45rem;
    margin: 1rem 0;
    border:none;
}
.containerInputLogin{
    margin:1rem auto;
    background-color: #fff;
    border: 1.5px solid #01A0F6;
    border-radius: 7px;
    width:calc(273px * 1.5);
    height:48px;
    overflow:hidden;
}
.inputLogin{
    border: none;
    width:85%;
    height:45px;
    margin: auto 10px;
}
.inputLogin:focus-visible, .inputLogin:focus{
    border: none !important;
    background-color: #fff !important;
    outline-style: none;
}
.clock{filter:grayscale(1);}
.clock:hover{filter:none;}
.containerHeadDeliveries{position: relative;}
.containerHeadDeliveries{font-size: 13px;}
.containerHeadDeliveries::before{
    content:"";
    background: #005DBF;
    opacity: 0.05;
    width:100%;
    height:100%;
    position: absolute;
    padding:16px;    
    border-radius: 10px;
}   
.inputTextTable{
    width:90%;
    border:none;
    background: transparent;
    text-align:center;
}
.inputTextTable:disabled{
    border: none;
}
.inputTextTable:focus-visible,.inputTextTable:focus{
    border:none;
    outline: none;
}
.groupIcons{    
    display:flex;
    justify-content:flex-end;
    align-items:flex-end;
    flex:2;
}
.groupIcons img {
    filter:grayscale(1);
}
.groupIcons img:hover {
    filter:none;
    cursor:pointer;
}
.discountInput{
    margin: 0 1rem;
    display:flex;
}
.discountInputText{    
    border-radius: 10px;
    color:$darkGray;
    position:relative;
    border:none;
    background-color:$backgroundDarkBlue;
    transition: 0.5s all ease-in-out;
}
.discountInputText:disabled{
    border: none;
}
.discountInputText:focus-visible{
    border:1.5px solid $darkBlue;
}
.collapsedDiscount{
    transform:scale(0.1,1) translateX(200%);
    opacity:0;
    visibility: hidden;
}
.expandDiscount{
    opacity:1;
    visibility: visible;
    transform:scale(1) translateX(0%);
}
.observationsArea{
    max-height: 4rem;
    border:1px solid $darkBlue;
    border-radius: 6px;
}
.containerDropzone{
    margin:1rem 0;
    width:40%;
    align-items:center;
}
.containerDropzone:hover{
    cursor:pointer;
}
.dropzoneSignature{    
    width: 100%;
    height: 4rem;
    border-radius: 10px;
    position:relative;
    display:flex;
    justify-content:center;
    text-align:center;
    border-radius: 10px;
    border: 1.5px solid rgba(0, 93, 191, 0.1);
    background-color:rgba(0, 93, 191, 0.1);
}
.footerTableContainer{
    width:15%;
    list-style: none;
    // margin-right: 2rem;
    font-size: 0.8rem;
}
.footerTableContainer tbody tr td{
    padding:3px;
}
.footerTableContainer tbody tr:nth-child(1), 
.footerTableContainer tbody tr:nth-child(3){
    background-color:$tableEven;
}
.buttonDisabled{
    background:#CECECE;
    opacity:0.5;
    color:#fff;
}
.inputDisabled:disabled, .selectGroup:disabled, .selectInput:disabled{
    background: #F5F7FA !important; 
    opacity:1 !important;    
    border: 1px solid #7FADDE;
}
.lineBefore{position:relative}
.lineBefore::before{
    content:"";
    position:absolute;
    border-bottom:1.5px solid $darkGray;
    width: 0.7rem;
    right: 98.5%;
    bottom: 32%;
}
.lineSection{position:relative; margin-bottom: 3rem;}
.lineSection::after{
    content:"";
    position: absolute;
    border-bottom: 1px solid $clearGray;
    width: 109.6%;
    right: -62px;
    top: 150%;
}
.containerTableModal{
    max-height: 10rem;
    overflow: scroll;
}
.textareaObservations{
    height:2rem;
    max-height:4rem;
}
.textareaObservationsAuth{
    height:4rem;
    max-height:4rem;
}
.replyButtonPopUp{
    padding-left: 1em;
    text-indent: -1em;
    margin-top: .3rem;
    margin-bottom: .3rem;
    font-size: .8rem;
    cursor: pointer;
}
.replyButtonPopUp:hover::before{
    filter:grayscale(100%) invert(100%);
}
.replyButtonPopUp::before{
    content: "";
    background-image: url("../../assets/img/icons/reply.svg");
    background-repeat: no-repeat;
    background-size:cover;
    background-position:left;
    display: inline-block;
    width: .7rem;
    height: .65rem;
    margin-right: 5px;
}
.textAreaJustification{
    border: 1px solid #01A0F6;
    max-width: 100%;
    max-height: 6rem;
    border-radius: 6px;
}
.inputIconEdit{
    background-image:url("../../assets/img/icons/editar.svg");
    background-repeat: no-repeat;
    background-size: 5%;
    background-position-x:95%;
    background-position-y:50%;
}
.inputIconEditWithOriginal{
    background-image:url("../../assets/img/icons/editar.svg");
    background-repeat: no-repeat;
    background-size: 5%;
    background-position-x:90%;
    background-position-y:50%;
}
.justificationRenew{
    border-radius: 6px;
    max-height: 5rem;
    border:1.5px solid $darkBlue;
}
.enterLineIcon{
    padding-left: 1em;
    text-indent: -1em;
    margin-top: .3rem;
    margin-bottom: .3rem;
    font-size: .8rem;
    cursor: pointer;
}
.enterLineIcon:hover::before{
    filter:grayscale(100%) invert(100%);
}
.enterLineIcon::before{
    content: "";
    background-image: url("../../assets/img/icons/deshacer.svg");
    background-repeat: no-repeat;
    background-size:contain;
    background-position-x:10%;
    background-position-y:100%;
    display: inline-block;
    width: .7rem;
    height: .65rem;
    margin-right: 5px;
}