@import "../../../styleConstants.scss";

table {
  font-family: "PT PT Sans Caption", "Helvetica Neue";
}

thead {
  border-radius: 10px 10px 0 0;
}

// tocó usar important
.headers {
  background-color: $clearBlue !important;
  color: $white !important;
  font-weight: bold !important;
  font-size: 15px !important;
  padding: 0 !important;
}

.darkHeaders {
  background-color: $darkBlue !important;
  color: $white !important;
  font-weight: bold !important;
  font-size: 15px !important;
  padding: 0 !important;
}


.stickyValues1 {
  color: $darkGray;
  padding-left: 1rem;
  bottom: 28.5%;
  left: 0;
  position: sticky;
  z-index: 2;
  background-color: $white;
}
.stickyValues2 {
  color: $darkGray;
  padding-left: 1rem;
  bottom: calc(9.5% * 2);
  left: 0;
  position: sticky;
  z-index: 2;
  background-color: $tableEven;
}
.stickyValues3 {
  color: $darkGray;
  padding-left: 1rem;
  bottom: 9.5%;
  left: 0;
  position: sticky;
  z-index: 2;
  background-color: $white;
}
.stickyValues4 {
  color: $darkGray;
  padding-left: 1rem;
  bottom: 0;
  left: 0;
  position: sticky;
  z-index: 2;
  background-color: $tableEven;
}

.stickyValues1 > * {
  text-align: left;
}
.stickyValues2 > * {
  text-align: left;
}
.stickyValues3 > * {
  text-align: left;
}
.stickyValues4 > * {
  text-align: left;
}

.colWhite {
  background-color: $tableEven;
  border: none;
}
.textareaStyle {
  width: 95%;
  height: 4rem;
  max-height: 5rem;
  min-height: 2.5rem;
  border: 1.5px solid $clearBlue;
  border-radius: 6px;
  background-color: $white;
}
.textareaStyle:disabled {
  background-color: $bgInputDisabled;
}
.labelFont {
  font-size: 0.8rem;
  color: #005dbf;
  font-family: "PT Sans Caption";
  line-height: 0.971rem;
  font-weight: 400;
}
.labelFontGray {
  font-size: 13px;
  color: #58595b;
  font-family: "PT Sans Caption";
  line-height: 0.971rem;
  font-weight: 400;
}
