@import "../../styleConstants.scss";

.container1 {
    display: flex;
    flex-direction: row;
}

.throwThisToTheBottom {
    display: flex;
    flex-direction: column-reverse;
}

//zone of new angie made tables
.container2 {
    //flex:2;
    //margin-top:15px;
    display: flex;
    flex-direction: column;
}

.textarea {
    resize: none;
    font-size: 12px;
}
.subtitle {
    margin: 16px 0 0 0;
    color: #005dbf;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
}

.styleSelect {
    background: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.container3 {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.ItemTiny {
    flex: 2;
    display: flex;
    flex-direction: column;
    width: 27px;
}

.ItemSmall {
    flex: 6;
    display: flex;
    flex-direction: column;
}

.ItemSmall_new {
    flex: 3;
    display: flex;
    flex-direction: column;
}

.Item {
    flex: 0.6;
    display: flex;
    flex-direction: column;
}

.imagePadding {
    margin-top: 8px;
}
.imagePaddingNew {
    margin-top: 25px;
}

.letterPadding {
    padding-left: 8px;
    color: #005dbf;
}

.btn {
    cursor: pointer;
    filter: brightness(0) saturate(100%) invert(20%);
}

.btnNew {
    cursor: pointer;
    filter: grayscale(100%);
}

.btnNew:hover {
    filter: none;
}

.buttonCreatCount {
    flex-direction: row;
    float: right;
    width: 185px;
    background-color: #f5f7fa;
    height: 30px;
    display: inline-flex;
    border-radius: 10px;
    justify-content: space-evenly;
}
.Boton {
    cursor: pointer;
    flex-direction: row;
    width: 64px;
    background-color: rgba(0, 93, 191, 0.1);
    height: 24px;
    border-radius: 10px;
}

.filesDir {
    flex-direction: row;
    display: inline-flex;
}
.position {
    position: relative;
}

.buttonCreatCount > p {
    size: 76px;
    line-height: 16px;
    margin-bottom: 0;
    color: #005dbf;
    font-weight: 700;
}

.deleteicon {
    position: relative;
    flex: 1;
    display: flex;
}
.deleteicon span {
    position: absolute;
    display: block;
    top: -6px;
    right: -6px;
    width: 16px;
    height: 16px;
    font-size: 10px;
    text-align: center;
    color: #fff;
    background: $darkBlue;
    cursor: pointer;
    border-radius: 50%;
}
.deleteicon input {
    flex: 1;
}

.imagePaddingType {
    width: 20px;
    height: 20px;
    margin: 5px 0px 0px 6px;
}

.buttonCreatCount > img {
    padding: 0px 0px 0px 0px;
    margin-top: 6px;
}
.ellipsis {
    width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.labelNew {
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    line-height: 15.53px;
    margin-bottom: 1.5px;
    align-content: left;
    color: #005dbf;
    padding: 6px 1px 3px 4px;
}

.justify {
    justify-content: space-between;
}

.btn:hover {
    filter: none;
}

.ItemTiny_new {
    flex: 2.36;
    display: flex;
    flex-direction: column;
    width: 27px;
}

.crudModalLabel {
    font-size: 0.75rem;
    margin: 0.4rem 0.3rem 0 0.3rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: $darkBlue;
}
.mainRow {
    display: inline-flex;
    width: 100%;
    vertical-align: middle;
    justify-content: space-between;
    padding: 0 0.5rem;
}

.crudModalInputDate {
    font-size: 1vw;
    flex: 1 1 auto;
}
.backgroundModal {
    background: rgba(0, 93, 191, 0.05);
    border-radius: 10px;
}
.ItemTiny2 {
    flex: 1.33;
    display: flex;
    flex-direction: column;
    width: 25px;
}

.ItemTiny3 {
    flex: 2;
    display: flex;
    flex-direction: column;
}

.ItemMedium {
    flex: 1;
    display: flex;
    flex-direction: row;
    width: 27px;
}
.Aling {
    display: flex;
    flex: 1;
    margin-left: auto;
    position: relative;
}

.AlingNew {
    display: flex;
    flex: 1;
    margin-left: auto;
    position: relative;
    margin-top: -22px;
}
.inputMargin {
    margin-right: 8px;
}
.inputMarginNew {
    margin-right: 15px;
}
.negativeMargin {
    margin-left: -13px;
}

.inputMarginleft {
    margin-left: 15px;
    margin-right: 15px;
}
.inputMarginleftNew {
    margin-left: 15px;
    margin-right: 15px;
}

.paddingButton {
    padding-bottom: 15px;
}

.btnPrimary {
    background-color: #fff !important;
    border: 1.5px solid $clearBlue !important;
    color: $clearBlue !important;
    min-width: 110px;
    font-weight: 700;
    font-size: 15px;
    line-height: 19, 41px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.btnPrimary:hover {
    background-color: $clearBlue !important;
    border: 1.5px solid $darkBlue !important;
    color: #fff !important;
}

.btnPrimary:disabled {
    background-color: $clearGray;
    border: 1.5px solid $clearGray;
    color: #fff;
    opacity: 1;
}

.btnSecondary {
    background-color: $darkBlue !important;
    border: 1.5px solid $darkBlue !important;
    color: #fff !important;
    min-width: 110px;
    font-weight: 700;
    font-size: 15px;
    line-height: 19, 41px;
    padding-top: 6px;
    padding-bottom: 6px;
    text-align: center;
}

.btnSecondary:hover {
    background-color: $darkBlue !important;
    border: 1.5px solid $darkBlue !important;
    color: #fff !important;
}

.btnSecondary:disabled {
    background-color: #fff !important;
    border: 1.5px solid $clearGray !important;
    color: $clearGray !important;
    opacity: 1;
}

.ItemCheckbox {
    display: flex;
    margin-top: 24px;
    //flex-direction: column-reverse;
}

.registerInputs {
    border: 1px solid #7FADDE !important;
    border-radius: 5px !important;
    width: 100%;
    padding: 0 0.5rem !important;
    height: 24px;
    font-size: 12px;
}

.registerInputs:disabled {
    background-color: #f5f7fa;
    border: 1px solid #7FADDE;
}

.selectRegister2 {
    height: 30px;
    width: 100%;
    border: 1.5px solid #7FADDE;
    border-radius: 5px;
    flex: 1;
}

.greyRow {
    display: flex;
    flex-direction: row;
    background-color: #ddd;
    border-radius: 0.5rem;
    margin-top: 1rem;
    padding: 0.5rem;
    padding: 0.5rem 2rem;
}

.textArea {
    height: 32px;
    border: 1.5px solid #7FADDE;
    border-radius: 6px;
}
.textAreaClear {
    height: 50px;
    border: 1.5px solid $clearBlue;
    border-radius: 6px;
    color: $darkGray;
    padding: 5px;
    min-height: 30px;
    max-height: 100px;
}
.textArea_new {
    height: 27px;
    border: 1.5px solid #7FADDE;
    border-radius: 6px;
}

.textArea_new2 {
    min-height: 142px;
    width: 100%;
    border: 1.5px solid #7FADDE;
    border-radius: 6px;
}

.textArea_new3 {
    min-height: 142px;
    width: 100%;
    border: 1.5px solid #7FADDE ;
    border-radius: 6px;
    padding: 10px; 
    outline: none; 
    color: #58595B;
    height: 3rem;
}

.bottomRow {
    margin: 20px 0;
    display: inline-flex;
    flex-direction: row-reverse;
    width: 100%;
    vertical-align: middle;
    padding: 0 0.5rem;
}

.bottomRow > Button {
    margin-left: 12px;
}

.tableButton {
    cursor: pointer;
    color: $tableText;
    text-decoration: none;
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 5px;
    font-size: 12px;
    line-height: 16px;
}

.tableButton:hover {
    color: $darkBlue;
    padding-top: 0px;
    transition-duration: 0.2s;
}

.tableSlash {
    color: $tableText;
    font-size: 12px;
    line-height: 16px;
}

.traerReq {
    display: flex;
    min-width: 140px;
    height: 30px;
    font-weight: Bold;
    font-size: 12px;
    line-height: 16px;
    color: #697387;
    cursor: pointer;

    border-radius: 10px;
    margin: 0 0 0 auto;
}

.traerReq:hover {
    background-color: #f5f7fa;
}

.traerReq > p {
    flex: 1;
    margin: 8px 6px 4px 6px;
}

.traerReq:hover > p {
    color: #005dbf;
}

.traerReq:hover > img {
    filter: brightness(0) saturate(100%) invert(20%) sepia(94%) saturate(1860%) hue-rotate(196deg) brightness(107%)
        contrast(105%);
}

.pseudoInput {
    padding: 1px;
    position: relative;
    cursor: pointer;
}

.pseudoInput > p {
    margin: 0;
    color: #cecece;
    font-size: 16px;
    line-height: 24px;
    height: 22px;
    padding-left: 8px;
    overflow: hidden;
}
.size {
    width: 32px;
    height: 32px;
}
.sizeNew {
    width: 25px;
    height: 25px;
}
.sizeNew2 {
    width: 16px;
    height: 16px;
}

.popupReqActive::before {
    width: 12px;
    height: 12px;
    cursor: default;
}

.pseudoInput > img {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 15px;
    height: 22px;
    margin: 0;
}

.urgente {
    color: #697387;
    line-height: 16px;
    font-size: 12px;
    display: inline;
}
.newArticle {
    margin: 0 5px;
    border: 1.5px solid $orange;
    border-radius: 5px;
    padding: 0 15px;
}

.adjustIcon {
    cursor: pointer;
    width: 20px;
    margin: 0;
}
.pointer {
    cursor: pointer;
}
.pointerNew {
    cursor: pointer;
    margin-top: -3px;
}

//added for article insert ts-147
.orangeIcon {
    fill: $orange;
    filter: saturate(3000%) hue-rotate(190deg);
}

.orangeBorder {
    border: 1.5px solid $orange;
    border-radius: 5px;
}

.orangeText {
    color: $orange;
    font-weight: Bold;
    font-size: 12px;
    line-height: 16px;
}

.orangeCheck {
    background-color: $orange;
    border-color: $orange;
}
@import "../../styleConstants.scss";

.container1 {
    display: flex;
    flex-direction: row;
}

.throwThisToTheBottom {
    display: flex;
    flex-direction: column-reverse;
}

//zone of new angie made tables
.container2 {
    //flex:2;
    //margin-top:15px;
    display: flex;
    flex-direction: column;
}

.ItemSmall1 {
    flex: 4.7;
    display: flex;
    flex-direction: column;
}

.crudModalLabelGris {
    font-size: 12px;
    margin: 0.4rem 0.3rem 0 0.3rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: $darkGray;
}

.LabelIntoDropzone {
    font-size: 12px;
    margin: 0.4rem 0.3rem 0 0.3rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #cecece;
    font-weight: 400;
}

.registerInputsBlue {
    border: 1px solid #7FADDE;
    border-radius: 5px;
    width: 100%;
    padding: 0 .5rem;
    height: 24px;
    max-height: 24px;
    font-size: 12px;
    color: $darkGray;
    outline: none
}

.registerInputsBlue:disabled {
    background-color: #f5f7fa;
    border: 1px solid #7FADDE;
}

.registerInputsBlue[type="date"] {
    color: #7FADDE;
    font-weight: bold;
    outline: none
}


.registerInputsClearBlue {
    border: 1px solid #7FADDE;
    border-radius: 5px;
    width: 100%;
    padding: 0 0.5rem;
    height: 24px;
    font-size: 12px;
    outline: none;
}

.registerInputsClearBlue:disabled {
    background-color: #f5f7fa;
    border: 1px solid #7FADDE;
}

.registerInputsGris {
    border: 1px solid #7FADDE;
    border-radius: 5px;
    width: 100%;
    padding: 0 0.5rem;
    height: 24px;
    color: #58595b;
    font-size: 12px;
    outline: none;
}

.textarea {
    resize: none;
}

.size {
    width: 32px;
    height: 32px;
}
.subtitle {
    margin: 16px 0 0 0;
    color: #01a0f6;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
}

.styleSelect {
    background: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.container3 {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.ItemTiny {
    flex: 2;
    display: flex;
    flex-direction: column;
    width: 27px;
}

.ItemSmall {
    flex: 6;
    display: flex;
    flex-direction: column;
}

.inputAlling {
    flex-direction: column;
    align-items: flex-start;
}
.ItemSmall1 {
    flex: 4.7;
    display: flex;
    flex-direction: column;
}

.ItemSmall_new {
    flex: 3;
    display: flex;
    flex-direction: column;
}

.Item {
    flex: 0.58;
    display: flex;
    flex-direction: column;
}
.Item3 {
    flex: 0.62;
    display: flex;
    flex-direction: column;
}
.Item2 {
    flex: 0.53;
    //margin-right:3px;
    display: flex;
    flex-direction: column;
}

.imagePadding {
    margin-top: 8px;
}
.imagePaddingNew {
    margin-top: 25px;
}

.letterPadding {
    padding-left: 8px;
    color: #005dbf;
}

.btn {
    cursor: pointer;
    filter: brightness(0) saturate(100%) invert(20%);
}

.btnNew {
    cursor: pointer;
    filter: grayscale(100%);
}

.btnNew:hover {
    filter: none;
}

.imagePadding2 {
    margin-top: -3px;
}

.buttonCreatCount {
    flex-direction: row;
    float: right;
    width: 185px;
    background-color: #f5f7fa;
    height: 30px;
    display: inline-flex;
    border-radius: 10px;
    justify-content: space-evenly;
}
.Boton {
    cursor: pointer;
    flex-direction: row;
    width: 64px;
    background-color: rgba(0, 93, 191, 0.1);
    height: 24px;
    border-radius: 10px;
}

.filesDir {
    flex-direction: row;
    display: inline-flex;
}
.position {
    position: relative;
}

.buttonCreatCount > p {
    size: 76px;
    line-height: 16px;
    margin-bottom: 0;
    color: #005dbf;
    font-weight: 700;
}

.deleteicon {
    position: relative;
    flex: 1;
    display: flex;
}
.deleteicon span {
    position: absolute;
    display: block;
    top: -6px;
    right: -6px;
    width: 16px;
    height: 16px;
    font-size: 10px;
    text-align: center;
    color: #fff;
    background: $darkBlue;
    cursor: pointer;
    border-radius: 50%;
}
.size {
    width: 32px;
    height: 32px;
}
.deleteicon input {
    flex: 1;
}

.imagePaddingType {
    width: 20px;
    height: 20px;
    margin: 5px 0px 0px 6px;
}

.buttonCreatCount > img {
    padding: 0px 0px 0px 0px;
    margin-top: 6px;
}
.ellipsis {
    width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.labelNew {
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    line-height: 15.53px;
    margin-bottom: 1.5px;
    align-content: left;
    color: #005dbf;
    padding: 6px 1px 3px 4px;
}

.justify {
    justify-content: space-between;
}

.btn:hover {
    filter: none;
}

.ItemTiny_new {
    flex: 2.36;
    display: flex;
    flex-direction: column;
    width: 27px;
}

.crudModalLabel {
    font-size: 0.75rem;
    margin: 0.4rem 0.3rem 0 0.3rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: $darkBlue;
}
.mainRow {
    display: inline-flex;
    width: 100%;
    vertical-align: middle;
    justify-content: space-between;
    padding: 0 0.5rem;
}

.crudModalInputDate {
    font-size: 1vw;
    flex: 1 1 auto;
}
.backgroundModal {
    background: rgba(0, 93, 191, 0.05);
    border-radius: 10px;
    padding-bottom: 16px;
    padding-left: 15px;
    // margin-right: -20px;
    padding-right: 20px;
}

.backgroundModal_new {
    border-radius: 10px;
    padding-bottom: 16px;
    padding-left: 15px;
    margin-right: -20px;
    padding-right: 20px;
}

.ItemTiny2 {
    flex: 1.33;
    display: flex;
    flex-direction: column;
    width: 25px;
}

.ItemTiny_cantidad {
    flex: 1.09;
    display: flex;
    flex-direction: column;
    width: 25px;
}
.ItemTiny4 {
    flex: 3;
    display: flex;
    flex-direction: column;
    width: 25px;
}

.ItemTiny3 {
    flex: 2;
    display: flex;
    flex-direction: column;
}

.ItemMedium {
    flex: 1;
    display: flex;
    flex-direction: row;
    width: 27px;
}
.Aling {
    display: flex;
    flex: 1;
    margin-left: auto;
    position: relative;
}
.inputMargin {
    margin-right: 8px;
}
.inputMarginNew {
    margin-right: 15px;
}
.inputMarginleft_new {
    margin-left: 15px;
}
.negativeMargin {
    margin-left: -13px;
}
.line {
    align-self: flex-end;
}
.inputMarginleft {
    margin-left: 15px;
    margin-right: 15px;
}
.inputMarginleftNew {
    margin-left: 15px;
    margin-right: 15px;
}

.paddingButton {
    padding-bottom: 15px;
}

.btnPrimary {
    background-color: #fff !important;
    border: 1.5px solid $clearBlue !important;
    color: $clearBlue !important;
    min-width: 110px;
    font-weight: 700;
    font-size: 15px;
    line-height: 19, 41px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.btnPrimary:hover {
    background-color: $clearBlue !important;
    border: 1.5px solid $darkBlue !important;
    color: #fff !important;
}

.btnPrimary:disabled {
    background-color: $clearGray;
    border: 1.5px solid $clearGray;
    color: #fff;
    opacity: 1;
}

.btnSecondary {
    background-color: $darkBlue !important;
    border: 1.5px solid $darkBlue !important;
    color: #fff !important;
    min-width: 110px;
    font-weight: 700;
    font-size: 15px;
    line-height: 19, 41px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.btnSecondary:hover {
    background-color: $darkBlue !important;
    border: 1.5px solid $darkBlue !important;
    color: #fff !important;
}

.btnSecondary:disabled {
    background-color: #fff !important;
    border: 1.5px solid $clearGray !important;
    color: $clearGray !important;
    opacity: 1;
}

.ItemCheckbox {
    display: flex;
    margin-top: 24px;
    //flex-direction: column-reverse;
}
.registerInputs {
    border: 1px solid #7FADDE;
    border-radius: 5px;
    width: 100%;
    padding: 0 0.5rem;
    height: 24px;
    font-size: 13px;
}

.selectRegister {
    min-height: 24px;
    width: 100%;
    border: 1.5px solid #7FADDE;
    border-radius: 5px;
    flex: 1;
    font-size: 12px
}
.selectRegisterClearBlue {
    height: 24px;
    width: 100%;
    border: 1.5px solid $clearBlue;
    border-radius: 5px;
    flex: 1;
}

.selectRegisterClearBlue:disabled {
    border: 1.5px solid $clearBlue;
    background-color: #f5f7fa;
    height: 24px;
    width: 100%;
    border-radius: 5px;
    flex: 1;
}

.selectRegisterBlue {
    height: 27px;
    width: 100%;
    border: 1.5px solid #005dbf;
    border-radius: 5px;
    flex: 1;
    color: #005dbf;
    font-weight: 700;
}

.greyRow {
    display: flex;
    flex-direction: row;
    background-color: #ddd;
    border-radius: 0.5rem;
    margin-top: 1rem;
    padding: 0.5rem;
    padding: 0.5rem 2rem;
}

.textAreaBig {
    height: 70px;
    border: 1.5px solid #7FADDE;
    border-radius: 6px;
}

.textArea {
    height: 32px;
    border: 1.5px solid #7FADDE;
    border-radius: 6px;
}

.textArea_new {
    height: 24px;
    border: 1.5px solid #7FADDE;
    border-radius: 6px;
}

.bottomRow {
    margin: 20px 0;
    display: inline-flex;
    flex-direction: row-reverse;
    width: 100%;
    vertical-align: middle;
    padding: 0 0.5rem;
}

.bottomRow > Button {
    margin-left: 12px;
}

.tableButton {
    cursor: pointer;
    color: $tableText;
    text-decoration: none;
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 5px;
    font-size: 12px;
    line-height: 16px;
}

.tableButton:hover {
    color: $darkBlue;
    padding-top: 0px;
    transition-duration: 0.2s;
}

.tableSlash {
    color: $tableText;
    font-size: 12px;
    line-height: 16px;
}

.traerReq {
    display: flex;
    min-width: 140px;
    height: 30px;
    font-weight: Bold;
    font-size: 12px;
    line-height: 16px;
    color: #697387;
    cursor: pointer;

    border-radius: 10px;
    margin: 0 0 0 auto;
}

.proArrowWrapper {
    position: absolute;
    right: 15px;
    top: 40%;
    transform: translateY(-50%);
}
.proArrow {
    display: inline-block;
    border-style: solid;
    border-color: $clearBlue;
    border-width: 0 2px 2px 0;
    padding: 2.5px;
    vertical-align: middle;
    transition: transform 0.3s;
    transform: rotate(45deg);
}

.proArrow2 {
    display: inline-block;
    border-style: solid;
    border-color: $clearBlue;
    border-width: 0 2px 2px 0;
    padding: 2.5px;
    vertical-align: middle;
    transition: transform 0.3s;
    transform: rotate(-135deg);
}

.traerReq:hover {
    background-color: #f5f7fa;
}

.traerReq > p {
    flex: 1;
    margin: 8px 6px 4px 6px;
}

.traerReq:hover > p {
    color: #005dbf;
}

.traerReq:hover > img {
    filter: brightness(0) saturate(100%) invert(20%) sepia(94%) saturate(1860%) hue-rotate(196deg) brightness(107%)
        contrast(105%);
}

.pseudoInput {
    padding: 1px;
    position: relative;
    cursor: pointer;
}

.pseudoInput > p {
    margin: 0;
    color: #cecece;
    font-size: 16px;
    line-height: 24px;
    height: 22px;
    padding-left: 8px;
    overflow: hidden;
}
.size {
    width: 32px;
    height: 32px;
}
.sizeNew {
    width: 25px;
    height: 25px;
}
.sizeNew2 {
    width: 16px;
    height: 16px;
}

.popupReqActive::before {
    width: 12px;
    height: 12px;
    cursor: default;
}

.pseudoInput > img {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 15px;
    height: 22px;
    margin: 0;
}

.urgente {
    color: #697387;
    line-height: 16px;
    font-size: 12px;
    display: inline;
}
.newArticle {
    margin: 0 5px;
    border: 1.5px solid $orange;
    border-radius: 5px;
    padding: 0 15px;
}

.adjustIcon {
    cursor: pointer;
    width: 20px;
    margin: 0;
}
.pointer {
    cursor: pointer;
}
.pointerNew {
    cursor: pointer;
    margin-top: -4px;
}

//added for article insert ts-147
.orangeIcon {
    fill: $orange;
    filter: saturate(3000%) hue-rotate(190deg);
}

.orangeBorder {
    border: 1.5px solid $orange;
    border-radius: 5px;
}

.orangeText {
    color: $orange;
    font-weight: Bold;
    font-size: 12px;
    line-height: 16px;
}

.orangeCheck {
    background-color: $orange;
    border-color: $orange;
}

.labelInfo {
    color: #58595b;
    font-size: 15px;
    line-height: 17px;
}

.divfir {
    border: 1px solid $darkBlue;
    height: 80px;
    border-radius: 10px;
    margin-top: 30px;
}

.divfir2 {
    border: 1px solid #7FADDE;
    height: 80px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: 0.4s;
}

.divfir2:hover {
    background-color: #f6f7f7;
}
.Search {
    border: rgba(0, 93, 191, 0.1);
    background-color: rgba(0, 93, 191, 0.1);
    border-radius: 5px;
    width: 100%;
    padding: 0 0.5rem;
    height: 24px;
    color: $darkGray;
    font-size: 12px;
    outline: none;
}

.Search::placeholder {
    color: #73a5db;
    font-size: 12px;
}

.imageInputLabel {
    max-width: 280px;
    max-height: 56px;
    border-radius: 100%;
    position: absolute;
    bottom: 1px;
    padding: 4px;
    background: #fff;
    right: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
    display: flex;
}

.hoverPointer:hover {
    cursor: pointer;
    //filter: brightness(0) saturate(100%) invert(43%) sepia(100%) saturate(1319%) hue-rotate(171deg) brightness(95%) contrast(104%);
}

.profilepic {
    width: 210px;
    height: 56px;
    border-radius: 100%;
}

.profilepic2 {
    width: 145px;
    height: 56px;
    border-radius: 10px;
}
.imageInputBtn {
    display: none;
}

.pseudoInput1 {
    padding: 1px;
    position: relative;
    cursor: pointer;
}

.ellipsisNew {
    width: 145px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.btnPrimaryDark {
    background-color: $darkBlue !important;
    border: 1.5px solid $darkBlue !important;
    color: #fff !important;
    min-width: 110px;
    font-weight: 700;
    font-size: 15px;
    line-height: 19, 41px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.btnPrimaryDark:hover {
    background-color: $darkBlue !important;
    border: 1.5px solid $clearBlue !important;
    color: #fff !important;
}

.btnPrimaryDark:disabled {
    background-color: $clearGray !important;
    border: 1.5px solid $clearGray !important;
    color: #fff !important;
    opacity: 1 !important;
}
.btnExtra {
    background-color: #fff !important;
    border: 1.5px solid $darkBlue !important;
    color: $darkBlue !important;
    min-width: 110px;
    font-weight: 700;
    font-size: 15px;
    line-height: 19, 41px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.btnExtra:hover {
    background-color: $darkBlue !important;
    border: 1.5px solid $darkBlue !important;
    color: #fff !important;
}

.btnExtra:disabled {
    background-color: #fff !important;
    border: 1.5px solid $clearGray !important;
    color: $clearGray !important;
    opacity: 1;
}

.btnExtra:disabled > img {
    filter: brightness(0) saturate(100%) invert(95%) sepia(0%) saturate(286%) hue-rotate(155deg) brightness(90%)
        contrast(86%);
}

.changeColor {
    filter: brightness(0) saturate(100%) invert(95%) sepia(0%) saturate(286%) hue-rotate(155deg) brightness(90%)
        contrast(86%);
}

.changeColorClearBlue {
    filter: brightness(0) saturate(100%) invert(40%) sepia(93%) saturate(1491%) hue-rotate(175deg) brightness(101%)
        contrast(98%);
}

.changeColorToGray {
    filter: brightness(0) saturate(100%) invert(95%) sepia(1%) saturate(20%) hue-rotate(321deg) brightness(89%)
        contrast(89%);
}

.btnImage {
    cursor: pointer;
}
.btnImage:hover > img {
    filter: brightness(0) saturate(100%) invert(47%) sepia(14%) saturate(7462%) hue-rotate(172deg) brightness(98%)
        contrast(108%);
}

.btnSecondary2 {
    background-color: $darkBlue !important;
    border: 1.5px solid $darkBlue !important;
    color: #fff !important;
    min-width: 110px;
    font-weight: 700;
    font-size: 15px;
    line-height: 19, 41px;
    padding-top: 6px;
    padding-bottom: 6px;
    text-align: center;
}

.btnSecondary2:hover {
    background-color: $darkBlue !important;
    border: 1.5px solid $darkBlue !important;
    color: #fff !important;
}

.btnSecondary2:disabled {
    background-color: $clearGray !important;
    border: 1.5px solid $clearGray !important;
    color: #fff !important;
    opacity: 1;
}


.btnSecondary3 {
    background-color: $clearBlue !important;
    border: 1.5px solid $clearBlue !important;
    color: #fff !important;
    min-width: 110px;
    font-weight: 700;
    font-size: 15px;
    line-height: 19, 41px;
    padding-top: 6px;
    padding-bottom: 6px;
    text-align: center;
}

.btnSecondary3:hover {
    background-color: $darkBlue !important;
    border: 1.5px solid $darkBlue !important;
    color: #fff !important;
}

.btnSecondary3:disabled {
    background-color: $clearGray !important;
    border: 1.5px solid $clearGray !important;
    color: #fff !important;
    opacity: 1;
}

.OrdSlideButton{
    background: #F7FEFF;
    box-shadow: 0px 0px 6px rgba(19, 19, 19, 0.2);
    border-radius: 10px;
  }