.auth-section {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F5F7FA;
    position: relative;
    font-size: 13px !important;

    @at-root #{&}__card {
        width: 100%;
        max-width: 500px !important;
        height: 100% !important;
        max-height: 600px !important;
        padding: 50px;
        margin: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 10;
    }
}

.shape {
    position: absolute;

    @at-root #{&}-1 {
        top: 0;
        right: 0;
    }

    @at-root #{&}-2 {
        bottom: 0;
        left: 0;
    }
}

.fs12px{
    font-size: 12px;
}

.fs21px{
    font-size: 21px;
}