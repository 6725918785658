@import "../../styleConstants.scss";

@font-face {
  font-family: "PT Sans Caption";
  src: url("../../assets/fonts/PT_Sans_Caption/PTSansCaption-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "PT Sans Caption";
  src: url("../../assets/fonts/PT_Sans_Caption/PTSansCaption-Regular.ttf") format("truetype");
}

.genericTable { 
  font-family: "PT Sans Caption";
  font-weight: 400;
  font-style: normal;
  width: 100%;
  margin-top: 1rem;
  text-align: center;
  border-spacing: 0;
}

.genericTable>tbody>tr>td {
  padding: 0.4rem 0;
  // text-transform: lowercase;
  background-color: $white;
  color: $tableText;
  font-size: 13px;
  line-height: 16.8px;
  font-weight: 500;
  vertical-align: middle;
  border-bottom: 1px solid #9bdee7;
  overflow-wrap: anywhere;
}

.genericTable>tbody>tr:last-child td{
  border-bottom: 1px solid transparent;
}

.genericTable>tbody>tr:first-child td{
  border-top: 1px solid transparent;
}

.genericTable>tbody>tr>td::first-letter {
  text-transform: capitalize;
}

// .genericTable> tbody> tr:nth-of-type(even)>td {
//     background-color: $tableEven;
// }

.genericTable>tbody>tr:hover>td {
  background-color: $tableHover;
  transition: 0.3s all ease-in-out;
}

.genericTableAdmission>tbody>tr:hover>td {
  background-color: $ordClearYellow;
}

.hoverLight>tbody>tr:hover>td {
  background-color: #e2f8fb !important;
}

.genericTableAdmission>tbody>tr:hover>td {
  background-color: $ordClearYellow;
  transition: 0.3s all ease-in-out;
}

.genericTable>tbody>tr:last-child>td:first-child {
  border-bottom-left-radius: 10px;
}

.genericTable>tbody>tr:last-child>td:last-child {
  border-bottom-right-radius: 10px;
}

.xxx>tbody>tr:last-child>td:first-child {
  border-bottom-left-radius: 0px;
}

.xxx>tbody>tr:last-child>td:last-child {
  border-bottom-right-radius: 0px;
}

.xxx >tbody>tr {
  border: 0px;
}

.thead>tr>th {
  font-size: 15px;
  line-height: 19.4px;
  padding: 0.5rem 0;
}

.theadLight {
  color: $ordTableTextColor;
  background-color: $ordTableHeadBgColor;
  border-bottom-width: 0px !important;
}

.theadDark {
  color: $white;
  background-color: $darkBlue;
  border-radius: 10px 10px 0px 0px;
}



.theadBottomless {
  color: $darkBlue;
}

.thead>tr>th:first-child {
  border-top-left-radius: 10px;
}

.thead>tr>th:last-child {
  border-top-right-radius: 10px;
}

.genericTable>tbody>tr.orange>td {
  background-color: $tableAddition;
}

.genericTable>tbody>tr.orange:hover>td {
  background-color: $tableAdditionHover;
  transition: 0.3s all ease-in-out;
}

// FOR TABLE TO NEED RECORDS IN LOWER CASE
.genericTableLower {
  font-family: "PT Sans Caption";
  font-weight: 400;
  font-style: normal;
  width: 100%;
  margin-top: 1rem;
  text-align: left;
  border-spacing: 0;
}

.genericTableLower>tbody>tr>td {
  border: none;
  padding: 6.5px;
  background-color: $white;
  color: $tableText;
  font-size: 13px;
  line-height: 16.8px;
  font-weight: 500;
  vertical-align: middle;
}

.genericTableLower>tfoot>tr>td {
  border: none;
  padding: 4px;
  background-color: $white;
  color: $tableText;
  font-size: 13px;
  line-height: 16.8px;
  font-weight: 500;
  vertical-align: middle;
}

.genericTableLower>tbody>tr:nth-of-type(even)>td {
  background-color: $tableEven;
}

.genericTableLower>tfoot>tr:nth-of-type(odd)>td {
  background-color: $tableEven;
}

.genericTableLower>tbody>tr:hover>td {
  background-color: $tableHover;
  transition: 0.3s all ease-in-out;
}

.genericTableLower>tbody>tr:last-child>td:first-child {
  border-bottom-left-radius: 10px;
}

.genericTableLower>tbody>tr.orange>td {
  background-color: $tableAddition;
}

.genericTableLower>tbody>tr.orange:hover>td {
  background-color: $tableAdditionHover;
  transition: 0.3s all ease-in-out;
}

.genericTableLower>tfoot>tr:hover>td {
  background-color: $tableHover;
  transition: 0.3s all ease-in-out;
}

.genericTableLower>tfoot>tr:last-child>td:first-child {
  border-bottom-left-radius: 10px;
}

.genericTableLower>tfoot>tr.orange>td {
  background-color: $tableAddition;
}

.genericTableLower>tfoot>tr.orange:hover>td {
  background-color: $tableAdditionHover;
  transition: 0.3s all ease-in-out;
}

.genericTableLower>tfoot>tr>td {
  border: none;
  padding: 4px;
  background-color: $white;
  color: $tableText;
  font-size: 13px;
  line-height: 16.8px;
  font-weight: 500;
  vertical-align: middle;
}

.genericTableLower>tfoot>tr:nth-of-type(odd)>td {
  background-color: $tableEven;
}

.genericTableLower>tfoot>tr:last-child>td:last-child {
  border-bottom-right-radius: 10px;
}

.genericTableLower>tfoot>tr:hover>td {
  background-color: $tableHover;
  transition: 0.3s all ease-in-out;
}

.genericTableLower>tfoot>tr:last-child>td:first-child {
  border-bottom-left-radius: 10px;
}

.genericTableLower>tfoot>tr:last-child>td:last-child {
  border-bottom-right-radius: 10px;
}

.genericTableLower>tfoot>tr.orange>td {
  background-color: $tableAddition;
}

.genericTableLower>tfoot>tr.orange:hover>td {
  background-color: $tableAdditionHover;
  transition: 0.3s all ease-in-out;
}

.oneHeadDarkBlue .thead>tr>th:first-child {
  background-color: $darkBlue;
  color: $white;
  padding: 0.5rem 1rem;
}

.treeHeadDarkBlue .thead>tr>th:nth-child(1n + 5) {
  background-color: $darkBlue;
  color: $white;
  padding: 0.5rem 1rem;
}

.firstdClearBlue>tr>td:first-child {
  padding: 0.4rem 0;
  text-transform: lowercase;
  background-color: $ordTableHeadBgColor;
  color: $tableText;
  font-size: 13px;
  line-height: 16.8px;
  font-weight: 500;
  vertical-align: middle;
  border-bottom: 1px solid #9bdee7;
}

.backgroundImage {
  padding-left: 5px;
  border-radius: 5px;
  border: 0px;
  padding: 1rem;
  background-image: url("../../assets/img/icons/ordBlueSearch.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
  // background-color: #f5f7fa;
  height: 28px;
  outline: none;
  color: #6e6f7c;
}

.searchIcon{
  position: absolute;
  right: 216px;
  top: 10px;
  cursor: pointer;
}

.searchIcon2{
  position: absolute;
  right: 2px;
  top: 10px;
  cursor: pointer;
}

.backgroundOutImage {
  padding-left: 5px;
  border-radius: 5px;
  padding-right: 30px;
  border: 0px;
  padding: 1rem;
  background-color: #f5f7fa;
  height: 28px;
  outline: none;
  color: #6e6f7c;
}

.backgroundImage:focus-visible {
  outline: none;
}

.ordCard {
  border: 1px solid #ccd9e6;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px 0px #13131333;
  border-radius: 10px;
  padding: 16px 24px;
  color: #6e6f7c;
}

.ordCardFlex {
  border: 1px solid #ccd9e6;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px 0px #13131333;
  border-radius: 10px;
  padding: 16px 24px;
  color: #6e6f7c;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ordAccordion {
  border-radius: 10px;
  box-shadow: 0px 0px 6px 0px #13131333;
}

.ordBox {
  border: 1px solid #ccd9e6;
  box-sizing: border-box;
  box-shadow: 0px 0px 16px rgba(19, 19, 19, 0.2);
  border-radius: 15px;
  padding: 10px 15px;
  color: #00b4cc;
  height: 120px;
  width: 192px;
  text-align: center;
  align-items: center;
}

.ordBoxImg {
  height: 26px;
  width: 26px;
}

.OrdSlideButton {
  background: #f7feff;
  box-shadow: 0px 0px 6px rgba(19, 19, 19, 0.2);
  border-radius: 10px;
  margin-top: 16px;
  margin-bottom: 20px;
}

.OrdSlideButton2 {
  background: #d6f3f7;
  box-shadow: 0px 0px 6px rgba(19, 19, 19, 0.2);
  border-radius: 10px;
  margin-top: 16px;
  margin-bottom: 20px;
}

.wrapper {
  display: flex;
  flex-direction: row;
}

.wrapperReverse {
  display: flex;
  flex-direction: row-reverse;
}

.itemClass {
  padding: 3px 0px;
  margin: 0 0.3rem;
  width: 25px;
  height: 25px;
  font-family: "PT Sans Caption";
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
}

.itemClass:hover,
.itemClass[class*="active"]:hover {
  background-color: #d6f3f7;
  border-radius: 100%;
}

.itemClass[class*="active"] {
  background-color: #d6f3f7;
  border-radius: 100%;
}

.itemClass a {
  text-decoration: none;
  color: $tableText;
}

.itemClass[class*="active"] a {
  color: $darkBlue;
}

.itemClass[class*="active"].iconClear a {
  color: $clearBlue;
}

.itemClassPrev {
  background-image: url("../../assets/img/icons/tlnPrevPg.svg");
  background-size: 8px;
}

.itemClassNext {
  background-image: url("../../assets/img/icons/tlnNextPg.svg");
  background-size: 8px;
}

.itemClassFirst {
  background-image: url("../../assets/img/icons/tlnFirstPg.svg");
  background-size: 10px;
}

.itemClassLast {
  background-image: url("../../assets/img/icons/tlnLastPg.svg");
  background-size: 10px;
}

.itemClassPrev.iconClear {
  background-image: url("../../assets/img/icons/backPlayClear.svg");
}

.itemClassNext.iconClear {
  background-image: url("../../assets/img/icons/playClear.svg");
}

.itemClassFirst.iconClear {
  background-image: url("../../assets/img/icons/primeroClear.svg");
}

.itemClassLast.iconClear {
  background-image: url("../../assets/img/icons/ultimoClear.svg");
}

.filterClearBlue {
  filter: brightness(0) saturate(100%) invert(43%) sepia(100%) saturate(1319%) hue-rotate(171deg) brightness(95%) contrast(104%);
}

.itemClassPrev:not(.disabled):hover,
.itemClassNext:not(.disabled):hover,
.itemClassFirst:not(.disabled):hover,
.itemClassLast:not(.disabled):hover {
  opacity: 0.5;
}

.itemClassPrev a,
.itemClassNext a,
.itemClassFirst a,
.itemClassLast a {
  color: transparent;
}

.paginationText {
  font-size: 13px;
  display: inline-flex;
  flex: 1;
  color: $darkGray;
}

.activeClass {
  background-color: $paginationSelected;
  color: $darkGray;
  font-size: 14px;
}

.ordBlueHoverText:hover {
  // color: $ordAquaMarine;
  color: red;
}

.ordBlueHoverText:hover {
  // color: $ordAquaMarine;
  color: red;
  background-color: red;
}

.shade {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

}

.titleAccordion {
  color: $ordTableTextColor;
  font-weight: bold;
}

.bgTabOrderReplaceRowSelected {
  background-color: #fcf1e1 !important;
}

.borderTable{
  box-shadow: 0px 0px 1px !important;
  border-radius: 10px !important;
}

.borderTable>tbody>tr:last-child>td {
  border-bottom: 0px !important;
}

.theadDark2>tbody>tr>td {
  border-bottom: 1px solid #005dbf21 !important;
}