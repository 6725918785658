@import "../../styleConstants.scss";
.botn {
  border-radius: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  width: 200px;
  height: 130px;
  filter: grayscale(100%);
}

.botn:hover {
  filter: grayscale(0%);
  background-color: #005cbf0e;
}

.botn > div > p {
  margin-top: 5px;
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
}

.bottonn {
  width: 75%;
  height: 130px;
  //   filter: grayscale(100%);
  border: none;
  margin: auto;
  cursor: pointer;
}

.hovimg {
  margin-bottom: 10px;
}

.bottonn:hover {
  .borhov {
    border: 1px solid #005dbf;
    background-color: #005cbf0e;
  }

  filter: grayscale(0%);
}

.borhov {
  border: solid 1px #cecece;
  border-radius: 10px;
  margin: auto;
}

.borhov > p {
  margin-top: 5px;
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  width: 110px;
  margin: auto;
}

.text-radi {
  margin-top: 5px;
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  margin: auto 10px;
  color: #58595b;
}

.bg-card {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  width: 690px;
  background: #f2f7fc;
  border-radius: 10px;
}
.buttonCreatCount {
  flex-direction: row;
  float: right;
  width: 185px;
  background-color: #f5f7fa;
  height: 30px;
  display: inline-flex;
  border-radius: 5px;
  border-color: $darkBlue;
  border: 1px solid;
  color: $darkBlue;
  justify-content: space-between;
  padding: 2px 10px 2px 10px;
  width: 23.4%;
}

// table {
//   font-family: "PT PT Sans Caption", "Helvetica Neue";
// }

// thead {
//   border-radius: 10px 10px 0 0;
// }

// // tocó usar important
// .headers {
//   background-color: $clearBlue !important;
//   color: $white !important;
//   font-weight: bold !important;
//   font-size: 15px !important;
//   padding: 0 !important;
// }

.stickyValues1 {
  color: $darkGray;
  padding-left: 1rem;
  bottom: 28.5%;
  left: 0;
  position: sticky;
  z-index: 2;
  background-color: $white;
}
.stickyValues2 {
  color: $darkGray;
  padding-left: 1rem;
  bottom: calc(9.5% * 2);
  left: 0;
  position: sticky;
  z-index: 2;
  background-color: $tableEven;
}
.stickyValues3 {
  color: $darkGray;
  padding-left: 1rem;
  bottom: 9.5%;
  left: 0;
  position: sticky;
  z-index: 2;
  background-color: $white;
}
.stickyValues4 {
  color: $darkGray;
  padding-left: 1rem;
  bottom: 0;
  left: 0;
  position: sticky;
  z-index: 2;
  background-color: $tableEven;
}

.stickyValues1 > * {
  text-align: left;
}
.stickyValues2 > * {
  text-align: left;
}
.stickyValues3 > * {
  text-align: left;
}
.stickyValues4 > * {
  text-align: left;
}

.colWhite {
  background-color: $tableEven;
  border: none;
}
// .textareaStyle {
//   width: 95%;
//   height: 4rem;
//   max-height: 5rem;
//   min-height: 2.5rem;
//   border: 1.5px solid $clearBlue;
//   border-radius: 6px;
//   background-color: $white;
// }

.labelFont {
  font-size: 0.8rem;
  color: #005dbf;
  font-family: "PT Sans Caption";
  line-height: 0.971rem;
  font-weight: 400;
}
.labelFontGray {
  font-size: 13px;
  color: #58595b;
  font-family: "PT Sans Caption";
  line-height: 0.971rem;
  font-weight: 400;
}

.backgroundModal{
  background : rgba(0, 93, 191, 0.05);
  border-radius: 10px;
  padding-bottom: 16px;
  padding-left: 15px;
  //margin-right: -20px;
  padding-right: 20px;
}

.paddingButton{
  padding-bottom: 15px;
}

.backgroundModal_new{
  border-radius: 10px;
  padding-bottom: 16px;
}

.ItemTiny_cantidad{
  flex:1.5;
  display: flex;
  flex-direction: column;
  width: 25px;
}