@import "../../styleConstants.scss";

//cosas copiadas de stiven / valentina
.principalScreenBtns{
    display: flex;
    align-self: flex-end;
}
.btnNewHover{
    cursor: pointer;
    filter: grayscale(1);
    align-self: center;
    padding-left: 10px ;
}
.labelNew{
    font-weight: 700;
    font-style : normal;
    font-size: 12px;
    line-height:15.53px;
    margin-bottom: 1.5px;
    align-content: left ;
    color: #58595B;
    padding: 6px 1px 3px 4px;
}

.Search{
    vertical-align: top !important;
    border: rgba(0, 93, 191, 0.1);
    background-color: rgba(0, 93, 191, 0.1);
    border-radius: 6px;
    width: 270px;
}
.inputMargin{
    margin-right:15px;
}
.buttonCreatCount{
    flex-direction: row;
    float: right;
    width: 80px;
    height: 30px;
    display: flex;
    border-radius: 6px;
    justify-content: space-evenly;
}
.buttonCreatCount:hover{
    background-color: #E6ECF2;
}
.buttonCreatCount:hover>p{
    color: #005DBF;
}

.buttonCreatCount>img{
    height: 20px;
    top: 3px;
}
.buttonCreatCount:hover>img{
    filter: none;
}
.buttonCreatCount>p{
    size: 76px;
    line-height:16px;
    margin-bottom: 0;
    color: #58595B;
    cursor: pointer;
    font-Weight:700;
}


//new modal update
.upperzone{
    display: flex;
    flex-direction: column;
    background: $tableEven;
    border-radius: 10px;
    padding: 10px 10px;
    margin: 0 -10px;
}

.Item{
    display: flex;
    flex-direction: column;
}
.iconSave{
    width: 27px;
    height: 27px;
}
.mr{
    margin-right: 16px;
}
.f1{
    flex:1;
}
.f3{
    flex:3;
}
.f4{
    flex:4;
}
.f6{
    flex:6;
}
.label{
    font-family: "PT Sans Caption";
    font-size: 12px;
    line-height: 16px;
    margin: auto 0px auto 5px;
    align-items: center;
    color:$darkBlue;
}
.labelBig{
    font-family: "PT Sans";
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    margin: 2px 0px 2px 6px;
    align-items: center;
    color: $darkBlue;
}
.mycheckbox{
    margin: auto 0;
    padding: 0 !important;
}
.mycheckbox>span{
    color: $clearBlue;
}

.imageWrapper{
    position: relative;
    margin: 0 auto;
}
.imageInput{
    background: #fff;
    flex: 1;
    max-height: 200px;
    max-width: 200px;
    border: 1px solid $clearBlue;
}
.imageInputLabel{
    width: 25px;
    height: 25px;
    border-radius: 100%;
    position: absolute;
    bottom: 1px;
    padding: 4px;
    background: #fff;
    right: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
    display: flex;
}
.imageInputLabel > img{
    flex:1;
    filter: brightness(0) saturate(100%) invert(33%) sepia(6%) saturate(178%) hue-rotate(182deg) brightness(95%) contrast(85%);
}
.imageInputLabel:hover > img{
    filter: none;
}
.imageInputBtn{
    display: none;
}