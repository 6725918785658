@import "../../styleConstants.scss";

.inputSearch {
    border          : 1.5px solid rgba(0, 93, 191, 0.1);
    border-radius   : 5px;
    width           : 100%;
    padding         : 0 .5rem;
    height          : 30px;
    color           : #58595b;
    background-color: rgba(0, 93, 191, 0.1);
}

.inputSearch::placeholder {
    color    : #005dbf;
    font-size: 12px;
    opacity  : 0.7;
}

.bgBtn {
    filter       : grayscale(100%);
    color        : #58595B;
    font-weight  : bold;
    text-align   : center;
    width        : 170px;
    height: 24px;
    border-radius: 10px;
    font-size: 12px;

}

.bgBtn:hover {
    filter          : brightness(0) saturate(100%) invert(26%) sepia(39%) saturate(3961%) hue-rotate(197deg) brightness(93%) contrast(102%);
    color           : #005DBF;
    font-weight     : bold;
    background-color: #005DBF20;

}

.backIcon{
    width: 50px;
    height: 33px;
    margin: auto 0;
    cursor: pointer;
}

.crudModalLabelBlue {
    font-size: 16px;
    margin: 0.4rem 0.3rem 0 0.3rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: $darkBlue;
    font-weight: 700;
  }

.inputsTextArea {
    border: 1.5px solid #01a0f6;
    border-radius: 5px;
    width: 100%;
    padding: 0 0.5rem;
    height: 50px;
}

.profilepic{
    width: 60.7px;
    height: 60px;
    border:1px solid $clearBlue;
    border-radius: 100%;
}

.imageFormat{
   object-fit: cover;
}

.profilepicWrapper{
    position: relative;
    margin: 0 auto;
}

.card{
    background-color: #fff;
    border: 1px solid #CECECE;
    border-radius: 10px;
    padding: 12px 12px 12px 12px;
    margin-top: 16px;
}

.title1{
    font-weight:400;
    font-size:18px;
    color:#58595B;
    line-height: 19,41px;
    font-style: normal;

}

.title2{
    font-weight:700;
    font-size:16px;
    color: #005DBF;
    line-height: 15,53px;
    font-style: normal;

}

.title3{
    font-weight:400;
    font-size:12px;
    color:#58595B;
    line-height: 11,65px;
    font-style: normal;

}

.title4{
    font-weight:400;
    font-size:13px;
    color:#58595B;
    line-height: 12,94px;
    font-style: normal;
}

.title5{
    font-weight:400;
    font-size:16px;
    color: #005DBF;
    line-height: 15,53px;
    font-style: normal;

}

.title6{
    font-weight:400;
    font-size:16px;
    color: #58595B;
    line-height: 15,53px;
    font-style: normal;

}

.title7{
    font-weight:700;
    font-size:19px;
    color: #01A0F6;    ;
    line-height: 19,41px;
    font-style: normal;
}

.borderLeft{
    border-left: 1px solid hsla(200, 10%, 50%, 100);
    // padding-top: 15px;
    // padding-bottom: 15px;
}

