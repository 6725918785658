.cardDoctor {
  border: 1px solid #ccd9e6;
  box-sizing: border-box;
  border-radius: 10px;
}

.app_container_calendar_component {
  width: 100%;
  height: 282px;
  overflow: auto;
  max-width: 336px;
}

.app_container_schedule {
  gap: 0.7rem;
  display: flex;
  margin-bottom: 0.6rem;
}

.app_container_render {
  width: 100%;
  max-width: 250px;
}

.app_container_map {
  margin-bottom: 1rem;
}

.app_title {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #6e6f7c;
  font-family: "PT Sans Caption";
}

.app_title_sytem {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  color: #6e6f7c;
  text-transform: lowercase;
  font-family: "PT Sans Caption";
}

.app_container_hours {
  width: 100%;
  display: flex;
  max-width: 53px;
  cursor: pointer;
  border-radius: 5px;
  align-items: center;
  border-radius: 5px;
  background: #ffffff;
  flex-direction: column;
  padding: 0.3rem 0.8rem;
  justify-content: center;
  border: 1px solid #00b4cc;
}

.app_container_hours_active {
  width: 100%;
  display: flex;
  max-width: 53px;
  cursor: pointer;
  border-radius: 5px;
  align-items: center;
  border-radius: 5px;
  background: #00b4cc;
  flex-direction: column;
  padding: 0.3rem 0.8rem;
  justify-content: center;
}

.hours {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  font-family: "PT Sans Caption";
}

.type {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  text-transform: lowercase;
  font-family: "PT Sans Caption";
}

.app_container_left_hours {
  width: 100%;
  display: flex;
  min-width: 40px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.line {
  border-right: 1px solid black;
}

.app_container_sum {
  height: 48px;
  display: flex;
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
  align-items: center;
  background: #ffffff;
  justify-content: center;
  border: 1px solid #d6e0eb;
}




.app_card_doctor {
  gap: 1rem;
  display: flex;
  padding: 1rem;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 1rem;
  background: #FFFFFF;
  border: 1px solid #CCD9E6;
  box-shadow: 0px 0px 6px rgba(43, 71, 139, 0.15);
}
.app_card_doctor_active {
  gap: 1rem;
  display: flex;
  padding: 1rem;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 1rem;
  background: #00B4CC;
  border: 1px solid #CCD9E6;
  box-shadow: 0px 0px 6px rgba(43, 71, 139, 0.15);
}
.app_container_img {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.container_name_doctor {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 282px;
}
.app_title_card {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #6E6F7C;
  font-family: "PT Sans Caption";
}
.app_site_card {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #6E6F7C;
  font-family: "PT Sans Caption";
}

.app_container_card_component {
  width: 100%;
  height: 282px;
  overflow: auto;

}

  