.accountBoxWrap {
    display: grid;
    grid-template-areas: "a a";
    grid-gap: 20px;
    grid-auto-rows: 100px;
}

.titleContainer {
    display: flex;
    margin: 0px 0px 0px;
    width: 100%;
    margin: auto;
}

.centralContainer {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 3rem;
    padding: 0 60px 0 100px;
}

.container {
    width: 100%;
    /* padding: 0 135px; */
    display: flex;
    flex-direction: column;
}

.title {
    margin-top: 3rem;
    margin-bottom: 11px;
    font-weight: Bold;
    font-size: 40px;
    line-height: 52px;
}

.subtitle {
    font-style: normal;
    font-size: 24px;
    line-height: 31px;
    color: #58595b;
}

.flex1 {
    display: flex;
    padding: 15px;
    border: 1px solid #cecece;
    background-image: url(../../assets/img/icons/nube.svg);
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 15px;
    cursor: pointer;
    height: 100px;
}

.flex2 {
    cursor: pointer;
    text-align: center;
    transition: all 0.3s;
}

.flex2:hover {
    background: rgba(0, 93, 191, 0.05);
}

.flex1:hover {
    /*background-color: rgba(2, 55, 177, 0.1);*/
    background-color: #f5f7fa;
}

.classIcon {
    width: 40px;
    height: 40px;
}

.classTitle {
    flex: 2;
    margin: 0 auto;
    font-family: "PT Sans";
    color: #58595b;
    font-weight: Bold;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
}

/* .rols{
    position: absolute;
    top: 100%;
    left: 0;
}
.rols1{
    transform: translate(0, 30%);
}
.rols2{
    transform: translate(-20%, 30%);
}
.rols3{
    transform: translate(-34%, 30%);
} */
.containerInter {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 0px;
    position: absolute;
    width: 210px;
    overflow: overlay;
    max-height: 341px;
}


.containerChildren {
    cursor: pointer;
    text-align: center;
    border-bottom: 1px solid #ccc;
    padding: 5px 10px;
    width: 100%;
    color: #58595b;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
}

.containerInter > :last-child{
    border-bottom: 1px solid #fff;
    /* background-color: red; */
}