@import "../../styleConstants.scss";


.btn{
    cursor: pointer;
    filter: brightness(0) saturate(100%) invert(38%) sepia(0%) saturate(1608%) hue-rotate(302deg) brightness(87%) contrast(89%);
}

.btn:hover{
    filter: brightness(0) saturate(100%) invert(63%) sepia(85%) saturate(5144%) hue-rotate(175deg) brightness(100%) contrast(101%);
}