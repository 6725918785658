@import "../../styleConstants.scss";

.bigContainer{
    margin-left: 50px;
}

.sectionContainer{
    margin-top:38px;
}
.toggleAll{
    transition: transform 0.3s; 
}
.backIcon{
    width: 50px;
    height: 33px;
    margin: auto 0;
    cursor: pointer;
}
.marginR{
    margin-right: 12px;
   
}

.line{
    height: 1px;
    width: 16px;
    border-color: #CECECE;
    background-color: #CECECE;
    //align-self: center;
}
.alingLine{
    align-self: center;
}

.marginLeft{
    margin-left: 10px;
}

.filter{
    filter: invert(37%) sepia(98%) saturate(880%) hue-rotate(116deg) brightness(95%) contrast(100%);
}

.textPartFinal{
    margin: auto 0 0 10px;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    color: #58595B;

}

.inputsTextArea {
    border: 1.5px solid #01a0f6;
    border-radius: 5px;
    width: 100%;
    padding: 0 0.5rem;
    height: 50px;
}

.ItemSmall {
    flex: 2;
    display: flex;
    flex-direction: column;
}

.rotate{
    transition: transform 0.3s;
    transform: rotate(180deg)
}

.titleBlock{
    margin-top: 50px;
}

.mainTitle{
    font-family: PT Sans Caption;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 39px;
    margin: 0;
}
.marginTable{
    margin-bottom: 0rem !important;
}
.adjustIconPart2{
    height: 16px;
    width: 16px;
}
.textPart2{
    margin: auto 0 0 10px;
    color:$darkBlue;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
}

.textTotal{
    margin: auto;
    color:rgba(88, 89, 91, 1);
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
}



.textPart2Orange{
    margin: auto 0 0 2px;
    color:$orange;
}

.textJustify{
    cursor: pointer;
    color: $orange;
    text-decoration: underline;
    font-weight:700 ;
 
}

.marginTableCol{
    margin-top: 10px;
}
.marginSection{
    margin-top: 18.5px;
}

.marginSection2{
    margin-top: 6.5px;
    margin-bottom: 3px;
}
.marginSection3{
    margin: 17px 0px 28px;
}

.crudModalTopLabel{
    font-size: .75rem;
    margin: .4rem .3rem 0 .3rem;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
    color: #58595B;
}
.ItemNormal{
    display: flex;
    flex-direction: column;
}

.underMainTitle{
    font-family: PT Sans Caption;
    font-size: 15px;
    line-height: 19px;
}

.blueText{
    color:$darkBlue;
}

.greenText{
    color: $greenEnabled;
}

.yellowText{
    color: $yellowAux;
}

.redText{
    color: $red;
}

.sectionTitle{
    position: relative;
    font-family: "PT Sans";
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: $darkBlue;
    margin: 15px auto 0 12px;

}

.articleControlIcon{
    position: relative;
    cursor: pointer;
    border-radius: 30px;
}
.articleControlIconNew{
    position: relative;
    cursor: pointer;
    border-radius: 30px;
}

.articleControlIconNew.expand,
.articleControlIconNew:hover{
    background: rgba(88, 89, 91, 0.15);
}
.articleControlIcon.expand,
.articleControlIcon:hover{
    background: rgba(0, 93, 191, 0.1);
}
.articleControlIconNew:hover > img{
    filter: brightness(0) saturate(100%) invert(20%) sepia(94%) saturate(1860%) hue-rotate(196deg) brightness(107%) contrast(105%);
}
.articleControlIcon:hover > img{
    filter: brightness(0) saturate(100%) invert(20%) sepia(94%) saturate(1860%) hue-rotate(196deg) brightness(107%) contrast(105%);
}

.articleSearch{
    transition: transform 0.3s; 
    width: 30px;
    background: transparent;
    border: none;
    margin: 0;    
    font-size: 12px;
    line-height: 16px;
    color: $darkerGray;
    background-image: url("../../assets/img/icons/auctionDetailLupa.svg");
    background-repeat: no-repeat;
    background-position: right
}
.articleSearch.expand,
.articleSearch:focus{
    transition: transform 0.3s; 
    width: 200px;
    margin: 2px 10px;
}
.articleSearchIcon{
    position: absolute;
    right: 0;
    top: 0;
}

.articleSearchIcon:hover {
    transition: transform 0.3s;
    width: 30px;
    background: transparent;
    border: none;
    margin: 0;    
    font-size: 12px;
    line-height: 16px;
    color: $darkerGray;
    background-image: url("../../assets/img/icons/LupaGris.svg");
    background-repeat: no-repeat;
    background-position: right
    
}


.marginT{
    margin-top:0.2em !important;
}
.articleControlIcon:hover >img{
    filter: brightness(0) saturate(100%) invert(20%) sepia(94%) saturate(1860%) hue-rotate(196deg) brightness(107%) contrast(105%);
}

.namesColum{
    font-family: "PT Sans"; 
    font-style: "normal";
    font-size: 12px;
    line-height: 16px;
    color: #58595B;
}

.sectionTitle::before{
    position: absolute;
    width: 5px;
    height: 5px;
    top: 7.1px;
    left: -10px;
    content: "";
    background: $darkBlue;
    border-radius: 50%;
}

.titleIcons{
    width: 25px;
    height: 25px;
    margin-left: 8px;
}
.titleIcons:hover{
    filter: brightness(0) saturate(100%) invert(20%) sepia(94%) saturate(1860%) hue-rotate(196deg) brightness(107%) contrast(105%);
}

.tableGearIcon{
    width: 20px;
    height: 20px;
}
.aling{
    text-align: right;
    margin-right: 12px;
}
.flex3{
    flex: 3;
}
.center{
    text-align: center;
}
.mbImport{
    margin-bottom: 0px !important;
    padding: 0px !important;
}
.flex1{
    flex: 1;
}
.flex2{
    flex: 2;
}
.inputMargin{
    margin-right:15px
}
.Item{
    display: flex;
    flex-direction: column;
}
.registerInputs{
    border: 1.5px solid $darkBlue;;
    border-radius: 5px;
    background-color: #F5F7FA;
    width: 100%;
    padding: 0 .5rem;
    height: 27px;
    color: #58595B;
}

.registerInputsClear{
    border: 1.5px solid $clearBlue;;
    border-radius: 5px;
    background-color: #F5F7FA;
    width: 100%;
    padding: 0 .5rem;
    height: 27px;
    color:$darkBlue;
}



.textArea{
    height: 32px;
    border: 1.5px solid $darkBlue;
    border-radius: 6px;
    background-color: #F5F7FA;
}

.bottomRow{
    margin: 68px 0;
    display: inline-flex;
    flex-direction: row-reverse;
    width: 100%;
    vertical-align: middle;
    padding: 0 .1rem;
}

.label_inputs_new3{

    color:#58595B;
    font-weight: 700;
    font-style : normal;
    font-size: 13px;
    line-height:15.53px;
}
.checkStyle{
    flex: 1;
    margin-right: 5px;
}

.bottomRow> Button{
    margin-left: 12px;
} 
.crudModalInputDate{
    font-size: 1vw;
}
.crudModalLabel{
    font-size: .75rem;
    margin: .4rem .3rem 0 .3rem;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
    color: $darkBlue;
}
.registerInputsBlue{
    border: 1.5px solid $clearBlue;
    border-radius: 5px;
    width: 100%;
    padding: 0 .5rem;
    height: 27px;
    color: #005DBF;
    font-weight: 700;
}

.aling_text{
    text-align: -webkit-center}

//TAMAÑO DE LOS INPUTS 

.f0{
    flex:0.7;
}
.f1{
    flex:1;
}
.f15{
    flex:1.5;
}
.f2{
    flex:2;
}
.f3{
    flex: 3;
}

//ANGIE
.container2{
    //flex:2;
    //margin-top:15px;
    display: flex;
    flex-direction: column;
}

///// MODAL RESUME PURCHASE ORDER

.delivery {
    color: #58595B;
    line-height: 17px;
    font-size: 12px;
    display: inline;
}

.myCheckboxInput{
    width: 16px;
    height: 16px;
    border-radius: 4px;
    vertical-align: middle;
    border-color: #005DBF;
}

.myCheckbox{
    margin:0 1rem;
}

.myCheckboxLabel{
    margin-left: .5rem;
    text-transform:capitalize;
    vertical-align: middle;
}

.ItemCheckbox {
    display: flex;
    margin-top: 23px;
    //flex-direction: column-reverse;
}
  
.labelFont{
    font-size: 0.8rem;
    color: #005DBF;
    font-family: "PT Sans Caption";
    line-height: 0.971rem;
    font-weight: 400;
}

.observationsArea{
    max-height: 50px;
    width: 539px;
    border:1px solid $darkBlue;
    border-radius: 6px;
    background-color: #FFFFFF;
}

.sizeObservations{
    width: 575px;
    height: 72px ;
}

.containerObservations{
    display: flex; 
    margin-top:-90px;
}

.textFooter{
    font-family: "PT Sans Caption";
    font-style: normal;
    font-size: 13px;
    line-height: 17px;
    color: #58595B;
}

.observationsColor{
    background-color: #F5F7FA ;
}

.marginLeftObservations{
    margin-left: 16px;
}

.loading{
    z-index: 1059;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    margin-top: auto;  
    top: 40%;
    text-align: center;
  }
.crudModalLabelGrey{
    font-size: .75rem;
    margin: .4rem .3rem 0 .3rem;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
    color: $darkerGray;
}
.marginTop{
    margin-top: 8px;
}
.containerNew2{

    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

}
