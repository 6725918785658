@import "../../../styleConstants";

.container {
  margin-left: 60px;
  margin-right: 150px;
  width: 100%;
}

.filtersGrid {
  display: grid;
  font-size: 14px;
  color: $darkBlue;
  column-gap: 20px;
  padding: 10px 0px;
  align-items: end;
  grid-template-columns: 1fr 1fr 2fr 2fr;
}

.inputSearch {
  width: 100%;
  border: rgba(0, 93, 191, 0.1);
  background-color: rgba(0, 93, 191, 0.1);
  border-radius: 5px;
  width: 100%;
  padding: 0 0.5rem;
  height: 32px;
  color: $darkGray;
  font-size: 12px;
  outline: none;
}

.boxInputSearch {
  display: flex;
  column-gap: 15px;

  img {
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }
}

.btnPrimaryFilters {
  font-weight: 700;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #666;
  padding: 7px 10px;
  cursor: pointer;
  border-radius: 4px;
  user-select: none;
  transition: all 0.4s;
  padding: 7px 10px;

  &:hover {
    color: $darkBlue;
    background-color: #005dbf15;
  }
}

.btnPrimaryModal {
  font-weight: 700;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #666;
  padding: 5px 0px;
  cursor: pointer;
  border-radius: 4px;
  user-select: none;
  transition: all 0.4s;

  &:hover {
    color: $clearBlue
  }
}

.modalHeader {
  padding: 15px 0px !important;
  color: $clearBlue;
}

.btnCancel {
  background: white;
  border: 1px solid $clearBlue;
  color: $clearBlue;
  font-weight: bold;
  font-family: "PT Sans";

  &:hover {
    background-color: white;
    color: $clearBlue;
    border: 1px solid $clearBlue;
  }
}

.btnPrimary {
  background: $clearBlue;
  border: 1px solid $clearBlue;
  font-weight: bold;
  font-family: "PT Sans";

  &:hover {
    border: 1px solid $clearBlue;
    opacity: 0.8;
  }
}

.input {
  padding: 2px 5px;
  border-radius: 5px;
  outline: none;
  color: #666;
  border: 1px solid #A3BAD1;
  font-size: 13px;
  height: 32px;

  &:focus{
    border: 1px solid $darkBlue;
  }

  &::placeholder {
    color:#868686ec;
    font-size: 13px;
  }
}

.cardStatus {
  width: 200px;
  margin: auto;
  padding: 7px 10px;
  font-weight: bold;
  border-radius: 10px;
  color: rgb(0, 165, 81);
  background-color: rgb(230, 246, 238);
}

.asterisk{
  color: #e23f3f;
  margin-left: -4px;
}