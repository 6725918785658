@import "../../styleConstants.scss";
@import "../../index.scss";


.inputSearch {
    border          : 1.5px solid rgba(0, 93, 191, 0.1);
    border-radius   : 5px;
    width           : 100%;
    padding         : 0 .5rem;
    height          : 30px;
    color           : #58595b;
    background-color: rgba(0, 93, 191, 0.1);
}

.inputSearch::placeholder {
    color    : #005dbf;
    font-size: 12px;
    opacity  : 0.7;
}


.bgBtn {
    filter       : grayscale(100%);
    color        : #58595B;
    font-weight  : bold;
    text-align   : center;
    width        : 170px;
    border-radius: 20px;
    height: 24px;
    padding: 4px 7px;
}

.bgBtn:hover {
    filter          : brightness(0) saturate(100%) invert(26%) sepia(39%) saturate(3961%) hue-rotate(197deg) brightness(93%) contrast(102%);
    color           : #005DBF;
    font-weight     : bold;
    background-color: rgba(0, 93, 191, 0.05);
    padding: 4px 7px;


}