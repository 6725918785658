.editor {
    user-select: text;
    white-space: pre-wrap;
    word-break: break-word;
    padding: 5px;
    border-radius: 3px;
    min-height: 150px;

    &:not(&:read-only) {
        outline: solid 2px rgba($color: #005dbf, $alpha: 0.2);
    }
}

.ltr {
    text-align: left;
}

.rtl {
    text-align: right;
}

.editor-container {
    margin: 20px auto 20px auto;
    border-radius: 2px;
    max-width: 600px;
    color: #000;
    position: relative;
    line-height: 20px;
    font-weight: 400;
    text-align: left;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.editor-inner {
    background: #fff;
    position: relative;
}

.editor-input {
    resize: none;
    font-size: 15px;
    position: relative;
    tab-size: 1;
    outline: 0;
    padding: 15px 10px;
    caret-color: #444;
}

.editor-placeholder {
    color: #999;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 50px;
    left: 5px;
    font-size: 15px;
    user-select: none;
    display: inline-block;
    pointer-events: none;
}

.editor-text-bold {
    font-weight: bold;
}

.editor-text-italic {
    font-style: italic;
}

.editor-text-underline {
    text-decoration: underline;
}

.editor-text-strikethrough {
    text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
    text-decoration: underline line-through;
}

.editor-link {
    color: rgb(33, 111, 219);
    text-decoration: none;
}

.tree-view-output {
    display: block;
    background: #222;
    color: #fff;
    padding: 5px;
    font-size: 12px;
    white-space: pre-wrap;
    margin: 1px auto 10px auto;
    max-height: 250px;
    position: relative;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: auto;
    line-height: 14px;
}

.editor-paragraph {
    margin: 0;
    margin-bottom: 8px;
    position: relative;
    font-size: 12px;
}

.editor-paragraph:last-child {
    margin-bottom: 0;
}

.editor-heading-h1 {
    font-size: 24px;
    color: rgb(5, 5, 5);
    font-weight: 400;
    margin: 0;
    margin-bottom: 12px;
    padding: 0;
}

.editor-heading-h2 {
    font-size: 15px;
    color: rgb(101, 103, 107);
    font-weight: 700;
    margin: 0;
    margin-top: 10px;
    padding: 0;
    text-transform: uppercase;
}

.editor-quote {
    margin: 0;
    margin-left: 20px;
    font-size: 15px;
    color: rgb(101, 103, 107);
    border-left-color: rgb(206, 208, 212);
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 16px;
}

.editor-list-ol {
    padding: 0;
    margin: 0;
    margin-left: 16px;
}

.editor-list-ul {
    padding: 0;
    margin: 0;
    margin-left: 16px;
}

.editor-listitem {
    margin: 8px 32px 8px 32px;
}

.editor-nested-listitem {
    list-style-type: none;
}

pre::-webkit-scrollbar {
    background: transparent;
    width: 10px;
}

pre::-webkit-scrollbar-thumb {
    background: #999;
}

.debug-timetravel-panel {
    overflow: hidden;
    padding: 0 0 10px 0;
    margin: auto;
    display: flex;
}

.debug-timetravel-panel-slider {
    padding: 0;
    flex: 8;
}

.debug-timetravel-panel-button {
    padding: 0;
    border: 0;
    background: none;
    flex: 1;
    color: #fff;
    font-size: 12px;
}

.debug-timetravel-panel-button:hover {
    text-decoration: underline;
}

.debug-timetravel-button {
    border: 0;
    padding: 0;
    font-size: 12px;
    top: 10px;
    right: 15px;
    position: absolute;
    background: none;
    color: #fff;
}

.debug-timetravel-button:hover {
    text-decoration: underline;
}

.emoji {
    color: transparent;
    background-size: 16px 16px;
    background-position: center;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin: 0 -1px;
}

.emoji-inner {
    padding: 0 0.15em;
}

.emoji-inner::selection {
    color: transparent;
    background-color: rgba(150, 150, 150, 0.4);
}

.emoji-inner::moz-selection {
    color: transparent;
    background-color: rgba(150, 150, 150, 0.4);
}

.toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1px;
    background: #fff;
    padding: 4px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    vertical-align: middle;
}

.toolbar button.toolbar-item {
    border: 0;
    display: flex;
    background: none;
    border-radius: 10px;
    padding: 8px;
    cursor: pointer;
    vertical-align: middle;
}

.toolbar button.toolbar-item:disabled {
    cursor: not-allowed;
}

.toolbar button.toolbar-item.spaced {
    margin-right: 2px;
}

.toolbar button.toolbar-item i.format {
    background-size: contain;
    display: inline-block;
    height: 18px;
    width: 18px;
    margin-top: 2px;
    vertical-align: -0.25em;
    display: flex;
    opacity: 0.6;
}

.toolbar button.toolbar-item:disabled i.format {
    opacity: 0.2;
}

.toolbar button.toolbar-item.active {
    background-color: #eee;
}

.toolbar button.toolbar-item.active i {
    opacity: 1;
}

.toolbar .toolbar-item:hover:not([disabled]) {
    background-color: #eee;
}

.toolbar .divider {
    width: 1px;
    height: 30px;
    background-color: #eee;
    margin: 0 4px;
}

.toolbar select.toolbar-item {
    border: 0;
    display: flex;
    background: none;
    border-radius: 10px;
    padding: 8px;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 70px;
    font-size: 14px;
    color: #777;
    text-overflow: ellipsis;
}

.toolbar select.code-language {
    text-transform: capitalize;
    width: 130px;
}

.toolbar .toolbar-item .text {
    display: flex;
    line-height: 20px;
    width: 200px;
    vertical-align: middle;
    font-size: 14px;
    color: #777;
    text-overflow: ellipsis;
    width: 70px;
    overflow: hidden;
    height: 20px;
    text-align: left;
}

.toolbar .toolbar-item .icon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 8px;
    line-height: 16px;
    background-size: contain;
}

.toolbar i.chevron-down {
    margin-top: 3px;
    width: 16px;
    height: 16px;
    display: flex;
    user-select: none;
}

.toolbar i.chevron-down.inside {
    width: 16px;
    height: 16px;
    display: flex;
    margin-left: -25px;
    margin-top: 11px;
    margin-right: 10px;
    pointer-events: none;
}

i.bold {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-bold' width='24' height='24' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M7 5h6a3.5 3.5 0 0 1 0 7h-6z' /%3E%3Cpath d='M13 12h1a3.5 3.5 0 0 1 0 7h-7v-7' /%3E%3C/svg%3E");
}

i.italic {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-italic' width='24' height='24' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M11 5l6 0' /%3E%3Cpath d='M7 19l6 0' /%3E%3Cpath d='M14 5l-4 14' /%3E%3C/svg%3E");
}

i.underline {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-underline' width='24' height='24' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M7 5v5a5 5 0 0 0 10 0v-5' /%3E%3Cpath d='M5 19h14' /%3E%3C/svg%3E");
}

i.strikethrough {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-strikethrough' width='24' height='24' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M5 12l14 0' /%3E%3Cpath d='M16 6.5a4 2 0 0 0 -4 -1.5h-1a3.5 3.5 0 0 0 0 7h2a3.5 3.5 0 0 1 0 7h-1.5a4 2 0 0 1 -4 -1.5' /%3E%3C/svg%3E");
}

i.left-align {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-align-left' width='24' height='24' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M4 6l16 0' /%3E%3Cpath d='M4 12l10 0' /%3E%3Cpath d='M4 18l14 0' /%3E%3C/svg%3E");
}

i.center-align {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-align-center' width='24' height='24' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M4 6l16 0' /%3E%3Cpath d='M8 12l8 0' /%3E%3Cpath d='M6 18l12 0' /%3E%3C/svg%3E");
}

i.right-align {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-align-right' width='24' height='24' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M4 6l16 0' /%3E%3Cpath d='M10 12l10 0' /%3E%3Cpath d='M6 18l14 0' /%3E%3C/svg%3E");
}

i.justify-align {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-align-justified' width='24' height='24' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M4 6l16 0' /%3E%3Cpath d='M4 12l16 0' /%3E%3Cpath d='M4 18l12 0' /%3E%3C/svg%3E");
}

i.indent {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-indent-increase' width='24' height='24' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M20 6l-11 0' /%3E%3Cpath d='M20 12l-7 0' /%3E%3Cpath d='M20 18l-11 0' /%3E%3Cpath d='M4 8l4 4l-4 4' /%3E%3C/svg%3E");
}

i.outdent {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-indent-decrease' width='24' height='24' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M20 6l-7 0' /%3E%3Cpath d='M20 12l-9 0' /%3E%3Cpath d='M20 18l-7 0' /%3E%3Cpath d='M8 8l-4 4l4 4' /%3E%3C/svg%3E");
}

i.ordered {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-list-numbers' width='24' height='24' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M11 6h9' /%3E%3Cpath d='M11 12h9' /%3E%3Cpath d='M12 18h8' /%3E%3Cpath d='M4 16a2 2 0 1 1 4 0c0 .591 -.5 1 -1 1.5l-3 2.5h4' /%3E%3Cpath d='M6 10v-6l-2 2' /%3E%3C/svg%3E");
}

i.unordered {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-list' width='24' height='24' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M9 6l11 0' /%3E%3Cpath d='M9 12l11 0' /%3E%3Cpath d='M9 18l11 0' /%3E%3Cpath d='M5 6l0 .01' /%3E%3Cpath d='M5 12l0 .01' /%3E%3Cpath d='M5 18l0 .01' /%3E%3C/svg%3E");
}

.dropdown-variable {
    padding: 4px 8px;
    margin: auto 0px;
    color: #777;
    border-radius: 5px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    &:hover {
        background-color: #eee;
    }
}

.dropdown-variable-item {
    color: #6c757d;

    &:active,
    &.active {
        color: white !important;
        background-color: #005dbf !important;
    }
}