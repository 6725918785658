@import "../../../styleConstants.scss";

@font-face {
  font-family: "PT Sans Caption";
  src: url("../../../assets/fonts/PT_Sans_Caption/PTSansCaption-Regular.ttf") format('truetype');
}

@font-face {
  font-family: "PT Sans Caption";
  src: url("../../../assets/fonts/PT_Sans_Caption/PTSansCaption-Regular.ttf") format('truetype');
}
.mainTable {
    display: flex;
    max-height: 20rem;
    overflow: auto;
    // overflow-y: auto;
    // max-height: calc(100% - 37.46px)
    scrollbar-width: thin; /* Grosor del scroll */
  scrollbar-color: #ccc transparent; /* Color del scroll */
  }

  .mainTable>div::-webkit-scrollbar{
    width: 6px;
    height: 6px;
  }
  .mainTable>div::-webkit-scrollbar:vertical{
    padding-top: 45px;
  }
  
  .tableScrollK {
    border-spacing: 0;
    font-family: "PT Sans Caption";
  }
  
  .tableScrollK thead th {
    text-align: left;
    color: $white;
    background-color: $darkBlue;
    @extend  .fixed;
    @extend .fixedTop
  }

  .tableScrollK>thead> tr>th:first-child {
    border-top-left-radius: 10px;
}

.tableScrollK>thead>tr>th:last-child {
    border-top-right-radius: 10px;
}


  .tableScrollK thead th:first-child {
    text-align: left;
    width: 400px;
    @extend  .fixed;
    @extend .fixedTop;
    @extend .fixedLeft
  }

  .tableScrollK th,
  .tableScrollK td {

    // border-bottom: 1px solid #c6c6c6;
    // height: 5rem;
    white-space: nowrap;
    padding-left: 2rem;
  }
  .tableScrollK tbody{
    max-height: calc(100% - 37.46px);
    overflow-y: auto;
    
  }
  .tableScrollK tbody
  td:first-child {
    // border-bottom: 1px solid #c6c6c6;
    // height: 5rem;
    white-space: nowrap;
    padding-left: 2rem;
    @extend .fixed;
    @extend .fixedLeft;
  }
  
  .fixed {
    // background-color: white;
    position: sticky;
  }
  
  .fixedTop {
    // box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.2);
    top: 0;
    z-index: 1;
  }
  
  .fixedLeft {
    // border-right: 1px solid #c6c6c6;
    // box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.2);
    padding-right: 1rem;
    left: 0;
    z-index: 1;
  }
  
  .fixedTop.fixedLeft {
    // box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.2),
    //   -5px 0px 5px 0px rgba(0, 0, 0, 0.2);
    z-index: 2;
  }
  

  .tableScrollK>tbody>tr>td {
    border: none;
    padding: .4rem 0;
    text-transform: lowercase;
    background-color: $white;
    color: $tableText;
    font-size: 13px;
    line-height: 16.8px;
    font-weight: 500;
    vertical-align: middle;
}

.tableScrollK>tbody>tr>td::first-letter {
    text-transform: capitalize;
}

.tableScrollK>tbody>tr:nth-of-type(even)>td {
    background-color: $tableEven;
}

.tableScrollK>tbody>tr:hover>td {
    background-color: $tableHover;
    transition: 0.3s all ease-in-out;
}

.tableScrollK>tbody>tr:last-child>td:first-child {
    border-bottom-left-radius: 10px;
}

.tableScrollK>tbody>tr:last-child>td:last-child {
    border-bottom-right-radius: 10px;
}


// .thead>tr>th {
//     font-size: 15px;
//     line-height: 19.4px;
//     padding: .5rem 0;
// }