@import "../../styleConstants.scss";

.disabled{
    opacity: 0.7;
}

.popupContent{
    // border: 1px solid $darkBlue;
}

.popUpUlAlt,
.popUpUl{
    margin: 0  ;
    list-style: none;
    padding-left: 0 ;    
}
.popUpUl li:hover{
    background-color:#005DBF;
    color:#fff;
}

.popUpUlAlt li{
    padding-left:16px;
}
.popUpUlAlt li::before{
    filter: brightness(0) saturate(100%) invert(22%) sepia(68%) saturate(3623%) hue-rotate(344deg) brightness(98%) contrast(89%);
}
.popUpUlAlt li:hover{
    background-color:$red;
    color:#fff;
}
.popUpUlAlt li:hover:before{
    filter: brightness(100) saturate(100%);
}

.popUpGeneric,
.popUpLiAdd,
.popUpLiCheck,
.popUpLiPurchase,
.popUpLiDeal,
.popUpLiEdit,
.popUpLiActive, 
.popUpLiActiveYellow,
.popUpLiUpdate,
.popUpShowDetails,
.popUpLiDelete,
.popUpLiActiveRed,
.popUpLiDataSheet,
.popUpRegister,
.popUpLiOtroSi,
.popUpLiEmployeeRe,
.showIRadDig,
.popUpSign,
.popUpClone,
.popUpLiLegalization,
.popUpLiUndo,
.popUpLiSign,
.popUpLiOtroSi,
.sendToAccountTextStyles{
    padding-left: 1.5em;
    padding-right: 0.5em;
    text-indent: -1em;
    margin-top: .3rem;
    margin-bottom: .3rem;
    font-size: .8rem;
    cursor: pointer;
    white-space: nowrap;
}

// .popUpLiEdit:hover,
// .popUpLiUpdate:hover,
// .popUpShowDetails:hover,
// .popUpLiActive:hover,
// .popUpLiDelete:hover,
// .popUpLiActiveRed:hover{
//     background-color:#005DBF;
//     color:#fff;    
// }

.popUpGeneric:hover > img,
.popUpLiAdd:hover::before,
.popUpLiCheck:hover::before,
.popUpLiPurchase:hover::before,
.popUpLiDeal:hover::before,
.popUpLiEdit:hover::before,
.popUpLiUpdate:hover::before,
.popUpShowDetails:hover::before,
.popUpLiActive:hover::before,
.popUpLiDelete:hover::before,
.popUpLiActiveRed:hover::before,
.showIRadDig:hover::before,
.popUpSign:hover::before,
.popUpLiEmployeeRe:hover::before,
.popUpClone:hover::before,
.popUpRegister:hover::before,
.popUpLiLegalization:hover::before,
.popUpLiDataSheet:hover::before,
.popUpLiUndo:hover::before,
.popUpLiSign:hover::before,
.popUpLiOtroSi:hover::before{
    filter:brightness(0) saturate(100%) invert(100%);
}

// .popUpLiEdit:hover,
// .popUpLiActive:hover, 
// .popUpLiActiveRed:hover{
// } 

.popUpLiActive:before,
.popUpLiActiveRed:before,
.popUpLiActiveYellow:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: .6rem;
    height: .6rem;
    margin-right: 5px;
}

.popUpLiActive:before {
    background: $greenEnabled;
    }

.popUpLiActiveRed:before {
    background: $redDisabled;
    }

.popUpLiActiveYellow:before {
    background: $yellowAux;
}

.popUpGeneric>img,
.popUpLiAdd:before,
.popUpLiDelete:before ,
.popUpLiPurchase:before,
.popUpLiDeal:before,
.popUpLiCheck:before,
.popUpLiDataSheet:before,
.popUpLiLegalization::before,
.popUpLiEdit:before {
    content: "";
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    width: .6rem;
    height: .6rem;
    margin-right: 5px;
}

.popUpLiEdit:before {
    background-image: url("../../assets/img/icons/editar.svg");
}
.popUpLiLegalization::before {
    background-image: url("../../assets/img/icons/legalization.svg");
}

.popUpLiDataSheet:before{
    background-image: url("../../assets/img/icons/subir.svg");
}
.popUpLiPurchase:before{
    background-image: url("../../assets/img/icons/iconShopCart.svg");
}
.popUpLiDeal:before{
    background-image: url("../../assets/img/icons/iconHandshake.svg");
}
.popUpLiCheck:before{
    background-image: url("../../assets/img/icons/GestionCheck.svg");
}


.popUpShowDetails:before ,
.popUpLiUpdate:before {
    content: "";
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    width: .7rem;
    height: .65rem;
    margin-right: 5px;
}
.popUpLiUpdate:before {
    background-image: url("../../assets/img/icons/Update.svg");
}
.popUpLiUpdate:hover{
    background-color:#005DBF;
    color:#fff;
}
.popUpShowDetails:before {
    background-image: url("../../assets/img/icons/lupaTransparent.svg");
}
.popUpLiDelete:before {
    background-image: url("../../assets/img/icons/x.svg");
    background-repeat: no-repeat;
    background-size:unset;
    background-position:left;
    display: inline-block;
    width: .7rem;
    height: .65rem;
    margin-right: 5px;
}
.popUpLiDataSheet:before{
    content: "";
    background-image: url("../../assets/img/icons/subir.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    width: .6rem;
    height: .6rem;
    margin-right: 5px;
}
.popUpLiUndo:before{
    content: "";
    background-image: url("../../assets/img/icons/deshacer.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    width: .6rem;
    height: .6rem;
    margin-right: 5px;
}
.popUpLiSign:before{
    content: "";
    background-image: url("../../assets/img/icons/signature.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    width: .6rem;
    height: .6rem;
    margin-right: 5px;
}
.popUpLiOtroSi:before{
    content: "";
    background-image: url("../../assets/img/icons/otrosi.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    width: .6rem;
    height: .6rem;
    margin-right: 5px;
}
.popUpLiEmployeeRe:before{
    content: "";
    background-image: url("../../assets/img/icons/iconHandshake.svg");
    filter: invert(14%) sepia(82%) saturate(5664%) hue-rotate(202deg) brightness(94%) contrast(100%);
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    width: .6rem;
    height: .6rem;
    margin-right: 5px;
}
.popUpLiSign:hover{
    background-color:#005DBF;
    color:#fff;
}
.popUpLiOtroSi:hover{
    background-color:#005DBF;
    color:#fff;
}
.popUpLiEmployeeRe:hover{
    background-color:#005DBF;
    color:#fff;
}
.popUpLiUndo:hover{
    background-color:#005DBF;
    color:#fff;
}

.popUpSign:before{
    content: "";
    background-image: url("../../assets/img/icons/signature.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    width: .7rem;
    height: .7rem;
    margin-right: 5px;
}
.popUpSign:hover{
    background-color:#005DBF;
    color:#fff;
}

.popUpClone:before{
    content: "";
    background-image: url("../../assets/img/icons/TraerDocumento.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    width: .7rem;
    height: .7rem;
    margin-right: 5px;
}
.popUpRegister:before{
    content: "";
    background-image: url("../../assets/img/icons/CheckMarkBlue.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    width: .7rem;
    height: .7rem;
    margin-right: 5px;
}

.popUpClone:hover{
    background-color:#005DBF;
    color:#fff;
}

.popUpLiAdd:before {
    background-image: url("../../assets/img/icons/addbutton.svg");
}
.showIRadDig:before{
    content: "";
    // background-image: url("../../assets/img/icons/deshacer.svg");
    // background-repeat: no-repeat;
    // background-size: cover;
    display: inline-block;
    width: .1rem;
    height: .6rem;
    margin-right: 5px;
}
.showIRadDig:hover{
    background-color:#005DBF;
    color:#fff;
}

.popUpRegister:hover{
    background-color:#005DBF;
    color:#fff;
}

.sendToAccountTextStyles::before{
    content: "";
    background-image: url("../../assets/img/icons/paperplane.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    width: .7rem;
    height: .7rem;
    margin-right: 5px;
}
.sendToAccountTextStyles:hover::before{
    content: "";
    background-image: url("../../assets/img/icons/send.svg");
    background-repeat: no-repeat;
    background-size: cover;
    // display: inline-block;
    width: .7rem;
    height: .7rem;
    margin-right: 5px;
}
