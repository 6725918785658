.btn-following{
    background-color: $tenueBlue;
}
.pagination-button{
    border: 1px solid #ddd;
    a{
        color: white;
    padding: 1rem;
    font-weight: bolder;
    font-size: 1rem;
    }
}