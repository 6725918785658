@import "../../styleConstants.scss";

.container1{
    display: flex;
    flex-direction: row;
}

.container2{
    display: flex;
    flex-direction: column;
}
.inputMarginR{
    margin-right:15px;
}
.inputMarginLR{
    margin-right:15px;
    margin-left:15px;
}

.Item0{
    flex:0.2;
    display: flex;
    flex-direction: column;
}
.Item1{
    flex:0.5;
    display: flex;
    flex-direction: column;
}

.Item2{
    flex:1;
    display: flex;
    flex-direction: column;
}

.Item3{
    flex:3;
    display: flex;
    flex-direction: column;
}
.crudModalLabel{
    font-size: .75rem;
    margin: .4rem .3rem 0 .3rem;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
    color: $darkBlue;
}
.registerInputs{
    border: 1.5px solid #005DBF;
    border-radius: 5px;
    width: 100%;
    padding: 0 .5rem;
    height: 24px;
    flex:1;
}
.registerInputsBlue{
    border: 1.5px solid #005DBF;
    border-radius: 5px;
    width: 100%;
    padding: 0 .5rem;
    height: 27px;
    color: #005DBF;
    font-weight: 700;
}
.ItemCheckbox {
    display: flex;
    margin-top: 24px;
    //flex-direction: column-reverse;
}
.urgente {
    color: #697387;
    line-height: 16px;
    font-size: 12px;
    display: inline;
  }
.ItemMedium {
    flex: 1;
    display: flex;
    flex-direction: row;
}  

.pseudoInput {
    padding: 1px;
    position: relative;
    cursor: pointer;
}
  
.pseudoInput > p {
    margin: 0;
    color: #cecece;
    font-size: 16px;
    line-height: 24px;
    height: 22px;
    padding-left: 8px;
    overflow: hidden;
}
  
.pseudoInput > img {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 15px;
    height: 22px;
    margin: 0;
}
.labelInInput{
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
}
.sizeIcon{
    height: 16px;
    width:16px;
}
.marginTopIcon{
    margin-top: 20px;

}
.marginTop{
    margin-top: 14px !important;
}

.titleBlock{
    margin-top: 50px;
}
.mainTitle{
    font-family: PT Sans Caption;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 39px;
    margin: 0;
}
.bigContainer{
    margin-left: 50px;
}
.backIcon{
    width: 50px;
    height: 33px;
    margin: auto 0;
    cursor: pointer;
}
.underMainTitle{
    font-family: PT Sans Caption;
    font-size: 15px;
    line-height: 19px;
}

//TAMAÑO DE LOS INPUTS 

.f0{
    flex:0.7;
}
.f1{
    flex:1;
}
.f15{
    flex:1.5;
}
.f2{
    flex:2;
}
.f3{
    flex: 3;
}
.blueText{
    color:$darkBlue;
}
.inputMargin{
    margin-right:15px
}
.sectionTitle{
    position: relative;
    font-family: "PT Sans";
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: $darkBlue;
    margin: 15px 7px 0 12px;

}
.sectionTitleGrey{
    position: relative;
    font-family: "PT Sans";
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: $darkerGray;
    margin: 15px 7px 0 12px;

}
.line{
    height: 1px;
    width: 100%;
    border-color: #CECECE;
    background-color: #CECECE;
    margin-top: 32px;
    margin-bottom: 17px;
}
.bottomRow{
    margin: 68px 0;
    display: inline-flex;
    flex-direction: row-reverse;
    width: 100%;
    vertical-align: middle;
    padding: 0 .1rem;
}
.background{
    background-color: #F5F7FA;
}
.Item{
    display: flex;
    flex-direction: column;
}

.sectionTitleClearBlue{
    position: relative;
    font-family: "PT Sans";
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: $clearBlue;
    margin: 15px auto 0 12px;
}
.bottom{
    margin: 20px 0;
    display: inline-flex;
    flex-direction: row-reverse;
    width: 100%;
    vertical-align: middle;
    padding: 0 .5rem;
}
.selectRegister{
    padding: 0 .5rem;
    height: 24px;
    width: 100%;
    border: 1.5px solid #005DBF;
    border-radius: 5px;
    flex: 1;
}
.Search{
    border: rgba(0, 93, 191, 0.1);
    background-color: rgba(0, 93, 191, 0.1);
    border-radius: 6px;
    height: 27px;
    width: 470px;
}

.providerGearIcon{
    width: 1rem;
    padding: 0;
    margin: 0;
}
.popupReqActive::before{
    width: 12px;
    height: 12px;
    cursor:default;
}
.providerTable__Icon{
    text-align: right;
    padding-left: 22px;
    padding-right: 22px;
}
// .greenCheck{
//     background-image: url("../../assets/img/icons/CheckMark.svg");    
//     background-repeat: no-repeat;
//     width: 1rem;
//     height: 1rem;
//     width: "-webkit-fill-available"; 
//     margin-left:2px;
//     align-self:flex-end;
// }

// .alertTriangle{
//     background-image: url("../../assets/img/icons/Alert.svg");
//     background-repeat: no-repeat;
//     width: "-webkit-fill-available";
//     width: 1.5rem;
//     height: 1.1rem;
//     margin-left:2px;
// }

.bottom> Button{
    margin-left: 12px;
}


.paddingButton{
    padding-bottom: 15px;
}

.inputTable:focus,.inputTable{
   outline: none !important;
   border:none !important;
   background-color: transparent !important;

}
.filter{
    filter: invert(37%) sepia(98%) saturate(880%) hue-rotate(116deg) brightness(95%) contrast(100%);
}
.iconsIntoTable{
    display: flex;
    justify-content: center;
}
.contentInputs{
    display: flex;
    justify-content:flex-start;
}

.backgroundModal{
    background : rgba(0, 93, 191, 0.05);
    border-radius: 10px;
    padding-bottom: 16px;
    padding-left: 15px;
    //margin-right: -20px;
    padding-right: 20px;
}

.backgroundModal_new{
    border-radius: 10px;
    padding-bottom: 16px;
    //padding-left: 15px;
    //margin-right: -20px;
    //padding-right: 20px;
}

.ItemTiny_cantidad{
    flex:1.5;
    display: flex;
    flex-direction: column;
    width: 25px;
}

.textGreen{
    color:#00A551;
    margin: 0px;

}
.textRed{
    color:red;
    margin: 0px;
}
.textYellow{
    color:#F2C94C;
    margin: 0px;
}

.ItemCheckbox {
    display: flex;
    margin-top: 23px;
    //flex-direction: column-reverse;
}

.status{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.myCheckboxInput{
    width: 16px;
    height: 16px;
    border-radius: 4px;
    vertical-align: middle;
    border-color: #005DBF;
}

.myCheckbox{
    margin:0 1rem;
}

.myCheckboxLabel{
    margin-left: .5rem;
    text-transform:capitalize;
    vertical-align: middle;
}

.containerObservations{
    display: flex; 
    margin-top:-90px;
}
.observationsColor{
    background-color: #F5F7FA ;
}
.sizeObservations{
    width: 575px;
    height: 72px ;
}
.labelFont{
    font-size: 0.8rem;
    color: #005DBF;
    font-family: "PT Sans Caption";
    line-height: 0.971rem;
    font-weight: 400;
}
.marginLeftObservations{
    margin-left: 16px;
}
.observationsArea{
    max-height: 50px;
    width: 539px;
    border:1px solid $darkBlue;
    border-radius: 6px;
    background-color: #FFFFFF;
}

.delivery {
    color: #58595B;
    line-height: 17px;
    font-size: 12px;
    display: inline;
}

.btnNew{
    cursor: pointer;
    filter: grayscale(100%)
}

.btnNew:hover{
    filter: none;
}
.marginButton{
    margin-bottom: 16px;
}
.btnPrimary{
    background-color: #fff !important;
    border: 1.5px solid $clearBlue !important;
    color: $clearBlue !important;
    min-width: 110px;
    font-weight: 700;
    font-size: 15px;
    line-height: 19,41px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.btnPrimary:hover{
    background-color: $clearBlue !important;
    border: 1.5px solid $darkBlue !important;
    color:#fff !important;
}

.btnPrimary:disabled{
    background-color: $clearGray;
    border: 1.5px solid $clearGray;
    color:#fff;
    opacity: 1;
}

.btnSecondary{
    background-color:$darkBlue  !important;
    border: 1.5px solid $darkBlue !important;
    color: #fff !important;
    min-width: 110px;
    font-weight: 700;
    font-size: 15px;
    line-height: 19,41px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.btnSecondary:hover{
    background-color: $darkBlue !important;
    border: 1.5px solid $darkBlue !important;
    color:#fff !important;
}

.btnSecondary:disabled{
    background-color: #fff !important;
    border: 1.5px solid $clearGray !important;
    color:$clearGray !important;
    opacity: 1;
}
.inputsTextArea {
    border: 1.5px solid #01a0f6;
    border-radius: 5px;
    width: 100%;
    padding: 0 0.5rem;
    height: 50px;
}