@import "../../styleConstants.scss";

@font-face {
    font-family: "PT Sans Caption";
    src: url("../../assets/fonts/PT_Sans_Caption/PTSansCaption-Regular.ttf") format('truetype');
}

.genericTableScroll{
    font-family: "PT Sans Caption";
    font-weight: 400;
    font-style: normal;
    margin-top: 1rem;
    text-align: center;
    border-spacing: 0;
    border-collapse:collapse;
}

.genericTableScroll> tbody> tr> td {
    border: none;
    padding: .4rem 0;
    text-transform:lowercase;
    background-color: $white;
    color: $tableText;
    font-size: 13px;
    line-height: 16.8px;
    font-weight: 500;
    vertical-align: middle;
}
.genericTableScroll> tbody> tr> td::first-letter{
    text-transform:capitalize;
}
.table{
    border-radius: 10px;
    overflow: hidden;
    margin-top:1rem;
    margin-bottom:18px;
}

.head{
    //tr
    display: flex;
    flex-direction: row;
    //border-bottom: 1px solid $clearBlue;
}
.head > *{
    //th
    flex: 2;
    background: $clearBlue;
    color: #fff;
    font-family: "PT Sans Caption";
    font-weight: bold;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    margin:0;
    padding-top: 4px; 
    padding-bottom: 4px;

    &.f1{ flex:1; }
    &.f3{ flex:3; }
    &.f4{ flex:4; }
    &.f5{ flex:5; }
    &.f6{ flex:6; }
}

.head2{
    display: flex;
    flex-direction: row;

}
.head2 > *{
    background: $darkBlue !important;
    color: #fff;
    font-family: "PT Sans";
    font-weight: bold;
    font-size: 15px;
    line-height: 19.4px;
    text-align: center;
    margin:0;
    padding: .5rem 0;

    &.f1{ flex:1; }
    &.f3{ flex:3; }
    &.f4{ flex:4; }
    &.f5{ flex:5; }
    &.f6{ flex:6; }
}

.head3{
    display: flex;
    flex-direction: row;

}
.head3 > *{
    background: $clearBlue !important;
    color: #fff;
    font-family: "PT Sans";
    font-weight: bold;
    font-size: 15px;
    line-height: 19.4px;
    text-align: center;
    margin:0;
    padding: .5rem 0;

    &.f1{ flex:1; }
    &.f3{ flex:3; }
    &.f4{ flex:4; }
    &.f5{ flex:5; }
    &.f6{ flex:6; }
}

.head4{
    display: flex;
    flex-direction: row;

}
.head4 > *{
    background: $ordTableHeadBgColor !important;
    color: $ordTableTextColor;
    font-family: "PT Sans";
    font-weight: bold;
    font-size: 15px;
    line-height: 19.4px;
    text-align: center;
    margin:0;
    padding: .5rem 0;

    &.f1{ flex:1; }
    &.f3{ flex:3; }
    &.f4{ flex:4; }
    &.f5{ flex:5; }
    &.f6{ flex:6; }
}

.genericTableScroll{
    font-family: "PT Sans Caption";
    font-weight: 400;
    font-style: normal;
    margin-top: 1rem;
    text-align: center;
    border-spacing: 0;
    border-collapse:collapse;
}

.genericTableScroll> tbody> tr> td {
    border: none;
    padding: .4rem 0;
    text-transform:lowercase;
    background-color: $white;
    color: $tableText;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    margin:0;
    padding-top: 4px; 
    padding-bottom: 4px;

    &.f1{ flex:1; }
    &.f3{ flex:3; }
    &.f4{ flex:4; }
    &.f5{ flex:5; }
    &.f6{ flex:6; }
}

.headerClear,
.headerClear > *{
    background-color: transparent;
}
.headerDark,
.headerDark > *{
    background-color: $darkBlue;
}
.body{
    //tbody
    display: flex;
    flex-direction: column;
    //border-bottom: 1px solid $clearBlue;
}
.body summary{
    display: flex;
    flex-direction: row;
}
.body summary > section, 
.body summary > section2{
    flex: 1;
}
.body details[open] .marker{
    transform: rotate(180deg);
}
.body section, 
.body section2{
    //tbody tr
    display: flex;
    flex-direction: row;
    //border-bottom: 1px solid #fff;
}
.body section:nth-child(even),
.body section2:nth-child(even){
    //tbody tr :even
    background: $tableEven;
}
.body section:hover, 
.body section2:hover{
    background-color: $tenueBlue;
    filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.1));
    transition: 0.3s all ease-in-out;
}
.bodycontainer{
    overflow: auto;
}

.body section> *{
    //tbody tr td
    flex: 2;
    font-family: "PT Sans Caption";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin:0;
    padding-top: 4px; 
    padding-bottom: 4px;

    &.f1{ flex:1; }
    &.f3{ flex:3; }
    &.f4{ flex:4; }
    &.f5{ flex:5; }
    &.f6{ flex:6; }
}

.body section2 > *{
    border:none;
    font-family: "PT Sans Caption";
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    align-self: center;
    font-size: 13px;
    line-height: 16.8px;
    text-align: center;
    margin:0;
    padding: .4rem 0;
    color: #58595B;
}

.foot{
    display: flex;
    flex-direction: row;
    background-color: rgba( $clearBlue, .15);
    padding: 8px 16px 16px 16px;
    font-size: 12px;
    line-height: 16px;
}

.foot2{
    display: flex;
    flex-direction: row;
    background-color: "#fff";
    padding: 8px 0px 16px 0px;
    font-size: 12px;
    line-height: 16px;
}

.rowSubSection{
    margin: 3px 16px;
}
.rowSubSection2{
    margin: 3px 16px;
}
.genericTableScrollLower> tfoot> tr.orange:hover>td {
    background-color: $tableAdditionHover;
    transition: 0.3s all ease-in-out;
}


.containScroll{
    overflow-y: scroll;
}

.headerClear{
    background-color: transparent;
}
.headerDark{
    background-color: $darkBlue;
}


