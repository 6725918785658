.divDisabled {
    filter: grayscale(100%);
}

.bgBtn {
    filter: grayscale(100%);
    color: #58595b;
    font-weight: bold;
    text-align: center;
    width: 80%;
    border-radius: 20px;
    font-size: 14px;
    margin-left: 40px;
}

.bgBtn:hover {
    filter: brightness(0) saturate(100%) invert(26%) sepia(39%) saturate(3961%) hue-rotate(197deg) brightness(93%)
        contrast(102%);
    color: #005dbf;
    font-weight: bold;
    background-color: #005dbf20;
}
.bgBtnTwo {
    filter: grayscale(100%);
    color: #58595b;
    font-weight: bold;
    text-align: center;
    // margin: auto;
    border-radius: 20px;
    font-size: 14px;
    padding: 6px;
}

.bgBtnTwo:hover {
    filter: brightness(0) saturate(100%) invert(26%) sepia(39%) saturate(3961%) hue-rotate(197deg) brightness(93%)
        contrast(102%);
    color: #005dbf;
    font-weight: bold;
    background-color: #005dbf20;
}

.addPtBtn:hover{
    color: #01A0F6;
    font-family: PT Sans Caption;
    font-style: normal;
    font-size: 13px;
    line-height: 111.9%;
    text-align: right;
    cursor:pointer;
    transition: all 0.5s ease-in-out;
}
.addPtBtn{
    color: #ffffff;
}