@import "../../styleConstants.scss";
.checkbooxx {
    margin: 8px 0px 0px 0px;
}

.lineConten {
    border       : solid 2px #01A0F6;
    width        : 1px;
    margin-top   : 10px;
    margin-bottom: 50px;
}

.circuleLine {
    background-color: #fff;
    margin-left     : -11px;
    border          : solid 3px #01A0F6;
    border-radius   : 100%;
    width           : 18px;
    height          : 18px;
    margin-top      : 5px;
    box-shadow      : 0 0px 0px 5px #fff;
}


.greysection{
    border-radius: 10;
    background: rgba(0, 93, 191, 0.05);
    padding: 10px 10px;
    margin-bottom: 16px;
}

.subtitle{
    font-family: "PT Sans";
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: $darkBlue;
    margin:0;
    padding: 0;
}




.item{
    flex-direction: column;
    display: flex;
    margin-top: 10px;
}

.item > label{
    font-family: "PT Sans Caption";
    font-size: 12px;
    line-height: 16px;
    color: $darkBlue;
    margin-left: 4px;
}

.item > input,
.item > textarea{
    background: #fff;
    border: 0.7px solid rgba($darkBlue, 0.5);
    box-sizing: border-box;
    border-radius: 6px;
    flex:1;
    min-width: 0;
}

.item > input:disabled,
.item > textarea:disabled{
    background: $tableEven;
}

.item > textarea{
    height: auto;
}

.mr{
    margin-right: 16px;
}

.f1{ flex:1;}
.f2{ flex:2;}
.f3{ flex:3;}
.f4{ flex:4;}
.f5{ flex:5;}
.f6{ flex:6;}


.sec3container{
    border-radius: 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    padding: 10px;
}
.sec3title{
    font-family: "PT Sans";
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: #005DBF;
    margin: 0 0 8px 0;
}
.sec3text{
    font-family: "PT Sans Caption";
    font-size: 13px;
    line-height: 17px;
    color: #58595B;
    margin: 0 0 8px 0;
}
.sec3bottomtext{
    font-family: "PT Sans";
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: #58595B;
    margin:0 16px 0 0;
}


.checkbooxx {
    margin: 8px 0px 0px 0px;
}

.lineConten {
    border       : solid 2px #01A0F6;
    width        : 1px;
    margin-top   : 10px;
    margin-bottom: 50px;
}

.circuleLine {
    background-color: #fff;
    margin-left     : -11px;
    border          : solid 3px #01A0F6;
    border-radius   : 100%;
    width           : 18px;
    height          : 18px;
    margin-top      : 5px;
    box-shadow      : 0 0px 0px 5px #fff;
}

.textAreaGris{
    border: 0.7px solid rgba(0, 93, 191, 0.5);
    border-radius: 5px;
    width: 100%;
    padding: 0 .5rem;
    height: 27px;
}

.arrowSpacing{
}

.paddingR{
    padding-right: 1rem;
}
