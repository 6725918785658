@import "../../styleConstants.scss";
@import "../../index.scss";

.container1 {
  display: flex;
  flex-direction: row;
}

.centredContainer {
  width: 100%;
  padding: 0 6rem;
  display: flex;
  flex-direction: column;
}

//zone of new angie made tables
.container2 {
  //flex:2;
  //margin-top:15px;
  display: flex;
  flex-direction: column;
}


.btnPrimary {
    background-color: $clearBlue;
    border          : 1.5px solid $clearBlue;
    font-family     : 'PT Sans';
    color           : #fff;
    min-width       : 110px;
    font-weight     : 700;
    font-size       : 15px;
    line-height     : 19.41px;
    padding-top     : 6px;
    padding-bottom  : 6px;
}

.btnPrimary:hover {
    background-color: $darkBlue;
    border          : 1.5px solid $darkBlue;
    color           : #fff;
}

.btnPrimary:disabled {
    background-color: $clearGray;
    border          : 1.5px solid $clearGray;
    color           : #fff;
    opacity         : 1;
}

.btnPrimaryDark{
    background-color: $darkBlue;
    border          : 1.5px solid $darkBlue;
    font-family     : 'PT Sans';
    color           : #fff;
    min-width       : 110px;
    font-weight     : 700;
    font-size       : 15px;
    line-height     : 19.41px;
    padding-top     : 6px;
    padding-bottom  : 6px;
}

.btnPrimaryDark:hover {
    background-color: $clearBlue;
    border          : 1.5px solid $clearBlue;
    color           : #fff;
}

.btnPrimaryDark:disabled {
    background-color: $clearGray;
    border          : 1.5px solid $clearGray;
    color           : #fff;
    opacity         : 1;
}

.btnSecondary {
    background-color: #fff;
    border          : 1.5px solid $clearBlue;
    font-family     : 'PT Sans';
    color           : $clearBlue;
    min-width       : 110px;
    font-weight     : 700;
    font-size       : 15px;
    line-height     : 19.41px;
    padding-top     : 6px;
    padding-bottom  : 6px;
}

.btnSecondary:hover {
    background-color: $clearBlue;
    border          : 1.5px solid $clearBlue;
    color           : #fff;
}

.btnSecondary:disabled {
    background-color: #fff;
    border          : 1.5px solid $clearGray;
    color           : $clearGray;
    opacity         : 1;
}