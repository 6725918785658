.Search {
    border: rgba(0, 93, 191, 0.1);
    background-color: rgba(0, 93, 191, 0.1);
    border-radius: 5px;
    width: 100%;
    padding: 0 0.5rem;
    height: 27px;
    color: #005dbf;
}
.Search::placeholder {
    color: #005dbf70;
}
// .btnpreviw {
//     cursor: pointer;
// }
// .btnpreviw:hover {
//     filter: brightness(0) saturate(100%) invert(27%) sepia(100%) saturate(1235%) hue-rotate(191deg);
// }
.btnpreviw {
    cursor: pointer;
    background-image: url(../../assets/img/icons/arrowPreviw.svg);
}
.btnpreviw:hover {
   background-image: url(../../assets/img/icons/arrowPreviwHover.svg);
}
.bottomRow {
    margin: 20px 0;
    display: inline-flex;
    flex-direction: row-reverse;
    width: 100%;
    vertical-align: middle;
    padding: 0 0.5rem;
}

.bottomRow > Button {
    margin-left: 12px;
}

.textSubtitle{
    font-family: "PT Sans";
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: #005DBF;
    margin:0;
}

.totalRowText{
    font-family: "PT Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #58595B;
}

.NewsRowText{
    font-family: "PT Sans Caption";
    font-size: 13px;
    line-height: 17px;
    display: flex;
    color: #58595B;
    margin: 0px;
    align-items: center;
}

.iconPlus{
    width: 25px;
    height: 25px;
    filter: brightness(0) saturate(100%) invert(21%) sepia(64%) saturate(3014%) hue-rotate(198deg) brightness(101%) contrast(102%);
    cursor: pointer;
}

.iconPlus:hover{
    filter:none
}
