@import "../../styleConstants.scss";
.container404 {
    width: 100vw;
    max-width: 100vw;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-inline: auto;
    height: 100vh;

    background-image: url("../../assets/img/404/ERROR404.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.error404Btn {
    width: 100%;
    max-width: 280px;
    border-radius: 15px;
    top: 35%;
    font-size: 20px;
    background-color: $ordAquaMarine;
    color: white;
    padding: 25px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s;
}

.error404Btn:hover{
    opacity: 0.8;
}