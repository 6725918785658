@import "../../../styleConstants.scss";

.container{
    flex:1;
    display: flex;
    max-height: 20px;    
    justify-content: center;
}

.btn{
    display: flex;
    padding: 0 1px;
    text-align: center;
    width: 15px;
    height: 20px;
    border: 0.5px solid #CECECE;
    background: #fff;
    cursor: pointer;
}

.btn:hover{
    background: #CECECE;
}

.btnDown{
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.btnUp{
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}


.input{
    width: 38px;
    border: 0.5px solid #CECECE;
    border-radius: 0;
    
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    color:$clearBlue;
}