@import "../../styleConstants.scss";
.container1{
    display: flex;
    flex-direction: row;
}
.throwThisToTheBottom{
    display: flex;
    flex-direction: column-reverse;
}
//zone of new angie made tables
.container2{
    //flex:2;
    //margin-top:15px;
    display: flex;
    flex-direction: column;
}
.container3{
    flex:1;
    display: flex;
    flex-direction: column;
}
.ItemTiny{
    flex:1.02;
    display: flex;
    flex-direction: column;
}
.ItemSmall{
    flex:6;
    display: flex;
    flex-direction: column;
}
.ItemTiny_new{
    flex:2.4;
    display: flex;
    flex-direction: column;
}
.ItemTiny2{
    flex:1.31;
    display: flex;
    flex-direction: column;
}
.ItemMedium{
    flex:1;
    display: flex;
    flex-direction: row;
}
.Aling{
    display:flex ;
    flex:1;
    //align-items: flex-end;
}
.inputMargin{
    margin-right:15px;
}
.ItemCheckbox{
    display: flex;
    margin-top: 24px;
    //flex-direction: column-reverse;
}
.greyRow{
    display:flex;
    flex-direction: row;
    background-color: #ddd;
    border-radius: .5rem;
    margin-top:1rem;
    padding: .5rem;
    padding: .5rem 2rem;
}
.textArea{
    height: 32px;
    border: 1.5px solid $darkBlue;
    border-radius: 6px;
}
.textArea_new{
    height: 28px;
    border: 1.5px solid $darkBlue;
    border-radius: 6px;
}
.bottomRow{
    margin: 20px 0;
    display: inline-flex;
    flex-direction: row-reverse;
    width: 100%;
    vertical-align: middle;
    padding: 0 .5rem;
}
.bottomRow> Button{
    margin-left: 12px;
}
.tableButton{
    cursor: pointer;
    color: $tableText;
    text-decoration: none;
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 5px;
    font-size: 12px;
    line-height: 16px;
}
.tableButton:hover{
    color: $darkBlue;
    padding-top: 0px;
    transition-duration: 0.2s;
}
.tableSlash{
    color: $tableText;
    font-size: 12px;
    line-height: 16px;
}
.traerReq{
    display: flex;
    min-width: 140px;
    height: 30px;
    font-weight: Bold;
    font-size: 12px;
    line-height: 16px;
    color: #697387;
    cursor: pointer;
    border-radius: 10px;
    margin: 0 0 0 auto;
}
.traerReq:hover{
    background-color: #F5F7FA;
}
.traerReq > p {
    flex: 1;
    margin: 8px 6px 4px 6px;
}
.traerReq:hover> p{
    color: #005DBF;
}
.traerReq:hover> img{
    filter: brightness(0) saturate(100%) invert(20%) sepia(94%) saturate(1860%) hue-rotate(196deg) brightness(107%) contrast(105%);
}
.pseudoInput{
    padding: 1px;
    position: relative;
    cursor: pointer;
}
.pseudoInput>p{
    margin:0;
    color:#CECECE;
    font-size: 16px;
    line-height: 24px;
    height: 22px;
    padding-left: 8px;
    overflow: hidden;
}
.pseudoInput>img{
    position: absolute;
    top:2px;
    right:2px;
    width: 15px;
    height: 22px;
    margin: 0;
}
.urgente{
    color: #697387;
    line-height: 16px;
    font-size: 12px;
    display: inline;
}
.newArticle{
    margin: 0 5px;
    border: 1.5px solid $orange;
    border-radius: 5px;
    padding: 0 15px;
}
.adjustIcon{
    cursor: pointer;
    width: 20px;
    margin: 0;
}
//added for article insert ts-147
.orangeIcon{
    fill: $orange;
    filter: saturate(3000%) hue-rotate(190deg);
}
.orangeBorder{
    border: 1.5px solid $orange;
    border-radius: 5px;
}
.orangeText{
    color: $orange;
    font-weight: Bold;
    font-size: 12px;
    line-height: 16px;
}
.orangeCheck{
    background-color: $orange;
    border-color: $orange;
}
.subtitle{
    margin: 16px 0 0 0;
    color:#005DBF;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
}
.TopModalText{
    color: #58595B;
}
.backgroundModal{
    background : rgba(0, 93, 191, 0.05);
    border-radius: 10px;
    padding-bottom: 16px;
    padding-left: 20px;
    padding-right: 20px;
}

.nitBig{
    flex:1
}

.nitMini{
    width: 30px;
}
.crudModalTopLabel{
    font-size: .75rem;
    margin: .4rem .3rem 0 .3rem;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
    color: #58595B;
}
.crudModalBottomLabel{
    font-size: .75rem;
    margin: .4rem .3rem 0 .3rem;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
    color: #01A0F6;
}

.ItemRz{
    flex:4.35;
    display: flex;
    flex-direction: column;
}
.ItemCompT{
    flex:0;
    display: flex;
    flex-direction: column;
}
.ItemNormal{
    flex:3;
    display: flex;
    flex-direction: column;
}
.ItemLarge{
    flex:6.25;
    display: flex;
    flex-direction: column;
}
.ItemXL{
    flex:9;
    display: flex;
    flex-direction: column;
}
.ItemInicio{
    flex:1.6;
    display: flex;
    flex-direction: column;
}
.Search{
    vertical-align: top !important;
}
.register_inputs_new{
    height: 24px;
    width: 100%;
    border: 1.5px solid #005DBF;
    border-radius: 5px;
    flex: 1;
}

.register_inputs_new2{
    width: 100%;
    // border: 1.5px solid #005DBF;
    // border-radius: 5px;
    // flex: 1;
}