@import "../../styleConstants.scss";

@font-face {
    font-family: "PT Sans Caption";
    src: url("../../assets/fonts/PT_Sans_Caption/PTSansCaption-Regular.ttf") format('truetype');
}

.table{
    margin-top: 8px;
    width: 100%;
    //max-height: 450px;
}

.head{
    background: $clearBlue;
    &.dark{
        background-color: $darkBlue;
    }
}
.head> tr > th{
    //th
    background: $clearBlue;
    color: #fff;
    font-family: "PT Sans";
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    padding: 5px 10px;
    //z-index: 0;
    &.dark{
        background-color: $darkBlue;
    }
}
.head> tr > th:first-child{
    //th
    border-top-left-radius: 10px;
}
.head> tr > th:last-child{
    //th
    border-top-right-radius: 10px;
}

.body{}



.tablerow > td{
    font-family: "PT Sans Caption";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    padding: 5px 10px;
    color: $darkGray;
}

.tablerowEven{ 
    background: $tableEven;
}

.subtableTitle{
    font-family: "PT Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: $darkBlue;
    margin: 2px 0;
}

.subtableTh > th{
    font-family: "PT Sans Caption";
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: $darkGray;
    padding: 1px 8px;
}

.subtableTr > td{
    font-family: "PT Sans Caption";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $darkGray;
    padding: 5px 8px;
}