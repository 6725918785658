@import "../../styleConstants.scss";

.subtitle{
    margin: 16px 0 0 0;
    color:$darkBlue;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
}

.item{
    display: flex;
    flex-direction: column;
}
.f1{ flex:1;}
.f2{ flex:2;}
.f3{ flex:3;}
.f4{ flex:4;}
.f5{ flex:5;}
.f6{ flex:6;}

.titleBlock{
    margin-top: 50px;
    display: flex;
}

.backIcon{
    width: 50px;
    height: 30px;
    margin: auto 0;
    cursor: pointer;
}

.mainTitle{
    font-family: PT Sans Caption;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 39px;
    margin: 0;
}

.bigContainer{
    margin-left: 50px;
}

.mr16{
    margin-right: 16px;
}

.label{
    font-size: .75rem;
    margin: .4rem .3rem 0 .3rem;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
    color: $darkBlue;
}

.input{
    border: 1.5px solid #005DBF;
    border-radius: 5px;
    width: 100%;
    padding: 0 .5rem;
    height: 24px;
    flex:1;
}

.observationsArea{
    max-height: 50px;
    width: 539px;
    border:1px solid #7FADDE;
    border-radius: 6px;
    background-color: #FFFFFF;
}

.upperBlueText{
    font-family: "PT Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    align-items: flex-end;
    color: $clearBlue;
}

.bottomRow {
    margin: 20px 0;
    display: inline-flex;
    flex-direction: row-reverse;
    width: 100%;
    vertical-align: middle;
    padding: 0 0.5rem;
}

.bottomRow > Button {
    margin-left: 12px;
}



.inputReq{
    background-image: url("../../assets/img/icons/SearchLocal.svg");
    background-position: right;
    background-origin: content-box;
    background-repeat: no-repeat;
}


.articleDetails{
    background: rgba($darkBlue, 0.05);
    border-radius: 10px;
    padding: 10px 8px 8px 6px;
    margin-bottom: 8px;
}
.articleDetails__title{
    font-family: "PT Sans Caption";
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    margin-left: 2px;
    margin-bottom: 2px;
    color: $clearBlue;
}
.articleDetails__label,
.articleDetails__label__blue{
    font-family: "PT Sans Caption";
    font-size: 12px;
    line-height: 16px;
    margin-left: 4px;
    margin-bottom: 0;
}   
.articleDetails__label{
    color: $darkGray;
}
.articleDetails__label__blue{
    color: $clearBlue;
}
.articleDetails__text{

    font-family: "PT Sans Caption";
    font-size: 13px;
    line-height: 17px;
    color: $darkGray;
    padding: 4px 8px;
    border-radius: 6px;
    margin-bottom: 0;
    border: 1px solid $clearBlue;
}

