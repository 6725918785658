@import "../../styleConstants";


/*BEM*/
/*nombresEnCammelCase*/


.buttonCreatCount{
    flex-direction: row;
    float: right;
    width: 80px;
    height: 30px;
    display: flex;
    border-radius: 6px;
    justify-content: space-evenly;
    color : #58595B;
}

.sizeLink{
    width: 10px;
    height: 10px;
}

.btnNewHover{
    cursor: pointer;
    filter: grayscale(1);
}

.labelNew{
    font-weight: 700;
    font-style : normal;
    font-size: 12px;
    line-height:15.53px;
    margin-bottom: 1.5px;
    align-content: left ;
    color: #58595B;
    padding: 6px 1px 3px 4px;
}

.flex1{
    flex: 1;
}

.Search{
    border: rgba(0, 93, 191, 0.1);
    background-color: rgba(0, 93, 191, 0.1);
    border-radius: 6px;
    width: 270px;
    
}

.titulo{
    color:#005DBF;
    font-weight: 400;
    font: "PT Sans Caption";
    size: 12px;
    line-height:19.41px;
    margin-bottom: 1.5px;
    display: flex;
}

.register_inputs_new{
    //box-sizing: content-box;
    height :24px;
    border-radius :6px;
    border: 1.5px solid #005DBF;
}

.Position{
    margin-top: 15px
}

.buttonCreatCount:hover{
    background-color: #E6ECF2;
}
.buttonCreatCount:hover>p{
    color: #005DBF;
}

.buttonCreatCount>img{
    height: 20px;
    top: 3px;
}
.buttonCreatCount:hover>img{
    filter: none;
}
.buttonCreatCount>p{
    size: 76px;
    line-height:16px;
    margin-bottom: 0;
    color: #58595B;
    cursor: pointer;
    font-Weight:700;
}

.widtDiv{
    width:-webkit-fill-available;
}



