.service-chips-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 5px;
    padding: 5px;

    .service-chips {
        padding: 0 5px;
        border-radius: 3px;
        background-color: #FCF1E1;
        margin: 0 5px;
    }
}