.timeline-accordion {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 70px;
    flex-shrink: 0;
}

.timeline-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 5px;
    right: 0;
    height: 100%;
}

.timeline-circle {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 35px;
    height: 35px;
    background: #005dbf;
    border-radius: 50%;
    flex-shrink: 0;
}

.dashed-bottom {
    width: 1px;
    height: 100%;
    border-left: 2px dotted rgba($color: #005dbf, $alpha: 0.3);
}