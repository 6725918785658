@import "../../styleConstants.scss";

.sectionCart{
    font-family: "PT Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    align-items: flex-start;
    color: $darkBlue;
    margin: 22px 0 0px 0;
}

.sectionCart > img{
    width: 18px;
    height: 18px;
}

.sectionCart > p{
    margin: auto auto auto 6px;
}

.inputBox{
    color: $darkGray;
    background: #FFF;
    border: 1px solid $clearBlue;
    box-sizing: border-box;
    border-radius: 6px;
}

.inputBox:disabled{
    color: $darkGray;
    background: $tableEven;
    border: 1px solid $clearBlue;
}

.inputBoxCart{
    color: $darkBlue;
    background: #FFF;
    border: 1px solid $clearBlue;
    box-sizing: border-box;
    border-radius: 6px;
}

.inputBoxCart:disabled{
    color: $darkBlue;
    background: $tableEven;
    border: 1px solid $clearBlue;
}

.carritoLabel{
    font-family: "PT Sans Caption";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $darkerGray;
    margin: .4rem .3rem 0 .3rem;
}

.bigContainer{
    margin-left: 50px;
}

.sectionContainer{
    margin-top:38px;    
}

.backIcon{
    width: 40px;
    height: 30px;
    margin: auto 0;
    cursor: pointer;
}

.titleBlock{
    margin-top: 50px;
}

.mainTitle{
    font-family: PT Sans Caption;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 39px;
    margin: 0;
}

.underMainTitle{
    font-family: PT Sans Caption;
    font-size: 15px;
    line-height: 19px;
}

.blueText{
    color:$darkBlue;
}

.greenText{
    color: $greenEnabled;
}

.yellowText{
    color: $yellowAux;
}

.redText{
    color: $red;
}

.sectionTitle{
    position: relative;
    font-family: "PT Sans";
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: $darkBlue;
    margin: 15px auto 0 12px;
    display: inline-block;

}

.sectionTitle::before{
    position: absolute;
    width: 5px;
    height: 5px;
    top: 7.1px;
    left: -10px;
    content: "";
    background: $darkBlue;
    border-radius: 50%;
}

.titleIcons{
    width: 25px;
    height: 25px;
    margin-left: 8px;
}
.titleIcons:hover{
    filter: brightness(0) saturate(100%) invert(20%) sepia(94%) saturate(1860%) hue-rotate(196deg) brightness(107%) contrast(105%);
}

.buttonIcons{
    width: 15px;
    height: 15px;
    margin: auto 0;
}

.tableGearIcon{
    width: 20px;
    height: 20px;
}
.providerGearIcon{
    width: 1rem;
    padding: 0;
    margin: 0;
}

.filterWhite{
    filter: brightness(0) saturate(100%) invert(1);
    width: 24px;
    height: 24px;
}


.articleControlIcon{
    position: relative;
    cursor: pointer;
    border-radius: 30px;
    margin-left: 18px;
}
.articleControlIcon:hover{
    background: rgba( $darkerGray, .15);
}
.articleControlIcon.expand,
.articleControlIcon:focus-within{
    background: rgba( $darkBlue, .1);
}
.articleControlIcon:hover > img{
    filter: brightness(0) saturate(100%) invert(20%) sepia(94%) saturate(1860%) hue-rotate(196deg) brightness(107%) contrast(105%);
}


.inputText{
    font-size: 13px;
    line-height: 17px;
}

.articleSearch{
    transition: transform 0.3s; 
    width: 25px;
    background: transparent;
    border: none;
    margin: 0;    
    font-size: 12px;
    line-height: 16px;
    color: $darkerGray;
    background-image: url("../../assets/img/icons/auctionDetailLupa.svg");
    background-repeat: no-repeat;
    background-position: right;
    cursor: pointer;
}
.articleSearch.expand,
.articleSearch:focus{
    transition: transform 0.3s; 
    width: 200px;
    margin: 2px 0 2px 10px;
    outline: none;
}
.articleSearchIcon{
    position: absolute;
    right: 0;
    top: 0;
}

.toggleAll{
    transition: transform 0.3s; 
    cursor: pointer;
}
.rotate{
    transform: rotate(180deg)
}
.clearGrayText{
    color: $clearGray;
}


.clearGrayText{
    color: $clearGray;
}


.adjustIconDescargar{
    cursor: pointer;
    width: 28px;
    margin: 0;
}
.adjustIconDescargar:hover{
    filter: brightness(0) saturate(100%) invert(22%) sepia(100%) saturate(1940%) hue-rotate(198deg) brightness(91%) contrast(103%);
}

.rowName{
    text-align: left;
    padding-left:5px;
}
.rowValue{
    text-align: right;
    padding-right:22px
}
.rowTotal{
    color: $darkBlue;
    font-weight: bold;
    text-transform: uppercase;
}

//seccion boton carrito
.btnCarrito{
    display: flex;
    min-width: 110px;
    height: 30px;
    font-weight: Bold;
    font-size: 12px;
    line-height: 16px;
    color: #697387;
    cursor: pointer;

    border-radius: 10px;
    margin: 0 15px 0 auto;
}

.btnCarrito:hover{
    background-color: #F5F7FA;
}

.btnCarrito > p {
    flex: 1;
    margin: 7px 6px 4px 6px;
}

.btnCarrito:hover> p{
    color: #005DBF;
}
.btnCarrito> img{
    margin: auto 4px;
    height: 20px;
}
.btnCarrito:hover> img{
    filter: brightness(0) saturate(100%) invert(20%) sepia(94%) saturate(1860%) hue-rotate(196deg) brightness(107%) contrast(105%);
}


.justification {
    min-height: 50px;
    height: 32px;
    border: 1.5px solid $darkBlue;
    border-radius: 6px;
}


.f1{
    flex: 1;
}
.f2{
    flex: 2;
}
.f3{
    flex: 3;
}
.namesColum{
    font-family: "PT Sans"; 
    font-style: "normal";
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #58595B;
    margin-top: 10px;
}

.IconCheckType{
    width: 24px;
    height: 24px;
}

//seccion tabla de proveedores
.tableMargin{
    margin-top: .4rem;
}

.providerTable{
    border: 1px solid #005DBF;
    border-radius: 10px;
    overflow: hidden;
}

.providerTable__Header{
    display: flex;
    flex-direction: row;
}

.providerTable__Header > div{
    font-family: "PT Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 17px;
    padding-top: 6px;
    padding-bottom: 6px;
    text-align: center;
    background: #fff;
    color: $darkBlue;
}

.providerTable__Body{    
    max-height: 300px;
    overflow: auto;
}

.providerTable__Row{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
}

.providerTable__Row > div{
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 12px;
    line-height: 16px;
    color: $parragraphColor;
}

.providerTable__Row:nth-child(odd){
    background: $tableEven;
}

.providerTable__Row .providerTable__ProviderName{
    flex:3;
    text-align: left;
    padding-left: 36px;
    color: $darkBlue;
}

.providerTable__Icon{
    text-align: right;
    padding-left: 22px;
    padding-right: 22px;
}



//zone purchase order
.adjustIconPart2{
    height: 16px;
    width: 16px;
}

.adjustIconPart2Orange{
    height: 24px;
    width: 24px;
}

.textPart2{
    margin: auto 0 0 10px;
    color:$darkBlue;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
}
.textPart2Orange{
    margin: auto 0 0 2px;
    color:$orange;
}
.iconTrash{
    width: 20px;
    height: 20px;
}