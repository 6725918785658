@import "../../styleConstants.scss";

.adjustIconDetalles{
    cursor: pointer;
    width: 14px;
    margin: 0;
}

.Item{
    display: flex;
    flex-direction: column;
}

.ItemSeparator{
    width:16px;
    height: 1px;
    background: $clearGray;
    margin: auto 0;
}


.f1{
    flex:1;
}

.f2{
    flex:2;
}

.f3{
    flex:3;
}

.fnone{
    flex:none;
}

.inputMargin{
    margin-right:15px
}

.separator{
    width: 15px;
}
.separator > p{
    text-align: center;
}

.textarea {
    height: 27px;
    border: 1.5px solid $darkBlue;
    border-radius: 6px;
    overflow: hidden;
}

.popupReqActive::before{
    width: 12px;
    height: 12px;
    cursor:default;
}


.bottomRow{
    margin: 20px 0;
    display: inline-flex;
    flex-direction: row-reverse;
    width: 100%;
    vertical-align: middle;
    padding: 0 .5rem;
}

.bottomRow> Button{
    margin-left: 12px;
}

.reqCountText{
    font-family: "PT Sans Caption";
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    text-decoration-line: underline;
    text-transform: lowercase;
    color: $darkBlue;
    cursor: pointer;
}