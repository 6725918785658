@import "../../styleConstants";

.react-code-input{
    display: flex !important;
    justify-content: space-between;
}

.react-code-input > input {
    width :100%;
    height :26px;
    border-radius: 6px;
}

.react-code-input > input:disabled {
    color:#58595B !important;
}
