@import "../../styleConstants";

.sizeImage{
    width:30px;
    height :24px;
    margin-top: 25px;
}
.trashImage{
    width:20px;
    height :20px;
}