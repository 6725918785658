.botn {
    border-radius: 15px;
    box-shadow   : 0px 4px 20px rgba(0, 0, 0, 0.15);
    width        : 200px;
    height       : 130px;
    filter       : grayscale(100%);
}

.botn:hover {
    filter          : grayscale(0%);
    background-color: #005cbf0e;

}

.botn>div>p {
    margin-top : 5px;
    font-family: PT Sans Caption;
    font-style : normal;
    font-weight: bold;
    font-size  : 16px;
    line-height: 21px;
}

.bottonn {
    width : 200px;
    height: 130px;
    filter: grayscale(100%);
    border: none;
    margin: auto;
}

.hovimg {
    margin-bottom: 10px;
}

.bottonn:hover {
    .borhov {
        border          : 1px solid #005DBF;
        background-color: #005cbf0e;
    }

    filter: grayscale(0%);
}

.borhov {
    border       : solid 1px #CECECE;
    border-radius: 10px;
    margin       : auto;
}

.borhov>p {
    margin-top : 5px;
    font-family: PT Sans Caption;
    font-style : normal;
    font-weight: bold;
    font-size  : 16px;
    line-height: 21px;
    width      : 110px;
    margin     : auto;
}

.text-radi {
    margin-top : 5px;
    font-family: PT Sans Caption;
    font-style : normal;
    font-weight: bold;
    font-size  : 16px;
    line-height: 21px;
    margin     : auto 10px;
    color      : #58595B;
}

.bg-card {
    margin-top   : 20px;
    margin-bottom: 20px;
    padding      : 20px;
    width        : 690px;
    background   : #F2F7FC;
    border-radius: 10px;
}


[data-title]:hover:after {
    opacity   : 1;
    visibility: visible;
}

[data-title]:after {
    content         : attr(data-title);
    color           : #58595B;
    position        : absolute;
    padding         : 3px 20px;
    bottom          : -1.6em;
    left            : 100%;
    visibility      : hidden;
    background-color: #FFFFFF;
    box-shadow      : 0px 1px 4px rgba(0, 63, 128, 0.35);
    border-radius   : 6px;

}

[data-title] {
    position: relative;
}

.bg-button {
    margin-top   : 20px;
    margin-bottom: 20px;
    padding      : 20px;
    width        : max-content;
    background   : #F2F7FC;
    border-radius: 10px;
}

.bg-inputs {
    margin-top   : 20px;
    margin-bottom: 20px;
    padding      : 20px;
    width        : 100%;
    background   : #F2F7FC;
    border-radius: 10px;
}

.bg-img-prejuridico {
    background-image   : url("../../assets/img/icons/Filtrar.svg");
    background-repeat  : no-repeat;
    background-position: 98% center;
    box-sizing         : border-box;
    border-radius      : 6px;
}

