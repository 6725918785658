@import "../../styleConstants";


/*BEM*/
/*nombresEnCammelCase*/

.btnNewHover{
    cursor: pointer;
    filter: grayscale(1);
}
.justify{
    display: flex;
    justify-content:between;
}
.registerInputs{
    border: 1.5px solid #01A0F6;
    border-radius: 6px;
    width: 100%;
    padding: 0 .5rem;
}

.p:hover{
    filter: none;
}

.widtDiv{
    width:-webkit-fill-available;
}

.Search{
    border: rgba(0, 93, 191, 0.1);
    background-color: rgba(0, 93, 191, 0.1);
    border-radius: 6px;
    width: 270px;
}

.buttonExcel{
    // width: 15.16px;
    height: 20px;
    padding: 2px 0px 0px 0px;
}

.buttonExcel:hover{
    filter: none;
}

.inputIcono{
  background-image: url('../../assets/img/icons/excel.svg');
  background-repeat: no-repeat;
  background-position: 4px center;

}

.hoverIcon{
    color:#01A0F6;
}
.selectState{
    width:213px;
    height :24px;
    border-radius :6px;
    border: 1.5px solid #01A0F6;
}

.labelNew{
    font-weight: 400;
    font-style : normal;
    font-size: 12px;
    line-height:15.53px;
    margin-bottom: 1.5px;
    align-content: left ;
    color: #58595B;
    padding: 6px 2px 3px 4px;
}

.imagePadding{
    padding: 0px 0px 0px 0px;
    margin-top: 4px;
    
}

.buttonCreatCount{
    flex-direction: row;
    float: right;
    width: 150px;
    //height: 19,86px;
    height: 30px;
    display: inline-flex;
    border-radius: 10px;
    //vertical-align: middle;
    justify-content: space-evenly;
}

.label_inputs_new{
    color:#005DBF;
    font-weight: 400;
    font-style : normal;
    font-size: 12px;
    line-height:15.53px;
    margin-bottom: 1.5px;
}


.label_inputs_new1{

    color:#005DBF;
    font-weight: 400;
    font-style : normal;
    font-size: 12px;
    line-height:15.53px;
}

.label_inputs_new2{
    color:#58595B;
    font-weight: 400;
    font-style : normal;
    font-size: 10px;
    line-height:12.94px;
    text-align: center;
}
.label_inputs_new3{

    color:#58595B;
    font-weight: 400;
    font-style : normal;
    font-size: 12px;
    line-height:15.53px;
}
.form-check-input:checked {
    background-color: #005DBF;
    border-color: #005DBF;
}
.divAjuste2{
    width: 100%;
    flex:1;
}

.divAjuste3{
    flex:2;
    width: 100%;
    //display: flex;
}


.divAjuste{
    display: flex;
    align-items: center;
    justify-content: space-around;
}


.buttonCreatCount>p{
    size: 76px;
    line-height:16px;
    margin-bottom: 0;
    color: #58595B;
    cursor: pointer;
    font-Weight:700;
}

.register_inputs_new{
    box-sizing: content-box;
    width:100%;
    height :24px;
    border-radius :6px;
    border: 1.5px solid #01A0F6;
    flex-direction: row;

}

.register_inputs_new:disabled{
    color:#58595B !important;
    background-color:#F5F7FA;
    border: 1.5px solid #01A0F6;
}

.register_inputs_new1{
    width:300px;
    height :24px;
    border-radius :6px;
    border: solid #01A0F6;
}

.styleCheckInputRatio{
    border: solid#005DBF;
    stroke: Solid#005DBF;
}


.buttonCreatCount:hover{
    background-color: rgba(0, 63, 128, 0.1);
}
.buttonCreatCount:hover>p{
    color: #005DBF;
}

.buttonCreatCount>img{
    height: 20px;
    //top: 3px;
}
.buttonCreatCount:hover>img{
    filter: none;

}

.flexDiv{
    flex:1;
    display: flex;
    flex-direction: column;
}

/*padre__hijo*/
.flexDiv__flexSelectInput{
    flex:1
}

/*padre--modificador*/
.flexDiv_margin{
    margin-right: 15px;
}

.titulo{
    color:#005DBF;
    font-weight: 700;
    font-style :normal;
    size: 12px;
    line-height:19.41px;
    margin-bottom: 1.5px;
}

