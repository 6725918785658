@import "../../styleConstants.scss";

.inputsTextArea {
    border: 1.5px solid #01a0f6;
    border-radius: 5px;
    width: 100%;
    padding: 0 0.5rem;
    height: 154px;
}

.labelGrey {
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    line-height: 15.53px;
    margin-bottom: 1.5px;
    align-content: left;
    color: #58595b;
    padding: 6px 1px 3px 4px;
}

.labelBlue {
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    line-height: 15.53px;
    margin-bottom: 1.5px;
    align-content: left;
    color: #005dbf;
    padding: 6px 1px 3px 4px;
}
.card {
    width: 300px;
    height: 180px;
    border-radius: 15px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    margin-bottom: 50px;
}
.card:hover {
    opacity: 0.8;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}
.nameBank {
    color: #fff;
    padding-top: 1rem;
    padding-left: 15px;
    font-size: 18px;
    font-weight: bold;
}
.codeBank {
    color: #fff;
    letter-spacing: 0.5em;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding-top: 1rem;
}
.nameUserCard {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    padding-left: 15px;
}
.nameBankGray {
    color: #58595b;
    padding-top: 1rem;
    padding-left: 15px;
    font-size: 18px;
    font-weight: bold;
}
.codeBankGray {
    color: #58595b;
    letter-spacing: 0.5em;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding-top: 1rem;
}
.nameUserCardGray {
    color: #58595b;
    font-size: 14px;
    font-weight: bold;
    padding-left: 15px;
}
.logoCard {
    text-align: end;
    padding-right: 1rem;
    margin-top: -20px;
}
.inputDateCard {
    background-position: 95%;
    background-repeat: no-repeat;
    background-image: url(../../assets/img/icons/date.svg);
}

.inputDateCard::placeholder {
    color: #005dbf;
}
.cardTypeSelec {
    border: 1.5px solid #01a0f6;
    padding: 5px;
    border-radius: 5px;
}
.btnAddCard {
    width: 45px;
    border: 2px solid #cecece;
    border-radius: 30px;
    height: 45px;
    text-align: center;
}
.btnAddCard span {
    color: #cecece;
    font-size: 27px;
}
.btnAddCard span:hover {
    color: #fff;
    font-size: 27px;
}
.btnAddCard:hover {
    background-color: #005dbf;
    border: none;
}
.bgBtn {
    filter: grayscale(100%);
    color: #58595b;
    font-weight: bold;
    text-align: center;
    width: 80%;
    border-radius: 20px;
    font-size: 14px;
    margin-left: 40px;
}

.bgBtn:hover {
    filter: brightness(0) saturate(100%) invert(26%) sepia(39%) saturate(3961%) hue-rotate(197deg) brightness(93%)
        contrast(102%);
    color: #005dbf;
    font-weight: bold;
    background-color: #005dbf20;
}
.bgBtnTwo {
    filter: grayscale(100%);
    color: #58595b;
    font-weight: bold;
    text-align: center;
    margin: auto;
    border-radius: 20px;
    font-size: 14px;
    padding: 6px;
}

.bgBtnTwo:hover {
    filter: brightness(0) saturate(100%) invert(26%) sepia(39%) saturate(3961%) hue-rotate(197deg) brightness(93%)
        contrast(102%);
    color: #005dbf;
    font-weight: bold;
    background-color: #005dbf20;
}


.btnImage{
    cursor: pointer;
}
.btnImage:hover>img{
    filter: invert(50%) sepia(65%) saturate(4049%) hue-rotate(173deg) brightness(102%) contrast(102%);
}
