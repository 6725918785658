@import "../../styleConstants.scss";
.btnNewHover{
    cursor: pointer;
    filter: grayscale(1);
}
.labelNew{
    font-weight: 700;
    font-style : normal;
    font-size: 12px;
    line-height:15.53px;
    margin-bottom: 1.5px;
    align-content: left ;
    color: #58595B;
    padding: 6px 1px 3px 4px;
}
.buttonCreatCount{
    flex-direction: row;
    float: right;
    width: 80px;
    height: 30px;
    display: flex;
    border-radius: 6px;
    justify-content: space-evenly;
}
.Search{
    border: rgba(0, 93, 191, 0.1);
    background-color: rgba(0, 93, 191, 0.1);
    border-radius: 6px;
    width: 270px;
    
}
.widtDiv{
    width:-webkit-fill-available;
}
.inputMargin{
    margin-right:15px;
}
.ItemInicio{
    flex:1.6;
    display: flex;
    flex-direction: column;
}
.register_inputs_new{
    height: 24px;
    width: 100%;
    border: 1.5px solid #005DBF;
    border-radius: 5px;
    flex: 1;
}
.register_inputs_blue{
    color: #005DBF;
}
.ItemLarge{
    flex:6.25;
    display: flex;
    flex-direction: column;
}
.SearchIcon{
    vertical-align: top !important;
}
.ItemTiny{
    flex:1;
    display: flex;
    flex-direction: column;
}
.budgetModalText {
    font-weight: Bold;
    font-size: 15px;
    line-height: 19px;
    color:$darkBlue;
    margin:0;
    background-color: #F5F7FA;
}
.ItemAlmostMedium{
    flex:3;
    display: flex;
    flex-direction: column;
}
.orangeText{
    color: $orange;
    font-weight: Bold;
    font-size: 13px;
    line-height: 17px;
    background-color: #F5F7FA;
}
.inputsTextArea{
    border: 1.5px solid #01A0F6;
    border-radius: 5px;
    width: 100%;
    padding: 0 .5rem;
    height: 80px;
    background-color: #FFFFFF;
}
.textArea {
    height: 32px;
    border: 1.5px solid $darkBlue;
    border-radius: 6px;
  }  
.ItemSmall {
    flex: 2;
    display: flex;
    flex-direction: column;
  }
  .justification {
    min-height: 50px;
  }
  
.labelGray{
    color: #58595B;
    font-size: 13px;
    line-height: 17px;
    font-family: PT Sans Caption;
}
.labelGrayTextArea{
    font-size: 12px;
    line-height: 16px;
    font-family: PT Sans Caption;
    color: #58595B;
}

.icon{
    width: 1.5rem;
    height: 1.1rem;
    margin: auto;
}
.alert{
    width: 1.5rem;
    height: 1.3rem;
    margin: auto;
}
.UrgentReq{
    color: #01A0F6 !important;
    font-weight: bold !important;
}