@import '../../index.scss';
@import "../../styleConstants.scss";
@import url('https://fonts.googleapis.com/css?family=Mukta:700');
@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700');
@import url('https://fonts.googleapis.com/css?family=PT+Sans+Caption:400,700');

.label-inputs {
    font-size: 1rem;
    margin-bottom: 0;
}

.close-icon-modal {
    position: absolute;
    top: 4%;
    right: 5%;
}

.modal-header {
    padding: 0.5rem !important;
}

.select-register {
    height: 27px;
    width: 100%;
    border: 1.5px solid #01A0F6;
    border-radius: 5px;
    flex: 1;
}

.select-register2 {
    height: 27px;
    width: 100%;
    border-radius: 5px;
    flex: 1;
}

.select-register:disabled {
    //border: 1.5px solid #a9abae;
    background-color: $diabledBg;
    color: $darkGray;
}

.contenedor-flex {
    display: flex;
    flex-wrap: wrap;
}

.contenedor-input {
    margin: 1rem;
}

.register-inputs-wrapper {
    display: flex;
    flex-direction: column;
}

.register-inputs-gray {
    border-radius: 5px;
    width: 100%;
    padding: 0px 0.5rem;
    height: 27px;
    font-size: 13px;
    color: #58595b;
    height: 30px;
    border: 1.5px solid #A3BAD1;
    outline: none;
    box-shadow: 0px 0px 1px #58595b;
}

.register-inputs {
    border: 1px solid #7FADDE;
    border-radius: 5px;
    width: 100%;
    padding: 0 .5rem;
    height: 25px;
    max-height: 25px;
    font-size: 12px;
    color: $darkGray;
    outline: none
}

.register-textarea {
    border: 1px solid #7FADDE;
    border-radius: 5px;
    width: 100%;
    padding: .5rem;
    font-size: 12px;
    color: $darkGray;
    outline: none;
    max-height: 120px;
}

.register-inputs--clearBlue {
    border: 1.5px solid $clearBlue;
}

.register-inputsClear {
    border: 1.5px solid $clearBlue;
    border-radius: 5px;
    width: 100%;
    padding: 0 .5rem;
    flex: 1;
}

.register-inputs:disabled {
    //border: 1.5px solid #a9abae;
    background-color: $diabledBg;
    color: $darkGray;
}

.register-inputs-big {
    border: 1.5px solid #1C6EA4;
    border-radius: 5px;
    width: 100%;
    padding: 0 .5rem;
    height: 2.6rem;
}

.register-inputs[type="date"],
.register-inputs[type="time"] {
    color: #7FADDE !important;
    font-weight: bold;
    outline: none
}

.iFamily-input {
    border: 3px solid $clearBlue !important;
    border-radius: 5px;
    width: 94%;
}

.div-header-modal {
    width: 100%;
    text-align: center;
    background-color: $darkBlue;
}

.flex-between {
    display: flex;
    justify-content: space-between;
}

.niifSelectMod {
    flex: 1 1;
    margin-right: 12px;
}

.icon-consumption {
    width: 1.5rem;
}

.register-inputs-big {
    border: 1.5px solid $darkBlue;
    border-radius: 5px;
    width: 100%;
    padding: 0 .5rem;
    height: 2.6rem;
}



@media (min-width:992px) {
    .close-icon-modal {
        top: 1.1rem;
    }
}

.modal-sm2 {
    width: 400px;
}

.register-inputs::-webkit-calendar-picker-indicator {
    filter: invert(15%) sepia(92%) saturate(5062%) hue-rotate(201deg) brightness(93%) contrast(101%);
}



.register-inputsTextArea {
    border: 1.5px solid #01A0F6;
    border-radius: 5px;
    width: 100%;
    padding: 0 .5rem;
    height: 50px;
}

.modal-red {
    color: #EC1C24;
}

.modal-green {
    color: #00A551;
}

.modal-400 {
    width: 400px;
    max-width: none
}

.modal-500 {
    width: 500px;
    max-width: none
}

.modal-440 {
    width: 440px;
    max-width: none
}

.modal-550 {
    width: 550px;
    max-width: none
}

.modal-620 {
    width: 620px;
    max-width: none
}

.modal-700 {
    width: 700px;
    max-width: none;
}

.modal-750 {
    width: 750px;
    max-width: none
}

.modal-800 {
    width: 800px;
    max-width: none
}

.modal-940 {
    width: 940px;
    max-width: none
}

.modal-1200 {
    width: 1200px;
    max-width: none
}

.modal-1340 {
    width: 1340px;
    max-width: none
}


.register-inputs-readonly {
    border: 1.5px solid #01A0F6;
    border-radius: 5px;
    width: 100%;
    padding: 0 .5rem;
    height: 27px;
    background-color: #F5F7FA;
}

.label_inputs_new3 {

    color: #58595B;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    line-height: 15.53px;
}


.app_title {
    text-align: start;
    margin-left: 15px;
    // padding: 6px 2.8rem;
}

.app_body {
    padding: 0px;
}

.headerModalClassName {
    padding: 0px 10px !important;
}

.headerBorderNone {
    border: none !important;
}

.footerModalClassName {
    padding: 10px 20px !important;
}

.w-67 {
    width: 67%;
}

.new_register-inputs {
    border: 1px solid #7FADDE;
    border-radius: 5px;
    width: 100%;
    padding: 0 .5rem;
    height: 32px;
    max-height: 32px;
    font-size: 12px;
    color: $darkGray;
    outline: none
}

.f12 {
    font-size: 12px;
}

.f10 {
    font-size: 10px;
}