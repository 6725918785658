@import "../../styleConstants.scss";

.Search{
    border: rgba(0, 93, 191, 0.1);
    background-color: rgba(0, 93, 191, 0.1);
    border-radius: 6px;
    color: $darkBlue;
}

.Search::placeholder{
    color: $clearBlue;
}

.reqCountText{
    font-family: "PT Sans Caption";
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    text-decoration-line: underline;
    text-transform: lowercase;
    color: $darkBlue;
    cursor: pointer;
}

.auctionNumber{
    margin: 0;
    text-transform: uppercase;
}