@import "../../../styleConstants.scss";

$specialPrimary: #005DBF;
$specialSecondary: #005DBF;

.table{
    border-radius: 10px;
    //border: 1px solid $specialPrimary;
    overflow: hidden;
    margin-top:2px;
    margin-bottom:18px;
}

.superhead{
    display: flex;
    flex-direction: row;
    cursor: pointer;
    //background: $specialPrimary;
}
.superhead > *{
    flex: 1;
    color: #fff;
    //background: $specialPrimary;
    font-family: "PT Sans";
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    margin:0; 
    padding-top: 4px; 
    padding-bottom: 4px;
}
.superhead > *:first-child{
    flex: 3;
    text-align: left;
    padding-left: 16px;
}
.superhead > *:last-child{
    text-align: right;
    padding-right: 16px;
}



.head{
    //tr
    display: flex;
    flex-direction: row;
    //border-bottom: 1px solid $specialSecondary;
}
.head > *{
    //th
    flex: 2;
    background: #fff;
    //color: $specialSecondary;
    font-family: "PT Sans";
    font-weight: bold;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    margin:0;
    padding-top: 4px; 
    padding-bottom: 4px;
}
.head >*:first-child{
    //th td
    flex: 5; //must be the same as td
}



.bodycontainer{
    overflow: auto;
    max-height: 300px;
}
.body{
    //tbody
    display: flex;
    flex-direction: column;
    //border-bottom: 1px solid $specialPrimary;
}
.body > div{
    //tbody tr
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #fff;
}
.body > div:nth-child(even){
    //tbody tr :even
    background: $tableEven;
}
.body > div:last-child{
    //tbody tr last
    border-bottom: 0;
}
.body > div > *{
    //tbody tr td
    flex: 2;
    font-family: "PT Sans Caption";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin:0;
    padding-top: 4px; 
    padding-bottom: 4px;
}
.body > div > *:first-child{
    //tbody tr td :first
    flex: 5; //must be the same as th
    //background: rgba($specialSecondary, .5);
    color: #fff;
    font-family: "PT Sans";
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
}
.body > div > *:nth-last-child(2),
.body > div > *:nth-last-child(1){
    //tbody tr td :last two
    //this will probably be a problem if the number of columns is 3 or less
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
}


.foot{
    display: flex;
    flex-direction: row;
    background-color: rgba( $specialPrimary, .15);
    padding: 8px 16px 16px 16px;
    font-size: 12px;
    line-height: 16px;
    //color: $specialSecondary;
}

.arrow{
    margin: auto 10px 3px 10px;
}

.collapse{
    height: 0;
    overflow: hidden;
    padding:0;
    margin:0;
    transition: height 0.5s ease-out;
    border-bottom:none;
}

/////////////////////////////color zone
.orangeTheme{
    .superhead{
        background: $orange;
    }
    .superhead > *{
        background: $orange;
    }
    .superhead.collapseSuper{
        background: rgba($orange, .05);
    }
    .superhead.collapseSuper > *{
        background: rgba($orange, .05);
        color: $orange;
    }
    .superhead.collapseSuper .arrow{
        filter: brightness(0) saturate(100%) invert(59%) sepia(97%) saturate(2485%) hue-rotate(359deg) brightness(101%) contrast(108%);
    }
    
    .head{
        border-bottom: 1px solid $orange;
        border-right: 1px solid $orange;
        border-left: 1px solid $orange;
        &.collapse{
            border:none;
        }
    }
    .head > *{
        color: $darkerGray;
    }
    .body{
        border-bottom: 1px solid $orange;
        border-right: 1px solid $orange;
        border-left: 1px solid $orange;
    }
    .body > div > *:first-child{
        background: rgba($darkerGray, .5);
    }
    .body > div > *:last-child> span{
        //this will only work assuming the last child has a <Checkbox> element
        color: $orange;
    }
    .foot{
        background-color: rgba( $orange, .15);
    }
    .footerTitle{
        color: $orange;
    }
    .footerLabels{
        color: $darkerGray;
    }
}

.blueTheme{
    .table{
        border: 1px solid $clearBlue;
    }
    .superhead{
        background: $clearBlue;
    }
    .superhead > *{
        background: $clearBlue;
    }
    .superhead.collapseSuper{
        background: rgba($clearBlue, .05);
    }
    .superhead.collapseSuper > *{
        background: rgba($clearBlue, .05);
        color: $clearBlue;
    }
    .superhead.collapseSuper .arrow{
        filter: brightness(0) saturate(100%) invert(44%) sepia(92%) saturate(1289%) hue-rotate(171deg) brightness(95%) contrast(104%);
    }

    .head{
        border-bottom: 1px solid $clearBlue;
        border-right: 1px solid $clearBlue;
        border-left: 1px solid $clearBlue;
        &.collapse{
            border:none;
        }
    }
    .head > *{
        color: $clearBlue;
    }
    .body{
        border-bottom: 1px solid $clearBlue;
        border-right: 1px solid $clearBlue;
        border-left: 1px solid $clearBlue;
    }
    .body > div > *:first-child{
        background: rgba($clearBlue, .5);
    }
    .body > div > *:last-child> span{
        //this will only work assuming the last child has a <Checkbox> element
        color: $clearBlue;
    }
    .foot{
        background-color: rgba( $clearBlue, .15);
    }
    .footerTitle{
        color: $clearBlue;
    }
    .footerLabels{
        color: $darkerGray;
    }
}

.darkBlueTheme{
    .superhead{
        background: $darkBlue;
    }
    .superhead > *{
        background: $darkBlue;
    }
    .superhead.collapseSuper{
        background: rgba($darkBlue, .05);
    }
    .superhead.collapseSuper > *{
        background: rgba($darkBlue, .05);
        color:$darkBlue;
    }
    .superhead.collapseSuper .arrow{
        filter: brightness(0) saturate(100%) invert(16%) sepia(85%) saturate(6814%) hue-rotate(202deg) brightness(95%) contrast(101%);
    }

    .head{
        border-bottom: 1px solid $darkBlue;
        border-right: 1px solid $darkBlue;
        border-left: 1px solid $darkBlue;
        &.collapse{
            border:none;
        }
    }
    .head > *{
        color: $darkBlue;
    }
    .body{
        border-bottom: 1px solid $darkBlue;
        border-right: 1px solid $darkBlue;
        border-left: 1px solid $darkBlue;
    }
    .body > div > *:first-child{
        background: rgba($darkBlue, .5);
    }
    .body > div > *:last-child> span{
        //this will only work assuming the last child has a <Checkbox> element
        color: $darkBlue;
    }
    .foot{
        background-color: rgba( $darkBlue, .15);
    }
    .footerTitle{
        color: $darkBlue;
    }
    .footerLabels{
        color: $darkerGray;
    }
}

.grayTheme{
    .superhead{
        background: $darkerGray;
    }
    .superhead > *{
        background: $darkerGray;
    }
    .superhead.collapseSuper{
        background: rgba($darkerGray, .05);
    }
    .superhead.collapseSuper > *{
        background: rgba($darkerGray, .05);
        color: $darkerGray;
    }
    .superhead.collapseSuper .arrow{
        filter: brightness(0) saturate(100%) invert(36%) sepia(6%) saturate(154%) hue-rotate(182deg) brightness(91%) contrast(92%);
    }

    .head{
        border-bottom: 1px solid $darkerGray;
        border-right: 1px solid $darkerGray;
        border-left: 1px solid $darkerGray;
        &.collapse{
            border:none;
        }
    }
    .head > *{
        color: $darkerGray;
    }
    .body{
        border-bottom: 1px solid $darkerGray;
        border-right: 1px solid $darkerGray;
        border-left: 1px solid $darkerGray;
    }
    .body > div > *:first-child{
        background: rgba($darkerGray, .5);
    }
    .body > div > *:last-child> span{
        //this will only work assuming the last child has a <Checkbox> element
        color: $darkerGray;
    }
    .foot{
        background-color: rgba( $darkerGray, .15);
    }
    .footerTitle{
        color: $darkerGray;
    }
    .footerLabels{
        color: $darkerGray;
    }
}


//later bug fix
.head > .smallChild,
.body > div > .smallChild{
    //flex: none;
    max-width: 68px;
    flex: 1;
    padding-right: 5px;
    padding-left: 5px;
}