@import "../../index.scss";
@import "../../styleConstants.scss";

.backgroundBlueTenue{
    background-color: rgba(0,63,128,0.05)
}
.btnAddTable{
    margin-right: 2.5rem;
}
.handleLot{
    margin-top:1.25rem;
}
.heightInputs{
    height: 2.5rem;
}
.registerSelect{
    border: 3px solid $clearBlue !important;
    border-radius: 5px;
    width: 100%;
}
.discardFile{
    position: absolute;
    right: 3rem;
    top: 2rem;
}
.btnNewHover{
    cursor: pointer;
    filter: grayscale(1);
    align-self: center;
    padding-left: 10px ;
}
.labelNew{
    font-weight: 700;
    font-style : normal;
    font-size: 12px;
    line-height:15.53px;
    margin-bottom: 1.5px;
    align-content: left ;
    color: #58595B;
    padding: 6px 1px 3px 4px;
}
.buttonCreatCount:hover{
    background-color: #E6ECF2;
}
.principalScreenBtns{
    display: flex;
    align-self: flex-end;
}
.Search{
    vertical-align: top !important;
    border: rgba(0, 93, 191, 0.1);
    background-color: rgba(0, 93, 191, 0.1);
    border-radius: 6px;
    width: 270px;
}
.inputMargin{
    margin-right:15px;
}
.buttonCreatCount{
    flex-direction: row;
    float: right;
    width: 80px;
    height: 30px;
    display: flex;
    border-radius: 6px;
    justify-content: space-evenly;
}
.buttonCreatCount:hover>p{
    color: #005DBF;
}

.buttonCreatCount>img{
    height: 20px;
    top: 3px;
}
.buttonCreatCount:hover>img{
    filter: none;
}
.buttonCreatCount>p{
    size: 76px;
    line-height:16px;
    margin-bottom: 0;
    color: #58595B;
    cursor: pointer;
    font-Weight:700;
}
.crudModalTopLabel{
    font-size: .75rem;
    margin: .4rem .3rem 0 .3rem;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
    color: $darkBlue;
}
.subtitle{
    margin: 16px 0 0 0;
    color:#005DBF;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
}

.tooltip {
    background-color: white;
    color : rgba(0, 0, 0, 0.87);
}
.borderAddDataSheet{
    border: 1.5px solid;
    border-color: #01A0F6;
    border-radius: 6px;
}