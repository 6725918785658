@import "../../styleConstants.scss";

.container1 {
  display: flex;
  flex-direction: row;
}

.container2 {
  display: flex;
  flex-direction: column;
}
.inputMarginR {
  margin-right: 15px;
}
.inputMarginLR {
  margin-right: 15px;
  margin-left: 15px;
}

.Item0 {
  flex: 0.32;
  display: flex;
  flex-direction: column;
}
.Item1 {
  flex: 0.5;
  display: flex;
  flex-direction: column;
}

.Item2 {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.Item3 {
  flex: 3;
  display: flex;
  flex-direction: column;
}
.crudModalLabel {
  font-size: 0.75rem;
  margin: 0.4rem 0.3rem 0 0.3rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: $darkBlue;
}
.crudModalLabelGris {
  font-size: 0.75rem;
  margin: 0.4rem 0.3rem 0 0.3rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #cecece;
}
.registerInputs {
  border: 1.5px solid #005dbf;
  border-radius: 5px;
  width: 100%;
  padding: 0 0.5rem;
  height: 30px;
  flex: 1;
}

.registerInputs2 {
  border: 1.5px solid #005dbf;
  border-radius: 5px;
  width: 100%;
  padding: 0 0.5rem;
  height: 30px;
}
.registerInputsGris {
  border: 1.5px solid #cecece;
  border-radius: 5px;
  width: 100%;
  padding: 0 0.5rem;
  height: 30px;
  flex: 1;
}
.registerInputsBlue {
  border: 1.5px solid #005dbf;
  border-radius: 5px;
  width: 100%;
  padding: 0 0.5rem;
  height: 30px;
  color: #005dbf;
  font-weight: 700;
}
.ItemCheckbox {
  display: flex;
  margin-top: 24px;
  //flex-direction: column-reverse;
}
.urgente {
  color: #697387;
  line-height: 16px;
  font-size: 12px;
  display: inline;
}
.ItemMedium {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.pseudoInput {
  padding: 1px;
  position: relative;
  cursor: pointer;
}

.pseudoInput > p {
  margin: 0;
  color: #cecece;
  font-size: 16px;
  line-height: 24px;
  height: 22px;
  padding-left: 8px;
  overflow: hidden;
}

.pseudoInput > img {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 15px;
  height: 22px;
  margin: 0;
}

.pseudoInput1 {
  padding: 1px;
  position: relative;
  cursor: pointer;
}

.pseudoInput1 > p {
  margin: 0;
  color: #005dbf;
  font-weight: 700;
  line-height: 24px;
  height: 22px;
  padding-left: 8px;
  overflow: hidden;
}

.pseudoInput1 > img {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 15px;
  height: 22px;
  margin: 0;
}
.labelInInput {
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}
.sizeIcon {
  height: 16px;
  width: 16px;
}
.marginTopIcon {
  margin-top: 18px;
}
.marginTop {
  margin-top: 14px !important;
}

.titleBlock {
  margin-top: 50px;
}
.mainTitle {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 39px;
  margin: 0;
}
.bigContainer {
  margin-left: 50px;
}
.backIcon {
  width: 50px;
  height: 33px;
  margin: auto 0;
  cursor: pointer;
}
.underMainTitle {
  font-family: PT Sans Caption;
  font-size: 15px;
  line-height: 19px;
}

//TAMAÑO DE LOS INPUTS

.f0 {
  flex: 0.7;
}
.f1 {
  flex: 1;
}
.f15 {
  flex: 1.5;
}
.f2 {
  flex: 2;
}
.f3 {
  flex: 3;
}
.blueText {
  color: $darkBlue;
}
.inputMargin {
  margin-right: 15px;
}
.sectionTitle {
  position: relative;
  font-family: "PT Sans";
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: $darkBlue;
  margin: 15px 7px 0 12px;
}
.sectionTitleGrey {
  position: relative;
  font-family: "PT Sans";
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: $darkerGray;
  margin: 15px 7px 0 12px;
}
.line {
  height: 1px;
  width: 100%;
  border-color: #cecece;
  background-color: #cecece;
  margin-top: 32px;
  margin-bottom: 17px;
}
.bottomRow {
  margin: 68px 0;
  display: inline-flex;
  flex-direction: row-reverse;
  width: 100%;
  vertical-align: middle;
  padding: 0 0.1rem;
}
.background {
  background-color: #f5f7fa;
}
.sectionTitleClearBlue {
  position: relative;
  font-family: "PT Sans";
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: $clearBlue;
  margin: 15px auto 0 12px;
}
.bottom {
  margin: 20px 0;
  display: inline-flex;
  flex-direction: row-reverse;
  width: 100%;
  vertical-align: middle;
  padding: 0 0.5rem;
}
.greenCheck {
  background-image: url("../../assets/img/icons/CheckMark.svg");
  background-repeat: no-repeat;
  width: 1rem;
  height: 1rem;
  width: "-webkit-fill-available";
  margin-left: 2px;
  align-self: flex-end;
}

.alertTriangle {
  // background-image: url("../../assets/img/icons/Alert.svg");
  background-repeat: no-repeat;
  width: "-webkit-fill-available";
  width: 1.5rem;
  height: 1.1rem;
  margin-left: 2px;
}

.bottom > Button {
  margin-left: 12px;
}

.popupReqActive::before {
  width: 12px;
  height: 12px;
  cursor: default;
}

.paddingButton {
  padding-bottom: 15px;
}

.inputTable:focus,
.inputTable {
  outline: none !important;
  border: none !important;
  background-color: transparent !important;
}
.filter {
  filter: invert(37%) sepia(98%) saturate(880%) hue-rotate(116deg)
    brightness(95%) contrast(100%);
}
.iconsIntoTable {
  display: flex;
  justify-content: center;
}
.contentInputs {
  display: flex;
  justify-content: flex-start;
}

.backgroundModal {
  background: rgba(0, 93, 191, 0.05);
  border-radius: 10px;
  padding-bottom: 16px;
  padding-left: 15px;
  //margin-right: -20px;
  padding-right: 20px;
}

.backgroundModal_new {
  border-radius: 10px;
  padding-bottom: 16px;
  //padding-left: 15px;
  //margin-right: -20px;
  //padding-right: 20px;
}

.ItemTiny_cantidad {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  width: 25px;
}

.lots {
  color: #005dbf !important;
  font-style: bold;
  font-size: 15px;
  margin: auto;
  cursor: pointer;
  text-decoration-line: underline;
  text-decoration-color: red;
}

.containerObservations {
  display: flex;
  justify-content: space-between;
  background-color: #f5f7fa;
  width: 100%;
  margin-top: 12px;
  padding: 0.5rem;
  border-radius: 10px;
}

.labelFont {
  font-size: 0.8rem;
  color: #005dbf;
  font-family: "PT Sans Caption";
  line-height: 0.971rem;
  font-weight: 400;
}
.selectInput {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #005dbf;
  min-height: 1.75rem;
}

.selectRegister {
  height: 27px;
  width: 100%;
  border: 1.5px solid #005dbf;
  border-radius: 5px;
  flex: 1;
}
.buttonCreatCount {
  flex-direction: row;
  height: 30px;
  display: inline-flex;
  flex: 1;
  border-radius: 10px;
}
.ellipsis {
  width: 230px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
