@import "../../styleConstants.scss";
.inputMargin{
    margin-right:15px;
}
.DspFlex{
    display: flex;
}
.greyRow{
    display:flex;
    flex-direction: row;
    background-color: #ddd;
    border-radius: .5rem;
    margin-top:1rem;
    padding: .5rem;
    padding: .5rem 2rem;
}
.bottomRow{
    margin: 20px 0;
    display: inline-flex;
    flex-direction: row-reverse;
    width: 100%;
    vertical-align: middle;
    padding: 0 .5rem;
}
.bottomRow> Button{
    margin-left: 12px;
}
.tableButton{
    cursor: pointer;
    color: $tableText;
    text-decoration: none;
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 5px;
    font-size: 12px;
    line-height: 16px;
}
.tableButton:hover{
    color: $darkBlue;
    padding-top: 0px;
    transition-duration: 0.2s;
}
.tableSlash{
    color: $tableText;
    font-size: 12px;
    line-height: 16px;
}
.traerReq{
    display: flex;
    min-width: 140px;
    height: 30px;
    font-weight: Bold;
    font-size: 12px;
    line-height: 16px;
    color: #697387;
    cursor: pointer;
    border-radius: 10px;
    margin: 0 0 0 auto;
}
.traerReq:hover{
    background-color: #F5F7FA;
}
.traerReq > p {
    flex: 1;
    margin: 8px 6px 4px 6px;
}
.traerReq:hover> p{
    color: #005DBF;
}
.traerReq:hover> img{
    filter: brightness(0) saturate(100%) invert(20%) sepia(94%) saturate(1860%) hue-rotate(196deg) brightness(107%) contrast(105%);
}
.pseudoInput{
    padding: 1px;
    position: relative;
    cursor: pointer;
}
.pseudoInput>p{
    margin:0;
    color:#CECECE;
    font-size: 16px;
    line-height: 24px;
    height: 22px;
    padding-left: 8px;
    overflow: hidden;
}
.pseudoInput>img{
    position: absolute;
    top:2px;
    right:2px;
    width: 15px;
    height: 22px;
    margin: 0;
}
.urgente{
    color: #697387;
    line-height: 16px;
    font-size: 12px;
    display: inline;
}
.newArticle{
    margin: 0 5px;
    border: 1.5px solid $orange;
    border-radius: 5px;
    padding: 0 15px;
}
.iconSvg{
    width: 1.7rem;
    margin: 0.1rem;
    }
.adjustIcon{
    cursor: pointer;
    width: 20px;
    margin: 0;
}
//added for article insert ts-147
.orangeIcon{
    fill: $orange;
    filter: saturate(3000%) hue-rotate(190deg);
}
.orangeBorder{
    border: 1.5px solid $orange;
    border-radius: 5px;
}
.orangeText{
    color: $orange;
    font-weight: Bold;
    font-size: 12px;
    line-height: 16px;
}
.orangeCheck{
    background-color: $orange;
    border-color: $orange;
}
.subtitle{
    margin: 16px 0 0 0;
    color:#005DBF;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
}
.TopModalText{
    color: #58595B;
}
.backgroundModal{
    background : rgba(0, 93, 191, 0.05);
    border-radius: 10px;
    padding-bottom: 16px;
    padding-left: 20px;
    margin-right: -20px;
    padding-right: 20px;
}

.principalScreenBtns{
    display: flex;
    align-self: flex-end;
}

.nitMini{
    width: 30px;
}
.crudModalTopLabel{
    font-size: .75rem;
    margin: .4rem .3rem 0 .3rem;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
    color: $darkBlue;
}
.crudModalBottomLabel{
    font-size: .75rem;
    margin: .4rem .3rem 0 .3rem;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
    color: #01A0F6;
}

.ItemRz{
    flex:4.35;
    display: flex;
    flex-direction: column;
}
.ItemCompT{
    flex:0;
    display: flex;
    flex-direction: column;
}
.ItemNormal{
    flex:3;
    display: flex;
    flex-direction: column;
}
.ItemLarge{
    flex:6.25;
    display: flex;
    flex-direction: column;
}
.ItemXL{
    flex:9;
    display: flex;
    flex-direction: column;
}
.ItemInicio{
    flex:1.6;
    display: flex;
    flex-direction: column;
}
.Search{
    vertical-align: top !important;
}
.register_inputs_new{
    height: 24px;
    width: 100%;
    border: 1.5px solid #005DBF;
    border-radius: 5px;
    flex: 1;
}
.buttonCreatCount{
    flex-direction: row;
    float: right;
    width: 80px;
    height: 30px;
    display: flex;
    border-radius: 6px;
    justify-content: space-evenly;
}

.sizeLink{
    width: 10px;
    height: 10px;
}

.btnNewHover{
    cursor: pointer;
    filter: grayscale(1);
    align-self: center;
    padding-left: 10px ;
}
.btnExport{
    width: 27.5px;
    height: 20px;
}

.labelNew{
    font-weight: 700;
    font-style : normal;
    font-size: 12px;
    line-height:15.53px;
    margin-bottom: 1.5px;
    align-content: left ;
    color: #58595B;
    padding: 6px 1px 3px 4px;
}

.flex1{
    flex: 1;
}

.Search{
    border: rgba(0, 93, 191, 0.1);
    background-color: rgba(0, 93, 191, 0.1);
    border-radius: 6px;
    width: 270px;
    
}

.titulo{
    color:#005DBF;
    font-weight: 400;
    font: "PT Sans Caption";
    size: 12px;
    line-height:19.41px;
    margin-bottom: 1.5px;
    display: flex;
}

.register_inputs_new{
    //box-sizing: content-box;
    height :24px;
    border-radius :6px;
    border: 1.5px solid #005DBF;
}

.Position{
    margin-top: 15px
}

.buttonCreatCount:hover{
    background-color: #E6ECF2;
}
.buttonCreatCount:hover>p{
    color: #005DBF;
}

.buttonCreatCount>img{
    height: 20px;
    top: 3px;
}
.buttonCreatCount:hover>img{
    filter: none;
}
.buttonCreatCount>p{
    size: 76px;
    line-height:16px;
    margin-bottom: 0;
    color: #58595B;
    cursor: pointer;
    font-Weight:700;
}

.widtDiv{
    width:-webkit-fill-available;
}


.progressBar {
    height: 27px !important;
}

.progressBar> div {
    text-align: start;
    // margin-left: 9px;
    color: black !important;
    background-color: rgba(1, 160, 246, 0.20);
}