@import "../../styleConstants.scss";

.customTabs__Border{
    border-radius: 10px;
    border: 1px solid $darkBlue;
}

.customTabs__NumberDisplay{
    text-align: center;
    flex:1;
}

.customTabs__NumberDisplay--mid{
    margin-right: 15px;
    margin-left: 15px;
}

.customTabs__Container{
    flex:1;
    margin-top:15px;
    display: flex;
    flex-direction: column;
}

.customTabs__Subtitle{
    color: $darkBlue;
    font-weight: Bold;
    font-size:  20px;
    line-height: 26px;
}

.customTabs__thirdTitle{
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    vertical-align: bottom;
    text-align: left;
    color: $darkBlue;
}

.customTabs__select{
    width: 240px;
    margin-bottom: 18px;
}

.customTabs__Number{
    color: #58595B;
    font-weight: Bold;
    font-size:  26px;
    line-height: 34px;
}

.customTabs> div{
    flex:1;
}

.customTabs> div>a{
    border-radius: 0;
    border-top: 0px;
    border-right: 1px solid transparent;
    border-bottom: 1px solid $darkBlue;
    border-left:  1px solid $darkBlue;
    text-align: center;
    color: $darkBlue;
    background-color: rgba(0, 63, 128, 0.07);
}

.customTabs>.customTabs__First>a{
    border-left: 1px solid transparent;
}
.customTabs>div>a.active{
    border-bottom: 1px solid transparent;
    background-color: transparent;
}

.customTabs__Area{
    padding: 5px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.customTabs__Footer{
    margin: 20px 0;
    display: inline-flex;
    flex-direction: row-reverse;
    width: 100%;
    vertical-align: middle;
    // justify-content: space-between;
    padding: 0 .5rem;
}

.customTabs__Footer>Button{
    margin-left: 12px;
}

.customTabs__Row{
    display: flex;
}

.customTabs__CellFirst{
    width: 60px;
}

.customTabs__TinyCell{
    color: $darkBlue;
    width: 60px;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 15.53px;
}

.customTabs__Cell{
    flex:1;
    color:$darkBlue;
}

.customTabs__Cell--border{
    border-top: none;
    border-right: 2px solid #CECECE;
    border-bottom: none;
    border-left: none;
    border-radius: 0;
    margin-right:39px;
}

.customTabs__relative{
    position: relative;
    overflow-y: scroll;
    //height: 100%;
    min-height: 300px;
    flex: 1;
}

.customTabs_scroll{    
    flex-grow: 1;
    overflow: auto;
    /* for Firefox */
    min-height: 0;
}

@media (min-width:992px){
    .customTabs__Area{
        padding: 15px 70px 30px 70px;
    }
}