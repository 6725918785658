
.popUpUl{
    margin: 0  ;
    list-style: none;
    padding-left: 0 ;
    }

.popUpLiEdit,
.popUpLiActive, 
.popUpLiActiveRed{
    padding-left: 1em;
    text-indent: -1em;
    margin-top: 15px;
    margin-bottom: 15px;
}

.popUpLiEdit,
.popUpLiActive:hover, 
.popUpLiActiveRed:hover{
    cursor: pointer;
}

.popUpLiActive:before {
    content: "";
    display: inline-block;
    background: #00A551;
    border-radius: 50%;
    width: .8rem;
    height: .8rem;
    margin-right: 5px;
    }

.popUpLiActiveRed:before {
    content: "";
    display: inline-block;
    background: #ec1109;
    border-radius: 50%;
    width: .8rem;
    height: .8rem;
    margin-right: 5px;
    }

.popUpLiEdit:before {
    content: "";
    background-image: url("../../assets/img/icons/editar.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    width: .8rem;
    height: .8rem;
    margin-right: 5px;
}